import Modal from 'components/Modal';

import style from './styles.module.scss';

const ValidateOfferModal = ({
  data,
  isActive,
  setIsActive
}) => {
  return (
    <Modal isActive={isActive} setIsActive={setIsActive}>
      <div className={style.wrapper}>
        <h3 className={style.title}>
          {'Некорректно заполненные строки: '}
          {/* <CopyEmptyRowsListButton /> */}
        </h3>

        <table className={style.table}>
          <tr>
            <td className={style.cell}>Номер строки</td>
            <td className={style.cell}>Наименование</td>
            <td className={style.cell}>Склад</td>
            <td className={style.cell}>Кол-во на складе</td>
            <td className={style.cell}>Общее отгружаемое кол-во</td>
          </tr>

          {
            Object.keys(data).map((itemNumber) => {
              return (
                <tr>
                  <td
                    className={style.cell}
                  >
                    {itemNumber}
                  </td>
                  <td
                    className={style.cell}
                  >
                    {data[itemNumber].plantName}
                  </td>
                  <td
                    className={style.cell}
                  >
                    {
                      data[itemNumber].storage === 'not found' || !data[itemNumber].storage
                        ? 'Отсутствует'
                        : data[itemNumber].storage
                    }
                  </td>
                  <td
                    className={style.cell}
                  >
                    {data[itemNumber].storageTotalAmount}
                  </td>
                  <td
                    className={style.cell}
                  >
                    {
                      data[itemNumber].exceedsTotalAmount
                        ? 'Превышает'
                        : data[itemNumber].exceedsTotalAmount
                    }
                  </td>
                </tr>
              );
            })
          }
          <tr></tr>
        </table>
      </div>
    </Modal>
  );
};

export default ValidateOfferModal;
