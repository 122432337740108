export const getCommentFiles = (state) => state.documents.commentFiles;
export const getDocumentAuthor = (state) => state.documents.author;
export const getProvidedStatus = (state) => state.documents.provided;
export const getDoneStatus = (state) => state.documents.done;
export const getIsCorrectionStatus = (state) => state.documents.isCorrection;
export const getGoodsByStorage = (state) => state.documents.goods;
export const getUnavailableForChargeGoods = (state) => state.documents.unavailableForChargeGoods;
export const getDocumentsNumbers = (state) => state.documents.documentsNumbers;
export const getStorageAddresses = (state) => state.documents.addresses;
export const getNewDocumentDate = (state) => state.documents.newDocumentDate;

export const getDocumentsList = (state) => state.documents.list;
export const getChangedInBusinessRuDocuments = (state) => state.documents.changedInBusinessRuDocuments;
export const getDocument = (state) => state.documents.document;

export const getDestinationStorage = (state) => state.documents.document.toStorage;
export const getShiftingTargetStorageAmounts = (state) => state.documents.shiftingTargetStorageAmounts;
