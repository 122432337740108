import Modal from 'components/Modal';

import style from './styles.module.scss';

const RemainsCheckValuesModal = ({
  data,
  isActive,
  setIsActive
}) => {
  return (
    <Modal isActive={isActive} setIsActive={setIsActive}>
      <div className={style.wrapper}>
        {
          data && data.incorrectLines && Object.keys(data.incorrectLines).length ? (
            <>
              <h5 className={style.title}>
                {'Некорректно заполненные строки: '}
              </h5>
              <table className={style.table}>
                <tr className={style.row}>
                  <td className={style.cell}>Номер строки</td>
                  <td className={style.cell}>Наименование</td>
                  <td className={style.cell}>Артикул</td>
                  <td className={style.cell}>Кол-во</td>
                  <td className={style.cell}>Адрес</td>
                </tr>
                {
                  Object.keys(data.incorrectLines).map((lineNumber) => (
                    data.incorrectLines[lineNumber].incorrectPositions ? Object.keys(data.incorrectLines[lineNumber].incorrectPositions).map((positionNumber, index) => (
                      <tr>
                        {/* Номер строки, наименование и артикул */}
                        {
                          index === 0 && (
                            <>
                              <td
                                className={style.cell}
                                rowSpan={Object.keys(data.incorrectLines[lineNumber].incorrectPositions).length}
                              >
                                {lineNumber}
                              </td>
                              <td
                                className={style.cell}
                                rowSpan={Object.keys(data.incorrectLines[lineNumber].incorrectPositions).length}
                              >
                                {
                                  data.incorrectLines[lineNumber].nameNotInserted && (
                                    <span>Не указано</span>
                                  )
                                }
                                {
                                  data.incorrectLines[lineNumber].incorrectName && (
                                    <span>Некорректное</span>
                                  )
                                }
                                {
                                  !data.incorrectLines[lineNumber].nameNotInserted && !data.incorrectLines[lineNumber].incorrectName && (
                                    <span>{data.incorrectLines[lineNumber].plantName}</span>
                                  )
                                }
                              </td>
                              <td
                                className={style.cell}
                                rowSpan={Object.keys(data.incorrectLines[lineNumber].incorrectPositions).length}
                              >
                                {
                                  data.incorrectLines[lineNumber].articleNotInserted && (
                                    <span>Не указан</span>
                                  )
                                }
                                {
                                  data.incorrectLines[lineNumber].incorrectArticle && (
                                    <span>Некорректный</span>
                                  )
                                }
                                {
                                  !data.incorrectLines[lineNumber].articleNotInserted && !data.incorrectLines[lineNumber].incorrectArticle && (
                                    <span>{data.incorrectLines[lineNumber].plantArticle}</span>
                                  )
                                }
                              </td>
                            </>
                          )
                        }

                        {/* Кол-во */}
                        <td
                          className={style.cell}
                        >
                          {
                            data.incorrectLines[lineNumber].incorrectPositions[positionNumber].amountNotInserted && (
                              <span>Не указано</span>
                            )
                          }
                          {
                            data.incorrectLines[lineNumber].incorrectPositions[positionNumber].amountNotNumeric && (
                              <span>Не числовое значение</span>
                            )
                          }
                          {
                            !data.incorrectLines[lineNumber].incorrectPositions[positionNumber].amountNotInserted && (
                              <>✔️</>
                            )
                          }
                        </td>

                        {/* Адрес */}
                        <td
                          className={style.cell}
                        >
                          {
                            data.incorrectLines[lineNumber].incorrectPositions[positionNumber].addressNotInserted && (
                              <span>Не указан (в подстроке {positionNumber})</span>
                            )
                          }
                          {
                            data.incorrectLines[lineNumber].incorrectPositions[positionNumber].addressIncorrect && (
                              <span>Некорректный (в подстроке {positionNumber})</span>
                            )
                          }
                          {
                            !data.incorrectLines[lineNumber].incorrectPositions[positionNumber].addressNotInserted &&
                            !data.incorrectLines[lineNumber].incorrectPositions[positionNumber].addressIncorrect && (
                              <>✔️</>
                            )
                          }
                        </td>
                      </tr>
                    )) : (
                      <tr>
                        {/* Номер строки */}
                        <td
                          className={style.cell}
                        >
                          {lineNumber}
                        </td>

                        {/* Наименование */}
                        <td
                          className={style.cell}
                        >
                          {
                            data.incorrectLines[lineNumber].nameNotInserted && (
                              <span>Не указано</span>
                            )
                          }
                          {
                            data.incorrectLines[lineNumber].incorrectName && (
                              <span>Некорректное</span>
                            )
                          }
                          {
                            !data.incorrectLines[lineNumber].nameNotInserted && !data.incorrectLines[lineNumber].incorrectName && (
                              <span>{data.incorrectLines[lineNumber].plantName}</span>
                            )
                          }
                        </td>

                        {/* Артикул */}
                        <td
                          className={style.cell}
                        >
                          {
                            data.incorrectLines[lineNumber].articleNotInserted && (
                              <span>Не указан</span>
                            )
                          }
                          {
                            data.incorrectLines[lineNumber].incorrectArticle && (
                              <span>Некорректный</span>
                            )
                          }
                          {
                            !data.incorrectLines[lineNumber].articleNotInserted && !data.incorrectLines[lineNumber].incorrectArticle && (
                              <span>{data.incorrectLines[lineNumber].plantArticle}</span>
                            )
                          }
                        </td>

                        {/* Кол-во */}
                        <td
                          className={style.cell}
                        >
                          <>✔️</>
                        </td>

                        {/* Адрес */}
                        <td
                          className={style.cell}
                        >
                          <>✔️</>
                        </td>
                      </tr>
                    )
                  ))
                }
              </table>
            </>
          ) : null
        }
      </div>
    </Modal>
  );
};

export default RemainsCheckValuesModal;
