import {createAsyncThunk} from '@reduxjs/toolkit';

import {ApiRoute} from 'constants/endpoints';

export const fetchOffersAsyncAction = createAsyncThunk(
  'offers/fetchOffers',
  async (isInArchive, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Offers.ALL}?provided=${isInArchive ? 1 : 0}`);

    return data;
  }
);

export const uploadOfferFileAndFillDBAsyncAction = createAsyncThunk(
  'offers/uploadOfferFileAndFillDB',
  async (formData, {extra: api}) => {
    const {data} = await api.post(ApiRoute.Offers.UPLOAD_OFFER, formData);

    return data;
  }
);

export const fetchOfferDetailsAsyncAction = createAsyncThunk(
  'offers/fetchOfferDetails',
  async (id, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Offers.SHOW}/${id}`);

    return data;
  }
);

export const refreshGoodAmountsAsyncAction = createAsyncThunk(
  'offers/refreshGoodStoresAmounts',
  async ({good_id, index}, {extra: api}) => {
    const {data} = await api.post(`${ApiRoute.Offers.REFRESH_REMAINS}/${good_id}`);

    return {data, index, good_id};
  }
);

export const saveOfferAsyncAction = createAsyncThunk(
  'offers/saveOffer',
  async (args, {extra: api}) => {
    const id = args.id;
    const body = args.body;
    const {data} = await api.post(`${ApiRoute.Offers.SAVE}/${id}`, body);

    return data;
  }
);

export const deleteOfferAsyncAction = createAsyncThunk(
  'offers/deleteOffer',
  async (id, {extra: api}) => {
    const {data} = await api.delete(`${ApiRoute.Offers.DELETE}/${id}`);

    return data;
  }
);

export const moveOfferToArchiveAsyncAction = createAsyncThunk(
  'offers/moveOfferToArchive',
  async (id, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Offers.MOVE_TO_ARCHIVE}/${id}`);

    return data;
  }
);

export const downloadOfferAsyncAction = createAsyncThunk(
  'offers/downloadOffer',
  async (id, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Offers.DOWNLOAD}/${id}`);

    return data;
  }
);
