import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import clsx from 'clsx';

import RemainsListItem from 'components/remains-list-item/remains-list-item';
import Preloader from 'components/preloader/SpinPlaceholderComponent';
import ListLegend from 'components/list-legend/list-legend';

import {fetchRemainsAsyncAction} from 'redux/slices/remains/remains-api-actions';
import {getRemains} from 'redux/slices/remains/selectors';

import {AppRoute} from 'constants/routes';
import {DocumentTypeNameMap, DocumentType} from 'constants/document-type';

import {AppContext} from 'providers/AppContextProvider';

import styles from './styles.module.scss';

const RemainsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {setCrumbs} = useContext(AppContext);

  // Активность прелоадера
  const [isPreloaderActive, setIsPreloaderActive] = useState(false);

  const remainsItems = useSelector(getRemains);

  const handleOpenUploadInitialRemainsPageButtonClick = () => {
    navigate(AppRoute.Remains.UPLOAD);
  };

  const handleOpenUserManualButtonClick = () => {
    window.open(AppRoute.UserManual.REMAINS);
  };

  useEffect(() => {
    dispatch(fetchRemainsAsyncAction());
  }, []);

  useEffect(() => {
    setCrumbs([
      {name: 'Начальные остатки', url: AppRoute.Remains.LIST}
    ]);
  }, []);

  return (
    <>
      <Preloader isActive={isPreloaderActive}/>

      <ListLegend
        legendData={[
          {type: 'danger', text: 'Документ загрузки начальных остатков создан'},
          {type: 'success', text: 'Документ загрузки начальных остатков проведён'}
        ]}
      />

      <div className={styles["buttons-wrapper"]}>
        <div>
          <button
            className={clsx(styles.button, styles.new)}
            onClick={handleOpenUploadInitialRemainsPageButtonClick}
          >
            <span>
              Загрузить остатки
            </span>
          </button>
        </div>
        
        <div>
          <button
            className={clsx(styles.button, styles.regular)}
            onClick={handleOpenUserManualButtonClick}
          >
            <span>
              Инструкция
            </span>
          </button>
        </div>
      </div>

      <table className={styles.table}>
        <thead>
          <tr>
            <th>Тип</th>
            <th>Номер в Системе</th>
            <th>Дата в Системе</th>
            <th>Автор</th>
            <th/>
          </tr>
        </thead>
        <tbody>
          {
            remainsItems.map((remainsItem, index) => (
              <RemainsListItem
                key={index}
                remainsItem={remainsItem}
              />
            ))
          }
        </tbody>
      </table>
    </>
  );
};

export default RemainsList;
