import {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import clsx from 'clsx';

import {
  deleteSuppliesItemAsyncAction,
  deleteSupplyCommentFileAsyncAction,
  fetchStorageAddressesAsyncAction,
  fetchSupplyDetailsAsyncAction,
  postSupplyCommentFilesAsyncAction,
  saveSupplyAsyncAction
} from 'redux/slices/supplies/supplies-api-actions';
import {
  resetState,
  setCommentFilesAction,
  setNewSupplyDateAction,
  setSupplyCommentAction,
  setSupplyEmployeeAction,
  setSupplyFormAction,
  setSupplyStorageAction
} from 'redux/slices/supplies/supplies';
import {
  getCommentFiles,
  getNewSupplyDate,
  getStorageAddresses,
  getSupply
} from 'redux/slices/supplies/selectors';
import {fetchStores} from 'redux/slices/business/businessSlice';
import {getChangedInBusinessRuDocuments} from 'redux/slices/documents/selectors';

import SuppliesFloatPanel from 'components/supplies-float-panel/supplies-float-panel';
import Preloader from 'components/preloader/SpinPlaceholderComponent';
import Modal from 'components/Modal';
import Input from 'components/input/InputComponent';
import CustomSearchSelect from 'components/custom-search-select/custom-search-select';
import SupplyTableRow from 'components/supply-table-row/supply-table-row';
import SupplyCheckValuesModal from 'components/supply-check-values-modal/supply-check-values-modal';
import ChangedInBusinessRuDocumentsModal from 'components/changed-documents-modal/changed-documents-modal';

import {AppContext} from 'providers/AppContextProvider';
import {getUser} from 'helpers/storage';
import {sortStringValues} from 'helpers/utils';

import {AppRoute} from 'constants/routes';
import {DocumentType, DocumentTypeNameMap} from 'constants/document-type';

import styles from './styles.module.scss';

const SuppliesNew = () => {
  // Классы таблиц
	const tableClasses = [styles.mainTable, styles.top0, "table", "table-responsive"];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {id} = useParams();

  const ARCHIVE_LOCATION_REG_EXP = /archive/;
  const pathName = location.pathname;
  const isInArchive = ARCHIVE_LOCATION_REG_EXP.test(pathName);

  // Проверка уровня пользователя
  const canUserUpdate = getUser().accessLevel > 1;

  const {alert, setCrumbs} = useContext(AppContext);

  const supply = useSelector(getSupply);
  const lines = supply.form;
  const displayedLines = Object.values(lines.reduce((res, line, index) => ({...res, [line.article]: {
    ...line,
    userPositions: res[line.article] ? [
      ...res[line.article].userPositions,
      {
        ...line.userPositions[0],
        lineIndex: index
      }
    ] : [
      {
        ...line.userPositions[0],
        lineIndex: index
      }
    ]
  }}), {}));
  const documentNumber = supply.document_number;
  const documentDate = supply.document_date;
  const newSupplyDate = useSelector(getNewSupplyDate);
  const storage = supply.storage;
  const author = supply.author;
  const employee = supply.employee;
  const provided = supply.provided;
  const done = supply.done;
  const comment = supply.comment;
  const commentFiles = useSelector(getCommentFiles);
  const changedInBusinessRuDocuments = useSelector(getChangedInBusinessRuDocuments);

  const storages = useSelector((state) => state.business).stores.data;
  const allAddresses = useSelector(getStorageAddresses);
  const addresses = allAddresses.filter((address) => address.storage === storage).sort((a, b) => {
    const ADDRESS_REG_EXP = /^\d+/;
    const prevValue = a.value;
    const nextValue = b.value;

    if (ADDRESS_REG_EXP.test(prevValue) && ADDRESS_REG_EXP.test(nextValue)) {
      return prevValue.match(ADDRESS_REG_EXP)[0] - nextValue.match(ADDRESS_REG_EXP)[0];
    }
    if (ADDRESS_REG_EXP.test(prevValue) && !ADDRESS_REG_EXP.test(nextValue)) {
      return -1;
    }
    if (!ADDRESS_REG_EXP.test(prevValue) && ADDRESS_REG_EXP.test(nextValue)) {
      return 1;
    }
    
    return 0;
  });

  // Активность прелоадера
  const [isPreloaderActive, setIsPreloaderActive] = useState(false);

  // Модальное окно примечания
  const [commentModalActive, setCommentModalActive] = useState(false);

  // Модальное окно со списком некорректно заполненных строк
  const [checkValuesModalData, setCheckValuesModalData] = useState([]);
  const [isCheckValuesModalActive, setIsCheckValuesModalActive] = useState(false);

  // Модальное окно с изменёнными в Бизнес.ру документами
	const [changedInBRuDocumentsModalActive, setChangedInBRuDocumentsModalActive] = useState(false);
  const [changedInBRuDocumentsModalData, setChangedInBRuDocumentsModalData] = useState({
    inventories: [],
    supplies: [],
    realizations: [],
    postings: [],
    charges: [],
    shiftings: [],
    factories: []
  });

  // Тип и порядок сортировки
  const [sortData, setSortData] = useState({
    type: 'name',
    order: 'ASC'
  });

  // Функция сортировки
  const handleSortElementClick = (name) => {
    setSortData((prevState) => ({
      type: name,
      order: prevState.type === name ? prevState.order === 'ASC' ? 'DESC' : 'ASC' : 'ASC'
    }));
  };

  const getSortedLines = () => {
    const sortedItems = structuredClone(displayedLines).sort((a, b) => {
      const sortOrder = sortData.order === 'ASC'
        ? sortData.type === 'article'
            ? Number(a[sortData.type]) - Number(b[sortData.type])
            : sortStringValues(a[sortData.type], b[sortData.type])
        : sortData.type === 'article'
            ? Number(b[sortData.type]) - Number(a[sortData.type])
            : -sortStringValues(a[sortData.type], b[sortData.type]);

      return sortOrder;
    });

    return sortedItems;
  }

  // Обработчик ввода текста примечания
  const handleModalTextAreaChange = (evt) => {
    const textAreaValue = evt.currentTarget.value;
    dispatch(setSupplyCommentAction(textAreaValue));
  };

  // Обработчик добавления файла к примечанию в модальном окне
  const handleFileInputChange = async (evt) => {
    const inputFiles = Object.values(evt.currentTarget.files);
    const updatedFiles = [...commentFiles, ...inputFiles];

    dispatch(setCommentFilesAction(updatedFiles));
  };

  // Обработчик удаления файла из модального окна примечания
  const handleCommentFileRemoveButtonClick = async (url) => {
    await dispatch(deleteSupplyCommentFileAsyncAction({filename: url}));
    dispatch(fetchSupplyDetailsAsyncAction(id));
  };

  // Обработчик поля ввода имени бригадира
  const handleEmployeeInputChange = (_name, employee) => {
    dispatch(setSupplyEmployeeAction(employee));
  };

  // Обработчик селекта выбора склада
  const handleStorageSelect = (newStorage) => {
    const handler = async () => {
      const updatedLines = structuredClone(lines)
        .reduce((res, line) => (res[line.article] ? res : {
          ...res,
          [line.article]: {
            ...line,
            userPositions: line.userPositions.map((position) => ({
              ...position,
              amount: line.total_incoming_amount,
              address: ''
            }))
          }
        }), {});
      const deletedLines = lines.filter((line) => !Object.values(updatedLines)
        .map((updLine) => updLine.userPositions[0].supplies_item_id)
          .includes(line.userPositions[0].supplies_item_id));
      const deletedSuppliesItemsIds = deletedLines.map((updLine) => updLine.userPositions[0].supplies_item_id);

      for (let i = 0; i < deletedSuppliesItemsIds.length; i++) {
        await dispatch(deleteSuppliesItemAsyncAction(deletedSuppliesItemsIds[i]));
      }

      await dispatch(saveSupplyAsyncAction({
        id,
        body: {
          form: updatedLines,
          document: {
            date: newSupplyDate ? `${newSupplyDate}${documentDate.slice(-9)}` : documentDate
          },
          storage: newStorage,
          employee,
          comment
        }
      }));

      dispatch(fetchSupplyDetailsAsyncAction(id));

      alert('', 'default', 1)
    };

    if (storage) {
      if (newStorage !== storage) {
        alert('Введённые данные будут потеряны. Вы уверены, что хотите изменить склад?', 'danger', 0, [
          {
            text: 'Да',
            handler,
            needToCloseImmediately: true
          },
          {
            text: 'Нет',
            handler: () => alert('', 'default', 1)
          }
        ]);
      }
    } else {
      dispatch(setSupplyStorageAction(newStorage));
    }

    return true;
  };

  // Обработчик селекта выбора общего адреса
  const handleSharedAddressSelect = (address) => {
    const updatedLines = structuredClone(lines)
        .map((line) => ({
          ...line,
          userPositions: line.userPositions.map((position) => ({
            ...position,
            address
          }))
        }));

      dispatch(setSupplyFormAction(updatedLines));
  };

  // Отправка файлов примечания на сервер
  const sendCommentFiles = async (id, files) => {
    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append(`file_${index}`, file);
    });

    await dispatch(postSupplyCommentFilesAsyncAction({
      id,
      files: formData
    }));
  };

  // Обработчик кнопки закрытия модального окна примечания
  const handleCommentModalCloseButtonClick = async () => {
    const newFiles = commentFiles.filter((file) => file instanceof File);

    if (id && canUserUpdate) {
      await dispatch(saveSupplyAsyncAction({
        id,
        body: {
          form: lines,
          document: {
            date: newSupplyDate ? `${newSupplyDate}${documentDate.slice(-9)}` : documentDate
          },
          storage,
          employee,
          comment
        }
      }));

      if (newFiles.length) {
        await sendCommentFiles(id, newFiles);
      }

      dispatch(fetchSupplyDetailsAsyncAction(id));
    }

    setCommentModalActive(false);
  };

  // Запрос детальной информации о перемещении
  const requestForSupplyDetails = async () => {
    setIsPreloaderActive(true);
    await dispatch(fetchSupplyDetailsAsyncAction(id));
    setIsPreloaderActive(false);
  };
  useEffect(() => {
    requestForSupplyDetails();
  }, [id]);

  // Запрос списка складов
  useEffect(() => {
    if (!storages.length) {
      dispatch(fetchStores());
    }
  }, []);

  // Запрос списка адресов выбранного склада
  useEffect(() => {
    dispatch(fetchStorageAddressesAsyncAction(storage));
  }, [storage]);

  // Ресет стейта при отмонтировании компонента
  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

  // Установка товаров при изменении сортировки (handleSortElementClick)
  useEffect(() => {
    const sortedItems = structuredClone(lines).sort((a, b) => {
      const sortOrder = sortData.order === 'ASC'
        ? sortData.type === 'article'
            ? Number(a[sortData.type]) - Number(b[sortData.type])
            : sortStringValues(a[sortData.type], b[sortData.type])
        : sortData.type === 'article'
            ? Number(b[sortData.type]) - Number(a[sortData.type])
            : -sortStringValues(a[sortData.type], b[sortData.type]);

      return sortOrder;
    });

    dispatch(setSupplyFormAction(sortedItems));
  }, [sortData.order, sortData.type]);

  // Запись хлебных крошек
	useEffect(() => {
    const crumbs = isInArchive ? [
      {name: 'Архивы', url: AppRoute.Archive.ALL},
      {name: DocumentTypeNameMap[DocumentType.SUPPLY], url: AppRoute.Archive.SUPPLIES},
			{name: `№ ${documentNumber}`, url: ''}
    ] : [
      {name: DocumentTypeNameMap[DocumentType.SUPPLY], url: AppRoute.Document.SUPPLIES},
			{name: `№ ${documentNumber}`, url: ''}
    ];

		setCrumbs(crumbs);
	}, [documentNumber, isInArchive]);

  // Защита от открытия архивного документа с помощью адресной строки в основном списке и наоборот
  // Если пользователь намеренно вводит в адресной строке "/factories/<id архивного документа>"
  useEffect(() => {
    const shouldGoToArchive = !isInArchive && done === 1;
    const shouldGoToGeneralList = isInArchive && done === 0;

    if (shouldGoToArchive || shouldGoToGeneralList) {
      const route = isInArchive
        ? `${AppRoute.Document.SUPPLIES}/${id}`
        : `${AppRoute.Archive.SUPPLIES}/${id}`;

      navigate(route);
    }
  }, [done]);

  return (
    <>
      <Preloader isActive={isPreloaderActive}/>

      <SuppliesFloatPanel
        setCommentModalActive={setCommentModalActive}
        setIsPreloaderActive={setIsPreloaderActive}
        addresses={addresses}
        displayedLines={displayedLines}
        setCheckValuesModalData={setCheckValuesModalData}
        setIsCheckValuesModalActive={setIsCheckValuesModalActive}
        changedInBusinessRuDocuments={changedInBusinessRuDocuments}
        setChangedInBRuDocumentsModalActive={setChangedInBRuDocumentsModalActive}
        setChangedInBRuDocumentsModalData={setChangedInBRuDocumentsModalData}
      />

      <table className={tableClasses.join(" ")}>
        <thead className={styles.top0 + " theadBordered thead-dark"}>
          <tr>
            <th colSpan="2" className={styles.borderNone}>
              Поступление № {documentNumber}
            </th>
            <th className={styles.borderNone}>
              <span>от </span>
              {/* {
                !provided && canUserUpdate ? (
                  <Input
                    type="text"
                    name="documentDate"
                    autocomplete="off"
                    mask="99.99.9999"
                    placeholder="Дата"
                    value={newSupplyDate ? newSupplyDate : documentDate}
                    setValue={(name, value) => dispatch(setNewSupplyDateAction(value))}
                  />
                ) : documentDate.split(' ').slice(0, -1).join(' ')
              } */}
              {documentDate.split(' ').slice(0, -1).join(' ')}
            </th>
            <th>
              {
                author && (
                  <>
                    Автор:&nbsp;
                    {author}
                    <br/>
                  </>
                )
              }
              Бригадир:&nbsp;
              {
                provided
                  ? (
                    <span>{employee}</span>
                  )
                  : (
                    <Input
                      type="text"
                      name="employee"
                      value={employee}
                      setValue={handleEmployeeInputChange}
                      placeholder="Бригадир"
                      title={employee}
                    />
                  )
              }
            </th>
            <th colSpan={2}>
              Склад:&nbsp;
              {
                provided === 0
                  ? (
                    <CustomSearchSelect
                      inputName='storage'
                      defaultValue={storage}
                      options={storages}
                      onChange={handleStorageSelect}
                      placeholder='Склад'
                    />
                  )
                  : storage
              }
            </th>
            <th>
              {
                provided === 0 && (
                  <CustomSearchSelect
                    inputName='shared-address'
                    defaultValue={''}
                    options={storage ? addresses : []}
                    onChange={handleSharedAddressSelect}
                    placeholder='Адрес&nbsp;для&nbsp;всех'
                    clearInputAfterOptionSelection={true}
                  />
                )
              }
            </th>
          </tr>
          <tr>
            <th>№</th>
            <th
              className={clsx('sort', {
                'sorted': sortData.type === 'name',
                'rotated': sortData.type === 'name' && sortData.order === 'ASC'
              })}
              onClick={() => handleSortElementClick('name')}
            >
              <span>Название</span>
            </th>
            <th
              className={clsx('sort', {
                'sorted': sortData.type === 'article',
                'rotated': sortData.type === 'article' && sortData.order === 'ASC'
              })}
              onClick={() => handleSortElementClick('article')}
            >
              <span>Артикул</span>
            </th>
            <th
              className={clsx('sort', {
                'sorted': sortData.type === 'b_group',
                'rotated': sortData.type === 'b_group' && sortData.order === 'ASC'
              })}
              onClick={() => handleSortElementClick('b_group')}
            >
              Группа
            </th>
            <th>Поступило всего:</th>
            <th>Поступило на площадку:</th>
            <th>Адрес</th>
          </tr>
        </thead>
        <tbody>
          {
            getSortedLines().map((line, index) =>
              <SupplyTableRow
                key={index}
                supplyId={id}
                lineIndex={index}
                line={line}
                storage={storage}
                addresses={addresses}
                provided={provided}
                setIsPreloaderActive={setIsPreloaderActive}
              />
            )
          }
          <tr style={{height: "300px"}}/>
        </tbody>
      </table>

      <Modal isActive={commentModalActive} setIsActive={handleCommentModalCloseButtonClick}>
        {
          !isInArchive && canUserUpdate ? (
            <>
              <textarea
                value={comment}
                onChange={handleModalTextAreaChange}
                placeholder="Примечание"
              />
              <input
                type="file"
                name="files[]"
                onChange={handleFileInputChange}
                multiple
              />
            </>
          ) : (
            <div className={styles.commentFrame}>
              {comment}
            </div>
          )
        }
        {
          commentFiles.map((file) => (
            <div className="fileIconGroup">
              {
                !isInArchive && canUserUpdate && (
                  <span
                    className="material-icons remove"
                    title="Удалить"
                    onClick={() => handleCommentFileRemoveButtonClick(file.url)}
                  >
                    cancel
                  </span>
                )
              }
              <span
                className="material-icons file"
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_SERVER_URL + file.url,
                    "_blank"
                  )
                }
              >
                description
              </span>
              <span
                className="name"
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_SERVER_URL + file.url,
                    "_blank"
                  )
                }
              >
                {file.name}
              </span>
            </div>
          ))
        }
      </Modal>

      <SupplyCheckValuesModal
        data={checkValuesModalData}
        isActive={isCheckValuesModalActive}
        setIsActive={setIsCheckValuesModalActive}
      />

      {
        !isInArchive && (
          <ChangedInBusinessRuDocumentsModal
            changedInBusinessRuDocuments={changedInBRuDocumentsModalData}
            changedInBRuDocumentsModalActive={changedInBRuDocumentsModalActive}
            setChangedInBRuDocumentsModalActive={setChangedInBRuDocumentsModalActive}
          />
        )
      }
    </>
  );
};

export default SuppliesNew;
