import {Fragment, useContext, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";

import InputDate from "components/input/InputDate";

import {AppContext} from "providers/AppContextProvider";
import {getUser} from 'helpers/storage';

import cl from "styles/pages/[fieldId].module.scss";
import styles from './styles.module.scss';

const Index = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();
	const searchParams = useSearchParams();

	const LOCATION_REG_EXP = /archive/;

	const id = params.id;
	const pathName = location.pathname
	const isInArchive = LOCATION_REG_EXP.test(pathName);

	const goodName = searchParams[0].get('name');
	const userLevel = getUser().accessLevel;
  
	const {alert, setCrumbs, setMenuCondition} = useContext(AppContext);

	const dates = useSelector((state) => state.history).archiveNames.dates;

	// Классы таблиц
	const tableClasses = [cl.mainTable, cl.top0, "table", "table-responsive"];
	
	// Данные с сервера
	const [history, setHistory] = useState([]);
	const [totalStart, setTotalStart] = useState({});
	const [totalEnd, setTotalEnd] = useState({});
	
	// Редактирование для админа
	const [editable, setEditable] = useState(false);
	const [deleted] = useState([]);
	
	// Период выборки
	const [period, setPeriod] = useState({before: "", after: ""});
	
	// Вывод уведомления при переходе по ссылке, если есть несохраненные данные
	useEffect(() => {
		if(!deleted.length){
			setMenuCondition({});
			return;
		}
		
		// Установка условия с обязательной очисткой после перехода по ссылке
		setMenuCondition({
			text: "Есть несохраненные данные. Сохранить?",
			type: "danger",
			duration: 10000,
			buttons: [
				{
					text: "Да",
					handler: function(href){
						editing();
						navigate(href);
						setTimeout(() => setMenuCondition({}), 1);
					}
				},
				{
					text: "Нет",
					handler: function(href){
						alert("", "danger", 1);
						navigate(href);
						setTimeout(() => setMenuCondition({}), 1);
					}
				}
			]
		});
	}, [deleted]);
	
	// Выборка за период
	useEffect(() => {
		if (isInArchive && id) {
			axios.post(`history/getArchive/${id}`, {
				name: goodName,
				before: period.before,
				after: period.after
			}).then((response) => {
				setHistory(response.data.result);
				setTotalStart(response.data.total_start);
				setTotalEnd(response.data.total_end);
			}).catch(() => {
				setHistory([]);
				setTotalStart({});
				setTotalEnd({});
			});
		} else {
			axios.post("history/getHistory", {
				name: goodName,
				before: period.before,
				after: period.after
			}).then((response) => {
				setHistory(response.data.result);
				setTotalStart(response.data.total_start);
				setTotalEnd(response.data.total_end);
			}).catch(() => {
				setHistory([]);
				setTotalStart({});
				setTotalEnd({});
			});
		}
	}, [period]);
	
	// Запись хлебных крошек
	useEffect(() => {
		const crumbs = isInArchive && id
		  ? [
				  {name: "Архивы", url: "/archive"},
				  {name: "История", url: '/archive/history'},
				  {name: "Сезон " + dates, url: `/archive/history/${id}`},
				  {name: goodName, url: `/archive/history/${id}/item?name=${goodName}`}
			  ]
			: [
				  {name: "История", url: "/history/select"},
			    {name: goodName, url: ""}
			  ];

		setCrumbs(crumbs);
	}, []);
	
	// Печать (скачивание) данных истории
	const print = () => {
		axios.post("history/printHistoryTable", {
			name: goodName,
			article: history[0].article,
			b_group: history[0].b_group,
			history: history,
			totalStart: totalStart,
			totalEnd: totalEnd,
			period: period
		}).then(response => {
			window.open(process.env.REACT_APP_SERVER_URL + "/FILES/" + response.data.filename);
		}).catch(() => {
			alert("Ошибка скачивания", "danger");
		});
	}
	
	// Удаление строки из таблицы
	const deleteLine = (index) => {
		deleted.push(history[index.index].positions[index.ind].id);
		
		const copy = structuredClone(history);
		copy[index.index].positions.splice(index.ind, 1);
		setHistory(copy);
	}
	
	// Вкл/выкл редактирование и отправка данных
	const editing = () => {
		// Вкл/выкл редактирование
		if(!editable){
			setEditable(true);
			return false;
		} else setEditable(false);
		
		// Флаг о показанном уведомлении, чтобы не дублировать
		let showedAlertSuccess = false;
		
		// Удаление строк, сохраненных в состоянии
		if(deleted.length > 0){
			axios.post("history/deleteData", {
				deleted: deleted
			}).then(() => {
				if(!showedAlertSuccess) alert("Успешно сохранено", "success");
				deleted.length = 0;
			}).catch(() => {
				alert("Возникли ошибки со стороны сервера", "danger");
			});
		}
	}
	
	// Изменение периода
	const changePeriod = (name, value) => {
		const copy = structuredClone(period);
		copy[name] = value;
		setPeriod(copy);
	}

	// Клик по ячейке названия системного документа,
	// которому соответствует запись в Истории
	const handleSystemDocumentCellClick = (line, ind) => {
		const isManualCorrection = (/Ручная коррекция журнала/).test(line.system_docs[ind].name);
		const isDocumentWithoutId = Number(line.system_docs[ind].id) === 0;

		if (isManualCorrection || isDocumentWithoutId) {
			return;
		}

		window.open(line.system_docs[ind].url, "_blank");
	};

	const handleClearDatesButtonClick = () => {
		setPeriod({before: '', after: ''});
	};

	return (
		<>
			<div className={cl.floatPanel + " " + cl.controlsPanel}>
				{
					userLevel > 2 &&
					<span className="roundIcon material-icons" onClick={() => editing()}
							title={editable ? "Сохранить" : "Редактировать"}>
						{editable ? "save" : "edit_note"}
					</span>
				}
				<span
				  className="roundIcon material-icons"
					title="Скачать"
					onClick={print}
				>
					download
				</span>
				<span
				  className="roundIcon material-icons"
					title="Перейти в архив"
					onClick={() => navigate("/archive/history")}
				>
					archive
				</span>
				<span
				  className="roundIcon material-icons"
					onClick={() => window.open("/faq/history", "_blank")}
					title="Инструкция"
				>
					quiz
				</span>
			</div>

			<div className="stickyContainer">
				<table className={tableClasses.join(" ")}>
					<thead className={cl.top0 + " theadBordered thead-dark"}>
						<tr>
							<th colSpan="7">
								Движение товара: {goodName}, арт. {history.length ? history[0].article : ""},
								группа {history.length ? history[0].b_group : ""}
							</th>
							<th>
								За период:
							</th>
							<th colSpan="3">
								{<InputDate name="after" currentValue={period.after} changeHandler={changePeriod}/>}
								<span> - </span>
								{<InputDate name="before" currentValue={period.before} changeHandler={changePeriod}/>}
								<button
									className={styles.datesClearButton}
									onClick={handleClearDatesButtonClick}
									title="Очистить даты"
								>
									<span>X</span>
								</button>
							</th>
						</tr>
						<tr>
							<th rowSpan="2">№</th>
							<th rowSpan="2">Дата и время</th>
							<th rowSpan="2">Приход</th>
							<th rowSpan="2">Расход</th>
							<th rowSpan="2">Остаток</th>
							<th colSpan="4">По базе</th>
							<th colSpan="2">По Системе учета</th>
						</tr>
						<tr>
							<th colSpan="3">Документ</th>
							<th>Автор</th>
							<th>Документ</th>
							<th>Автор</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td colSpan="4">Входящий остаток:</td>
							<td className={cl.right}>
								{new Intl.NumberFormat("ru-RU").format(totalStart.total)}
							</td>
							<td colSpan="6"/>
						</tr>
						{
							history.map((line, index) => {

								return (
									<Fragment key={index.toString()}>
										{
											line.docs.map((doc, ind) => {
												const involvedPositions = line.positions.filter((item) => Number(item.start) !== Number(item.end));
												const displayedCountPlus = involvedPositions[ind] && Number(involvedPositions[ind].start) - Number(involvedPositions[ind].end) < 0 ? Number(involvedPositions[ind].start) - Number(involvedPositions[ind].end) : 0;
												const displayedCountMinus = involvedPositions[ind] && Number(involvedPositions[ind].start) - Number(involvedPositions[ind].end) > 0 ? Number(involvedPositions[ind].start) - Number(involvedPositions[ind].end) : 0;;

												return (
													<tr key={ind.toString()}>
														{
															ind === 0 && (
																<>
																	<td rowSpan={line.docs.length} className={cl.cifer + " " + cl.center}>
																		{index + 1}
																	</td>
																	<td rowSpan={line.docs.length} className={cl.date + " " + cl.center}>
																		{line.date}
																	</td>
																</>
															)
														}

														<td className={cl.right}>
															{new Intl.NumberFormat("ru-RU").format(line.docs.length > 1 ? displayedCountPlus : line.count_plus)}
														</td>
														<td className={cl.right}>
															{new Intl.NumberFormat("ru-RU").format(line.docs.length > 1 ? displayedCountMinus : line.count_minus)}
														</td>

														{
															ind === 0 && (
																<td rowSpan={line.docs.length} className={cl.right}>
																	{new Intl.NumberFormat("ru-RU").format(line.count_end)}
																</td>
															)
														}

														<td colSpan="3">{doc.name}</td>

														{
															ind === 0 && (
																<>
																	<td rowSpan={line.docs.length}>{line.author}</td>
																</>
															)
														}

														<td
															className={cl.router}
															onClick={() => handleSystemDocumentCellClick(line, ind)}
														>
															{line.system_docs[ind].name}
															{line.system_docs[ind].is_correction === '1' && !(/внутреннее/).test(line.system_docs[ind].name) ? ' (коррекция)' : ''}
															{line.docs.length > 1 && involvedPositions[ind] ? ` (${involvedPositions[ind].address})` : ''}
														</td>

														{
															ind === 0 && (
																<>
																	<td rowSpan={line.docs.length}>{line.author}</td>
																</>
															)
														}
														
														{
															editable &&
															<td className="iconed">
																<span
																	className="roundIcon material-icons"
																	onClick={() => deleteLine({index: index, ind: ind})}
																	title="Удалить строку"
																>
																	delete_outline
																</span>
															</td>
														}
													</tr>
												);
											}
											)
										}
									</Fragment>
								);
							})
						}
						{
							totalEnd.total >= 0 && (
								<>
									<tr>
										<td rowSpan={totalEnd.positions.length + 1} colSpan="4">Исходящий остаток:</td>
										<td rowSpan={totalEnd.positions.length + 1} className={cl.right}>
											{/* Общее кол-во с полей/площадок */}
											{/* {new Intl.NumberFormat("ru-RU").format(totalEnd.total)} */}

											{/* Общее кол-во из последнего элемента истории */}
											{new Intl.NumberFormat("ru-RU").format(history.length ? history[history.length - 1].count_end : totalStart.total)}
										</td>
										<td colSpan="2" className={cl.center}>В том числе:</td>
										<td rowSpan={totalEnd.positions.length + 1} colSpan="4"/>
									</tr>
									{
										totalEnd.positions.map((pos, ind) =>
											<tr key={ind.toString()}>
												<td className={cl.right}>{pos.end}</td>
												<td className={cl.right}>{pos.address}</td>
											</tr>
										)
									}
								</>
							)
						}
					</tbody>
				</table>
			</div>
		</>
	);
};

export default Index;
