import {createSlice, current} from '@reduxjs/toolkit';

import {
  fetchOfferDetailsAsyncAction,
  fetchOffersAsyncAction,
  refreshGoodAmountsAsyncAction
} from './offers-api-actions';

const initialState = {
  offersList: [],
  offer: {
    id: '',
    number: '',
    date: '',
    author: '',
    customer: '',
    order_number: '',
    provided: undefined
  },
  items: [],
  deliveryTerms: {
    orderReadiness: '',
    priceCondition: '',
    paymentSchedule: [''],
    expirationDate: ''
  }
};

export const offers = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    resetState: (state) => {
      state.offer = initialState.offer;
      state.deliveryTerms = initialState.deliveryTerms;
      state.items = [];
    },
    setItemsAction: (state, action) => {
      state.items = action.payload;
    },
    selectGoodAction: (state, action) => {
      const copiedItems = structuredClone(current(state)).items;
      const index = action.payload.index;
      const selectElementName = action.payload.selectElementName;
      const value = action.payload.value;
      const goods = action.payload.goods;

      copiedItems[index][selectElementName] = value;

      if (copiedItems[index].manual_input === 0) {
        if (selectElementName === 'name') {
          const plant = goods.find((good) => good.name === value);
          const plantArticle = plant.article;
          const goodId = plant.good_id;
  
          copiedItems[index].article = plantArticle;
          copiedItems[index].good_id = goodId;
        }
  
        if (selectElementName === 'article') {
          const plant = goods.find((good) => good.article === value);
          const plantName = plant.name;
          const goodId = plant.good_id;
  
          copiedItems[index].name = plantName;
          copiedItems[index].good_id = goodId;
        }
      }

      state.items = copiedItems;
    },
    selectOrderCommentValue: (state, action) => {
      const copiedItems = structuredClone(current(state)).items;
      const itemIndex = action.payload.itemIndex;
      const value = action.payload.value;

      copiedItems[itemIndex].order_comment = value;

      state.items = copiedItems;
    },
    setDiscountAction: (state, action) => {
      const offer = structuredClone(current(state));
      const offerItems = offer.items;

      state.items = offerItems.map((item) => (item.manual_discount === 1 ? item : {
        ...item,
        discount: Number(action.payload),
        discount_price: Math.ceil(Number(item.price) * (1 - Number(action.payload) / 100)),
      }));
    },
    setOrderReadiness: (state, action) => {
      state.deliveryTerms.orderReadiness = action.payload;
    },
    setPriceCondition: (state, action) => {
      state.deliveryTerms.priceCondition = action.payload;
    },
    setPaymentSchedule: (state, action) => {
      state.deliveryTerms.paymentSchedule = action.payload;
    },
    setExpirationDate: (state, action) => {
      state.deliveryTerms.expirationDate = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOffersAsyncAction.fulfilled, (state, action) => {
        state.offersList = action.payload.result;
      })
      .addCase(fetchOfferDetailsAsyncAction.fulfilled, (state, action) => {
        state.offer = action.payload.offer;
        state.items = action.payload.items;
        state.deliveryTerms = action.payload.deliveryTerms;
      })
      .addCase(refreshGoodAmountsAsyncAction.fulfilled, (state, action) => {
        const selectedItemIndex = action.payload.index;
        const goodId = action.payload.good_id;
        const offer = structuredClone(current(state));
        const offerItems = offer.items;
        const discount = offer.items[0].discount;
        const selectedItem = offerItems[selectedItemIndex];
        const emptyItemPositions = [{
          selected_amount: 0,
          total_storage_amount: 0,
          storage: 'not found'
        }];

        if (action.payload.data.result.remains && action.payload.data.result.remains.length) {
          let orderAmount = selectedItem.order_amount;
          const itemPositions = action.payload.data.result.remains
            .filter((remainsItem) => Number(remainsItem.amount.total) > 0 && remainsItem.store.name !== 'Основной склад')
            .sort((a, b) => Number(a.amount.total) > Number(b.amount.total))
            .map((remainsItem) => {
              const selectedAmount = orderAmount > Number(remainsItem.amount.total)
                ? Number(remainsItem.amount.total)
                : orderAmount;

              orderAmount = orderAmount - selectedAmount;

              return {
                selected_amount: selectedAmount,
                total_storage_amount: Number(remainsItem.amount.total),
                storage: remainsItem.store.name
              };
            });

          const preparedItemPositions = itemPositions.length ? itemPositions : emptyItemPositions;

          selectedItem.positions = preparedItemPositions;
        } else {
          selectedItem.positions = emptyItemPositions;
        }

        selectedItem.price = action.payload.data.result.prices && action.payload.data.result.prices[0]
          ? Number(action.payload.data.result.prices[0].price)
          : 0;
        selectedItem.discount_price = action.payload.data.result.prices && action.payload.data.result.prices[0]
          ? Math.ceil(Number(action.payload.data.result.prices[0].price) * (1 - discount / 100))
          : 0;
        selectedItem.good_id = Number(goodId);

        state.items = [
          ...offerItems.slice(0, selectedItemIndex),
          selectedItem,
          ...offerItems.slice(selectedItemIndex + 1)
        ];
      });
  }
});

export const {
  resetState,
  setItemsAction,
  selectGoodAction,
  selectOrderCommentValue,
  setDiscountAction,
  setOrderReadiness,
  setPriceCondition,
  setPaymentSchedule,
  setExpirationDate
} = offers.actions;
