import CustomSearchSelect from 'components/custom-search-select/custom-search-select';
import {useDispatch, useSelector} from 'react-redux';
import {
  getRealizationAuthor,
  getRealizationComment,
  getRealizationCommentFiles,
  getRealizationDocumentDate,
  getRealizationDocumentNumber,
  getRealizationDoneStatus,
  getRealizationEmployee,
  getRealizationItems,
  getRealizationProvidedStatus
} from 'redux/slices/realizations/selectors';
import Input from 'components/input/InputComponent';

import styles from './styles.module.scss';
import {Fragment, useContext, useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {setRealizationDocumentNumberAction, setRealizationEmployeeAction, setRealizationItemsAction} from 'redux/slices/realizations/realizations';
import {restrictedGroupsIds} from 'constants/document-data';
import {sortStringValues} from 'helpers/utils';
import {getArticles, getGoods} from 'redux/slices/good/selectors';
import {setRealizationInitialAmountsAsyncAction} from 'redux/slices/realizations/realizations-api-actions';
import clsx from 'clsx';
import {AppContext} from 'providers/AppContextProvider';

const RealizationTable = () => {
  const dispatch = useDispatch();

  const {alert} = useContext(AppContext);

  // Классы таблицы
	const tableClasses = [styles.mainTable, styles.top0, "table", "table-responsive"];

  const realizationItems = useSelector(getRealizationItems);
  const commentFiles = useSelector(getRealizationCommentFiles);
	const provided = useSelector(getRealizationProvidedStatus);
	const done = useSelector(getRealizationDoneStatus);
	const employee = useSelector(getRealizationEmployee);
	const author = useSelector(getRealizationAuthor);
	const documentNumber = useSelector(getRealizationDocumentNumber);
	const documentDate = useSelector(getRealizationDocumentDate);
	const comment = useSelector(getRealizationComment);

  const goods = useSelector(getGoods);
  const articles = useSelector(getArticles);
  const groups = useSelector((state) => state.business).groups.data
    .filter((group) => !restrictedGroupsIds.includes(Number(group.id)));
  const addressList = useSelector((state) => state.inventory.addresses.data);
	const sortedAddresses = structuredClone(addressList).sort((a, b) => {
    const ADDRESS_REG_EXP = /^\d+/;
    const prevValue = a.value;
    const nextValue = b.value;

    if (ADDRESS_REG_EXP.test(prevValue) && ADDRESS_REG_EXP.test(nextValue)) {
      return prevValue.match(ADDRESS_REG_EXP)[0] - nextValue.match(ADDRESS_REG_EXP)[0];
    }
    if (ADDRESS_REG_EXP.test(prevValue) && !ADDRESS_REG_EXP.test(nextValue)) {
      return -1;
    }
    if (!ADDRESS_REG_EXP.test(prevValue) && ADDRESS_REG_EXP.test(nextValue)) {
      return 1;
    }
    
    return 0;
  });

  // Список уже выбранных наименований
	const itemsNames = structuredClone(realizationItems).map((itm) => itm.name);

  // Тип и порядок сортировки
  const [sortData, setSortData] = useState({
    type: 'name',
    order: 'ASC'
  });

  // Функция сортировки
  const handleSortElementClick = (name) => {
    setSortData((prevState) => ({
      type: name,
      order: prevState.type === name ? prevState.order === 'ASC' ? 'DESC' : 'ASC' : 'ASC'
    }));
  };

  // Установка товаров при изменении сортировки (sortFn)
  useEffect(() => {
    const sortedItems = structuredClone(realizationItems).sort((a, b) => {
      const sortOrder = sortData.order === 'ASC'
        ? sortData.type === 'article'
            ? Number(a[sortData.type]) - Number(b[sortData.type])
            : sortStringValues(a[sortData.type], b[sortData.type])
        : sortData.type === 'article'
            ? Number(b[sortData.type]) - Number(a[sortData.type])
            : -sortStringValues(a[sortData.type], b[sortData.type]);

      return sortOrder;
    });

    dispatch(setRealizationItemsAction(sortedItems));
  }, [itemsNames.length, sortData]);

  // Обработчик клика по неактивной дате документа
	const handleInactiveDocumentDateClick = () => {
		toast.error('Если нужна другая дата - измените её в соответствующем документе в Бизнес.ру', {
			position: 'top-left'
		});
	};

  const handleEmployeeInputChange = (value) => {
    dispatch(setRealizationEmployeeAction(value));
  };

  const handleDocumentNumberInputChange = (value) => {
    dispatch(setRealizationDocumentNumberAction(value))
  };

  // Получить список наименований для селекта для вновь добавленной строки (в самом низу)
  const filterSelectComponentOptions = (selectComponentName, selectedGroupId) => {
    // Отсеиваем те товары, которые уже есть в инвентаризации
    const filteredPlants = goods
      .filter((plant) => !itemsNames.includes(plant.name))
      .sort((a, b) => {
        const res = sortStringValues(a.value, b.value);
        return res;
      });
    const filteredArticles = filteredPlants.map((plant) => ({
      id: plant.b_group_id,
      name: plant.article,
      value: plant.article
    }));

    // Если в новой строке группа уже выбрана, то фильтруем список для селекта ещё и по группе
    if (selectedGroupId && groups.length) {
      const filteredByGroupPlants = filteredPlants
        .filter((plant) => plant.b_group_id === selectedGroupId)
        .sort((a, b) => {
          const res = sortStringValues(a.value, b.value);
          return res;
        });
      const filteredByGroupArticles = filteredByGroupPlants.map((plant) => ({
        id: plant.b_group_id,
        name: plant.article,
        value: plant.article
      }));

      switch(selectComponentName) {
        case 'name':
          return filteredByGroupPlants;
        case 'article':
          return filteredByGroupArticles;
        default:
          return;
      };
    }

    switch(selectComponentName) {
      case 'name':
        return filteredPlants;
      case 'article':
        return filteredArticles;
      default:
        return;
    };
  };

  // Метод изменения наименования
	const changeName = (index, name, value) => {
		if(name === "amounts" && realizationItems[index].name === "") return;
		
		const copy = structuredClone(realizationItems);
		
		// Проверка дублирующей позиции
		let error = false;
		copy.forEach(cp => {
			if(cp[name] === value) error = true;
		});
		
		// Вывод ошибки, если это не группа
		if(error && name !== "b_group"){
			toast.error("Такой товар уже есть в списке!", {
				position: 'bottom-right'
			});
			copy.splice(index, 1);
		} else copy[index][name] = value;

		// Установка соответствующих значений остальных параметров (группа и артикул/наименование)
		if (name === 'name') {
			const plantArticle = articles.find((article) => article.good === value);
			copy[index].article = plantArticle.value;
			copy[index].b_group = groups.find((group) => group.id === plantArticle.b_group_id).value;
		}
		if (name === 'article') {
			const plantName = goods.find((plantName) => plantName.article === value);
			copy[index].name = plantName.value;
			copy[index].b_group = groups.find((group) => group.id === plantName.b_group_id).value;
		}
		
		dispatch(setRealizationItemsAction(copy));

		// Если товар выбран (наименование определено), то запрашиваем данные с полей
		if (copy[index].name) {
      dispatch(setRealizationInitialAmountsAsyncAction({
				name: copy[index].name,
				date: documentDate
			}));
    }
	};

  // Изменение позиций
	const onChangePos = (index, name, value) => {
		const copy = structuredClone(realizationItems);
    const insertedValue = value < 0 ? 0 : value;

		copy[index.index].userPositions[index.ind][name] = insertedValue;
		
		dispatch(setRealizationItemsAction(copy));
	};

  // Формирование списка адресов для выпадающего списка
	const getDisplayedAddresses = (index) => {
		const selectedAddresses = realizationItems[index].userPositions.map((position) => position.address);
		const displayedAddresses = sortedAddresses.filter((address) => !selectedAddresses.includes(address.value));

		return displayedAddresses;
	};

  // Новая строка
	const addLine = () => {
		const copy = structuredClone(realizationItems);
		copy.push({
			name: "",
			totalAmount: 0,
			amounts: [{
				amount: "",
				address: ""
			}],
			userPositions: [{
				amount: "",
				address: ""
			}]
		});
		dispatch(setRealizationItemsAction(copy));
	};

	// Удаление строки
	const deleteLine = (index) => {
		alert("Удалить строку?", "danger", 0, [
			{
				text: "Да",
				handler: function(){
					const copy = structuredClone(realizationItems);
					copy[index.index].amounts.splice(index.i, 1);
					copy[index.index].userPositions.splice(index.i, 1);
					if(!copy[index.index].amounts.length) copy.splice(index.index, 1);
					dispatch(setRealizationItemsAction(copy));
				},
				needToCloseImmediately: true
			},
			{
				text: "Нет",
				handler: function(){
					alert("", "default", 1);
				}
			}
		]);
	};

  const handleCommentTextAreaChange= (index, value) => {
    const updatedItems = structuredClone(realizationItems);

    updatedItems[index].item_comment = value;

    dispatch(setRealizationItemsAction(updatedItems));
  };

  return (
    <table className={tableClasses.join(" ")}>
      <thead className={`${styles.top0} theadBordered thead-dark`}>
        <tr>
          <th colSpan="2" className={styles.borderNone}>
            Отгрузка (реализация) №&nbsp;
            {documentNumber}
            {/* {
              realizationItems.length && realizationItems && provided === 0
                ? (
                  <Input
                    type="text"
                    name="documentNumber"
                    autocomplete="off"
                    placeholder="№"
                    value={documentNumber}
                    setValue={(name, value) => handleDocumentNumberInputChange(value)}
                  />
                )
                : documentNumber
            } */}
          </th>
          <th className={styles.borderNone}>
            <span>от </span>
            <span onClick={handleInactiveDocumentDateClick}>
              {documentDate.split(' ')[0]}
            </span>
          </th>
          <th>
            {
              realizationItems.length && author &&
              <>
                Автор:&nbsp;
                {author}
                <br/>
              </>
            }
            Бригадир:&nbsp;
            {
              !provided && realizationItems.length ? (
                <Input
                  type="text"
                  name="employee"
                  value={employee}
                  setValue={(name, value) => handleEmployeeInputChange(value)}
                  placeholder="Бригадир"
                  title={employee}
                />
              ) : (
                <span>{employee}</span>
              )
            }
          </th>
          <th colSpan="3">Текущие остатки в Системе:</th>
          <th rowSpan="3">Кол-во<br/>по заказу</th>
          <th colSpan="3">Переместить на Основной склад</th>
          {
            realizationItems.length && provided === 0 && (
              <th rowSpan="3" className={styles.borderNone}/>
            )
          }
        </tr>
        <tr>
          <th rowSpan="2">№</th>
          <th
            rowSpan="2"
            className={clsx('sort', {
              'sorted': sortData.type === 'name',
              'rotated': sortData.type === 'name' && sortData.order === 'ASC'
            })}
            onClick={() => handleSortElementClick('name')}
          >
            <span>Название</span>
          </th>
          <th
            rowSpan="2"
            className={clsx('sort', {
              'sorted': sortData.type === 'article',
              'rotated': sortData.type === 'article' && sortData.order === 'ASC'
            })}
            onClick={() => handleSortElementClick('article')}
          >
            <span>Артикул</span>
          </th>
          <th rowSpan="2">Группа</th>
          <th rowSpan="2">Всего:</th>
          <th colSpan="2">В том числе:</th>
          <th rowSpan="2">Кол-во</th>
          <th rowSpan="2">Со склада</th>
          <th rowSpan="2">Примечание</th>
        </tr>
        <tr>
          <th>Кол-во</th>
          <th>Адрес</th>
        </tr>
      </thead>
      <tbody>
        {
          realizationItems.length && realizationItems.map((line, index) =>
            <Fragment key={index.toString()}>
              {
                line.amounts.map((ln, i) =>
                  <tr key={i.toString()} className={line.isError ? styles.error : ""}>
                    {
                      i === 0 && (
                        <>
                          <td rowSpan={line.amounts.length}>{index + 1}</td>
                          <td rowSpan={line.amounts.length}>
                            {
                              line.name === ""
                              ? (
                                <CustomSearchSelect
                                  inputName='name'
                                  defaultValue={line.name}
                                  options={filterSelectComponentOptions('name', line.b_group_id)}
                                  onChange={(value) => changeName(index, 'name', value)}
                                  placeholder='Наименование'
                                />
                              )
                              : line.name
                            }
                          </td>
                          <td rowSpan={line.amounts.length}>
                            {
                              line.name === ""
                              ? (
                                <CustomSearchSelect
                                  inputName='article'
                                  defaultValue={line.article}
                                  options={filterSelectComponentOptions('article', line.b_group_id)}
                                  onChange={(value) => changeName(index, 'article', value)}
                                  placeholder='Артикул'
                                />
                              )
                              : line.article
                            }
                          </td>
                          <td rowSpan={line.amounts.length}>
                            {
                              line.name === ""
                              ? (
                                <CustomSearchSelect
                                  inputName='b_group'
                                  defaultValue={line.b_group}
                                  options={groups}
                                  onChange={(value) => changeName(index, 'b_group', value)}
                                  placeholder='Группа'
                                />
                              )
                              : line.b_group
                            }
                          </td>
                          <td rowSpan={line.amounts.length} className={styles.right}>
                            {!line.isUsl && new Intl.NumberFormat("ru-RU").format(line.totalAmount)}
                          </td>
                        </>
                      )
                    }
                    
                    <td className={styles.right}>
                      {!line.isUsl && new Intl.NumberFormat("ru-RU").format(ln.amount)}
                    </td>
                    <td>{!line.isUsl && ln.address}</td>
                    
                    {
                      i === 0 && (
                        <td rowSpan={line.amounts.length} className={styles.right}>
                          {line.orderAmount ? new Intl.NumberFormat("ru-RU").format(line.orderAmount) : 0}
                        </td>
                      )
                    }
                    
                    <td className={styles.right}>
                      {
                        provided === 0
                          ? (
                            <Input
                              type="number"
                              name="amount"
                              placeholder="Кол-во"
                              value={line.userPositions[i].amount ? line.userPositions[i].amount : 0}
                              setValue={onChangePos}
                              currentIndex={{index: index, ind: i}}
                            />
                          )
                          : new Intl.NumberFormat("ru-RU").format(line.userPositions[i].amount)
                      }
                    </td>
                    
                    <td>
                      {
                        provided === 0 && !ln.address
                          ? (
                            <CustomSearchSelect
                              inputName='address'
                              defaultValue={ln.address}
                              options={getDisplayedAddresses(index)}
                              onChange={(value) => onChangePos({index: index, ind: i}, 'address', value)}
                              placeholder='Адрес'
                            />
                          )
                          : (
                            <span>{ln.address}</span>
                          )
                      }
                    </td>

                    {
                      i === 0 && (
                        <td rowSpan={line.amounts.length}>
                          {
                            provided === 1 ? (
                              line.item_comment
                            ) : (
                              <textarea
                                name="item_comment"
                                placeholder="Примечание"
                                value={line.item_comment}
                                onChange={(evt) => handleCommentTextAreaChange(index, evt.currentTarget.value)}
                              />
                            )
                          }
                        </td>
                      )
                    }
                    
                    {/* {
                      provided === 0 && (
                        <>
                          <td className="iconed">
                          <span
                            className="roundIcon material-icons"
                            onClick={() => deleteLine({index: index, i: i})}
                            title="Удалить строку">delete_outline</span>
                          </td>
                        </>
                      )
                    } */}
                  </tr>
                )
              }
            </Fragment>
          )
        }
        {/* {
          realizationItems.length && provided === 0 && (
            <tr className="adding">
              <td className="iconed">
                <span
                  className="roundIcon material-icons"
                  onClick={addLine}
                  title="Добавить наименование"
                >
                  add
                </span>
              </td>
            </tr>
          )
        } */}
      </tbody>
    </table>
  );
};

export default RealizationTable;
