import axios from 'axios';
import {toast} from 'react-toastify';
import {StatusCodes} from 'http-status-codes';

import {getToken, removeToken} from 'helpers/storage';

import {AuthStatus} from 'constants/auth-status';

import {store} from 'redux/store';
import {setAuthStatus} from 'redux/slices/auth-process/auth-process';

const BASE_URL = process.env.REACT_APP_SERVER_ENTRYPOINT;
const REQUEST_TIMEOUT = 300000;

const StatusCodeMapping = {
  [StatusCodes.FORBIDDEN]: true,
  [StatusCodes.BAD_REQUEST]: true,
  [StatusCodes.UNAUTHORIZED]: true,
  [StatusCodes.NOT_FOUND]: true,
  [StatusCodes.INTERNAL_SERVER_ERROR]: true
};

const shouldDisplayError = (response) => !!StatusCodeMapping[response.status];

export const createAPI = () => {
  const api = axios.create({
    baseURL: BASE_URL,
    timeout: REQUEST_TIMEOUT
  });

  api.interceptors.request.use(
    (config) => {
      const token = getToken();

      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      return config;
    },
  );

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      // Вывод общего сообщения отключён, т.к. включена обработка ошибок
      // в каждом конкретном асинхронном экшене (или в процессе включения) 
      // if (error.response && error.response.status === 401) {
      //   toast.warn('Ошибка авторизации', {
      //     position: 'top-right',
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: 'colored'
      //   });
      // }

      if (error.response.status === StatusCodes.UNAUTHORIZED) {
        removeToken();
        store.dispatch(setAuthStatus(AuthStatus.NO_AUTH));
      }

      throw error;
    }
  );
  
  return api;
};
