import {useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {fetchSuppliesAsyncAction} from 'redux/slices/supplies/supplies-api-actions';
import {getSupplies} from 'redux/slices/supplies/selectors';
import {getChangedInBusinessRuDocuments} from 'redux/slices/documents/selectors';
import {fetchChangedInBusinessRuDocumentsAsyncAction} from 'redux/slices/documents/documents-api-actions';
import {getSettings} from 'redux/slices/settings/selectors';

import SuppliesTable from 'components/archive/SuppliesTable';
import Preloader from 'components/preloader/SpinPlaceholderComponent';

import {AppContext} from 'providers/AppContextProvider';
import {getUser} from 'helpers/storage';

const Index = () => {
	const dispatch = useDispatch();
	const {alert, setCrumbs} = useContext(AppContext);
	const location = useLocation();

	const LOCATION_REG_EXP = /archive/;
	const pathName = location.pathname;
	const isInArchive = LOCATION_REG_EXP.test(pathName);

	const userLevel = getUser().accessLevel;

	// Активность прелоадера
	const [isPreloaderActive, setIsPreloaderActive] = useState(false);

	const supplies = useSelector(getSupplies);
	const changedInBusinessRuDocuments = useSelector(getChangedInBusinessRuDocuments);
	const settings = useSelector(getSettings);
	const businessRuDataCheckSettingStatus = settings.find((setting) => setting.name === 'business_ru_data_check')
		? settings.find((setting) => setting.name === 'business_ru_data_check').status
		: 0;
	const transformedSupplies = supplies
		.map((supply) => {
			const changedSupply = changedInBusinessRuDocuments.supplies
				.find((document) => Number(document.system_doc.id) === Number(supply.supply_id));

			if (!changedSupply) {
				return supply;
			}

			const supplyChanged = Object.values(changedSupply.changes).some((item) => !!item) ||
				!!changedSupply.goods.changedGoods.length ||
				!!changedSupply.goods.addedGoods.length ||
				(!!changedSupply.goods.deletedGoods.length && !!changedSupply.bru_docs[0]) ||
				(!!changedSupply.bru_docs[0] && !changedSupply.bru_docs[0].deleted && !!changedSupply.bru_docs[0].held);
			const supplyDeleted = !changedSupply.bru_docs[0] || (changedSupply.bru_docs[0] && changedSupply.bru_docs[0].deleted);
			const supplyDenied = changedSupply.bru_docs[0] && !changedSupply.bru_docs[0].deleted && !changedSupply.bru_docs[0].held;

			return {
				...supply,
				supplyChanged,
				supplyDeleted,
				supplyDenied
			};
		});

	const requestForChangedInBusinessRuDocuments = async () => {
		setIsPreloaderActive(true);
		await dispatch(fetchChangedInBusinessRuDocumentsAsyncAction());
		setIsPreloaderActive(false);
	};

	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
			{name: 'Архивы', url: '/archive'},
			{name: 'Поступления', url: ''}
		]);
	}, []);

	// Запрос данных с сервера для построения списка Поступлений
	useEffect(() => {
		dispatch(fetchSuppliesAsyncAction(isInArchive));

		if (!changedInBusinessRuDocuments.supplies.length && businessRuDataCheckSettingStatus === 1) {
			requestForChangedInBusinessRuDocuments();
		}
	}, [businessRuDataCheckSettingStatus]);

	return (
		<>
			<Preloader isActive={isPreloaderActive}/>

			<div className="cabinetMain">
				<SuppliesTable Supplies={transformedSupplies} userLevel={userLevel} alert={alert}/>
			</div>
		</>
	);
};

export default Index;
