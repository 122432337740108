import {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import RemainsFloatPanel from 'components/remains-float-panel/remains-float-panel';
import Preloader from 'components/preloader/SpinPlaceholderComponent';
import RemainsCheckValuesModal from 'components/remains-check-values-modal/remains-check-values-modal';

import {resetRemainsDocument} from 'redux/slices/remains/remains';
import {
  fetchAddressesAsyncAction,
  fetchRemainsDocumentDetailsAsyncAction
} from 'redux/slices/remains/remains-api-actions';
import {getRemainsDocument} from 'redux/slices/remains/selectors';
import {fetchGoodsAsyncAction} from 'redux/slices/good/good-api-actions';

import {AppContext} from 'providers/AppContextProvider';

import {AppRoute} from 'constants/routes';

import {getHumanFormattedDate} from 'helpers/utils';

import styles from './styles.module.scss';

const RemainsDocumentDetails = () => {
  const dispatch = useDispatch();
  const {id} = useParams();

  const {setCrumbs} = useContext(AppContext);

  // Активность прелоадера
  const [isPreloaderActive, setIsPreloaderActive] = useState(false);

  // Модальное окно валидации таблиц
  const [checkValuesModalData, setCheckValuesModalData] = useState({});
  const [isCheckValuesModalActive, setCheckValuesModalActive] = useState(false);

  const remainsDocument = useSelector(getRemainsDocument);
  const lines = remainsDocument.lines;

  useEffect(() => {
    dispatch(fetchRemainsDocumentDetailsAsyncAction(id));
    dispatch(fetchGoodsAsyncAction());
    dispatch(fetchAddressesAsyncAction());
  }, []);

  useEffect(() => {
    setCrumbs([
      {name: 'Начальные остатки', url: AppRoute.Remains.LIST},
      {name: `Загрузка № ${id} от ${lines[0] ? getHumanFormattedDate(lines[0].documentDate) : ''}`, url: ''}
    ]);
  }, [lines.length]);

  useEffect(() => {
    return () => {
      dispatch(resetRemainsDocument());
    };
  }, []);

  return (
    <>
      <Preloader isActive={isPreloaderActive}/>

      <RemainsFloatPanel
        setIsPreloaderActive={setIsPreloaderActive}
        setCheckValuesModalData={setCheckValuesModalData}
        setCheckValuesModalActive={setCheckValuesModalActive}
      />

      <table className={`${styles.remainsTable} ${styles.top0}`}>
        <thead>
          <tr>
            <th colSpan={2}>{`Загрузка № ${id} от ${lines[0] ? getHumanFormattedDate(lines[0].documentDate) : ''}`}</th>
            <th colSpan={2}>Автор: {lines[0] ? lines[0].author : ''}</th>
            <th colSpan={2}>В том числе:</th>
          </tr>
          <tr>
            <th>№</th>
            <th>Наименование</th>
            <th>Артикул</th>
            <th>Всего:</th>
            <th>Кол-во</th>
            <th>Адрес</th>
          </tr>
        </thead>
        <tbody>
          {
            lines.map((line, lineIndex) => (
              line.positions.map((position, positionIndex) => {
                const totalPositionsAmount = line.positions.reduce((res, item) => res + Number(item.amount), 0);

                return (
                  <tr key={`${line.id}${position.amount}`}>
                    {
                      positionIndex === 0 && (
                        <>
                          <td className={styles.rowNumber} rowSpan={line.positions.length}>{lineIndex + 1}</td>
                          <td className={styles.plantName} rowSpan={line.positions.length}>{line.name}</td>
                          <td className={styles.article} rowSpan={line.positions.length}>{line.article}</td>
                          <td rowSpan={line.positions.length}>{totalPositionsAmount}</td>
                        </>
                      ) 
                    }
                    <td className={styles.right}>{position.amount}</td>
                    <td className={styles.right}>{position.address}</td>
                  </tr>
                );
              })
            ))
          }
        </tbody>
      </table>

      <RemainsCheckValuesModal
        data={checkValuesModalData}
        isActive={isCheckValuesModalActive}
        setIsActive={setCheckValuesModalActive}
      />
    </>
  );
};

export default RemainsDocumentDetails;
