import {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import cl from 'styles/components/cabinet/UsersTable.module.scss';

import Indicator from 'components/Indicator';
import DocumentsListButtonsBlock from 'components/documents-list-buttons-block/documents-list-buttons-block';
import ChangedInBusinessRuDocumentsModal from 'components/changed-documents-modal/changed-documents-modal';
import Preloader from 'components/preloader/SpinPlaceholderComponent';

import {fetchRealizations} from 'redux/slices/docSlice';
import {getChangedInBusinessRuDocuments} from 'redux/slices/documents/selectors';
import {fetchChangedInBusinessRuDocumentsAsyncAction} from 'redux/slices/documents/documents-api-actions';
import {getSettings} from 'redux/slices/settings/selectors';

import {AppContext} from 'providers/AppContextProvider';

import {DocumentType} from 'constants/document-type';

const Index = () => {
	const dispatch = useDispatch();
  const navigate = useNavigate();
	const location = useLocation();

	const LOCATION_REG_EXP = /archive/;
  const pathName = location.pathname;
  const isInArchive = LOCATION_REG_EXP.test(pathName);

  const {setCrumbs} = useContext(AppContext);

  const {realizations} = useSelector((state) => state.doc);
	const changedInBusinessRuDocuments = useSelector(getChangedInBusinessRuDocuments);
	const settings = useSelector(getSettings);
	const businessRuDataCheckSettingStatus = settings.find((setting) => setting.name === 'business_ru_data_check')
		? settings.find((setting) => setting.name === 'business_ru_data_check').status
		: 0;

	// Активность прелоадера
  const [isPreloaderActive, setIsPreloaderActive] = useState(false);

	// Модальное окно с изменёнными в Бизнес.ру документами
	const [changedInBRuDocumentsModalActive, setChangedInBRuDocumentsModalActive] = useState(false);

	const requestForChangedInBusinessRuDocuments = async () => {
    setIsPreloaderActive(true);
    await dispatch(fetchChangedInBusinessRuDocumentsAsyncAction());
    setIsPreloaderActive(false);
  };

  // Запрос изменённых в Бизнес.ру документов
  useEffect(() => {
    if (businessRuDataCheckSettingStatus === 1) {
			requestForChangedInBusinessRuDocuments();
		}
  }, [isInArchive, businessRuDataCheckSettingStatus]);

	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
			{name: 'Отгрузки', url: ''}
		]);
	}, []);

  useEffect(() => {
    dispatch(fetchRealizations());
  }, []);

	// Активация модального окна с отменёнными в Бизнес.ру документами
	useEffect(() => {
		if (Object.values(changedInBusinessRuDocuments).some((item) => !!item.length)) {
			setChangedInBRuDocumentsModalActive(true);
		}
	}, [
    isInArchive,
    changedInBusinessRuDocuments.inventories.length,
    changedInBusinessRuDocuments.supplies.length,
    changedInBusinessRuDocuments.realizations.length,
    changedInBusinessRuDocuments.postings.length,
    changedInBusinessRuDocuments.charges.length,
    changedInBusinessRuDocuments.shiftings.length,
    changedInBusinessRuDocuments.factories.length
  ]);
	
	return (
		<>
			<Preloader isActive={isPreloaderActive}/>

			<div style={{padding: "16px"}}>
				&nbsp;
				<Indicator type="danger"/> - Отгрузка создана &nbsp;
				<Indicator type="success"/> - Проведено в Системе
			</div>

			{
        !isInArchive && (
          <DocumentsListButtonsBlock documentType={DocumentType.REALIZATION}/>
        )
      }
			
			<table className={cl.usersTable}>
				<thead>
					<tr>
						<th>Системный №</th>
						<th>Заказ</th>
						<th>Клиент</th>
						<th>Дата в Системе</th>
						<th/>
						<th/>
					</tr>
				</thead>
				<tbody>
					{
						realizations.data.map((realization, index) =>
							<tr key={index.toString()}>
								<td>
									<span>{realization.document_number}</span>
								</td>
								<td>
									<span>{realization.number}</span>
								</td>
								<td>
									<span>{realization.customer}</span>
								</td>
								<td>
									<span>{realization.document_date}</span>
								</td>
								<td>
									<span
										className={`${cl.icon} material-icons`}
										title="Посмотреть"
										onClick={() => navigate(`/realizations/edit/${realization.id}`)}
									>
										visibility
									</span>
								</td>
								<td>
									<Indicator type={realization.provided === 1 ? 'success' : 'danger'}/>
								</td>
							</tr>
						)
					}
				</tbody>
			</table>

			{
        !isInArchive && (
          <ChangedInBusinessRuDocumentsModal
            changedInBusinessRuDocuments={changedInBusinessRuDocuments}
            changedInBRuDocumentsModalActive={changedInBRuDocumentsModalActive}
            setChangedInBRuDocumentsModalActive={setChangedInBRuDocumentsModalActive}
          />
        )
      }
		</>
	)
}

export default Index;
