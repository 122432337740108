import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import Modal from 'components/Modal';

import {downloadModalTableAsyncAction} from 'redux/slices/documents/documents-api-actions';

import {
  DocumentListType,
  ListTypeDocTypeMap
} from './constants';
import {DocumentTypeNameMap} from 'constants/document-type';

import {getDateFromMySQLDate} from 'helpers/utils';

import styles from './styles.module.scss';

const ChangedInBusinessRuDocumentsModal = ({
  changedInBusinessRuDocuments,
  changedInBRuDocumentsModalActive,
  setChangedInBRuDocumentsModalActive
}) => {
  const dispatch = useDispatch();
  const {id} = useParams();

  let deniedDocsRowNumber = 0;
  let changedDocsRowNumber = 0;

  const handlePrintButtonClick = async () => {
    const downloadResponse = await dispatch(downloadModalTableAsyncAction(changedInBusinessRuDocuments));

    window.open(
      `${process.env.REACT_APP_SERVER_URL}/FILES/${downloadResponse.payload.filename}`,
      "_blank"
    );
  };

  return (
    <Modal
      isActive={changedInBRuDocumentsModalActive}
      setIsActive={setChangedInBRuDocumentsModalActive}
    >
      <div className={styles.wrapper}>
        <p className={styles.title}>
          В Системе найдены проведённые документы,<br/> которые отменены, удалены или изменены в Бизнес.ру.&nbsp;
          <span
            className="roundIcon material-icons"
            onClick={handlePrintButtonClick}
            title="Скачать таблицу"
          >
            print
          </span>
        </p>

        <div className={styles.tableWrapper}>
          {
            (
              Object.keys(changedInBusinessRuDocuments).some((typeName) => (
                changedInBusinessRuDocuments[typeName].some((typeNameItem) => (
                  !typeNameItem.bru_docs[0] ||
                  typeNameItem.bru_docs[0].deleted ||
                  !typeNameItem.bru_docs[0].held
                ))
              ))
            ) && (
              <>
                <p>Не проведённые/удалённые документы</p>
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th className={styles.cell}>№п/п</th>
                      <th className={styles.cell}>Тип документа</th>
                      <th className={styles.cell}>Номер документа</th>
                      <th className={styles.cell}>Дата документа</th>
                      <th className={styles.cell}>В Бизнес.ру</th>
                      <th/>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      Object.keys(changedInBusinessRuDocuments)
                        .filter((documentType) => (
                          changedInBusinessRuDocuments[documentType].length &&
                          changedInBusinessRuDocuments[documentType].some((document) => (
                            !document.bru_docs[0] ||
                            document.bru_docs[0].deleted ||
                            !document.bru_docs[0].held
                          ))
                        ))
                        .map((documentType) => {
                          return (
                            <>
                              {
                                changedInBusinessRuDocuments[documentType]
                                  .filter((document) => (
                                    !document.bru_docs[0] ||
                                    document.bru_docs[0].deleted ||
                                    !document.bru_docs[0].held
                                  ))
                                  .map((document) => {
                                    deniedDocsRowNumber++;

                                    return (
                                      <tr key={document["system_doc"]["id"]}>
                                        <td className={styles.cell}>
                                          <span>{deniedDocsRowNumber}</span>
                                        </td>
                                        <td className={styles.cell}>
                                          <span>{DocumentTypeNameMap[ListTypeDocTypeMap[documentType]]}</span>
                                        </td>
                                        <td className={styles.cell}>
                                          {
                                            documentType === DocumentListType.SUPPLY
                                              ? document["system_doc"]["supply_name"]
                                              : document["system_doc"]["document_number"]
                                          }
                                        </td>
                                        <td className={styles.cell}>
                                          {
                                            documentType === DocumentListType.SUPPLY ||
                                            documentType === DocumentListType.INVENTORY
                                              ? getDateFromMySQLDate(document["system_doc"]["date"]).split(' ')[0]
                                              : getDateFromMySQLDate(document["system_doc"]["document_date"]).split(' ')[0]
                                          }
                                        </td>
                                        <td className={styles.cell}>
                                          {
                                            !document.bru_docs[0] && (
                                              <span>{'Удалён\u00A0полностью'}</span>
                                            )
                                          }
                                          {
                                            document.bru_docs[0] && document.bru_docs[0].deleted && (
                                              <span>{'Удалён\u00A0в\u00A0корзину'}</span>
                                            )
                                          }
                                          {
                                            (
                                              document.bru_docs[0] &&
                                              !document.bru_docs[0].deleted &&
                                              !document.bru_docs[0].held
                                            ) && (
                                              <span>{'Не\u00A0проведён'}</span>
                                            )
                                          }
                                        </td>
                                      </tr>
                                    );
                                  })
                              }
                            </>
                          );
                        })
                    }
                  </tbody>
                </table>
              </>
            )
          }
          {
            (
              Object.keys(changedInBusinessRuDocuments).some((typeName) => (
                changedInBusinessRuDocuments[typeName].some((document) => (
                    Object.values(document.changes).some((item) => !!item) ||
                    !!document.goods.changedGoods.length ||
                    !!document.goods.addedGoods.length ||
                    (!!document.goods.deletedGoods.length && !!document.bru_docs[0]) ||
                    (!!document.bru_docs[0] && !document.bru_docs[0].deleted && !!document.bru_docs[0].held)
                  ))
              ))
            ) && (
              <>
                <p>Изменённые документы</p>
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th className={styles.cell}>№п/п</th>
                      <th className={styles.cell}>Тип документа</th>
                      <th className={styles.cell}>Номер документа</th>
                      <th className={styles.cell}>Дата документа</th>
                      <th className={styles.cell}>Что изменено</th>
                      <th/>
                    </tr>
                  </thead>
                  <tbody className={styles.changedDocuments}>
                    {
                      Object.keys(changedInBusinessRuDocuments)
                        .filter((typeName) => (
                          changedInBusinessRuDocuments[typeName].length &&
                          changedInBusinessRuDocuments[typeName].some((document) => (
                            Object.values(document.changes).some((item) => !!item) ||
                            !!document.goods.changedGoods.length ||
                            !!document.goods.addedGoods.length ||
                            (!!document.goods.deletedGoods.length && !!document.bru_docs[0]) ||
                            (!!document.bru_docs[0] && !document.bru_docs[0].deleted && !!document.bru_docs[0].held)
                          ))
                        ))
                        .map((documentType) => {
                          return (
                            <>
                              {
                                changedInBusinessRuDocuments[documentType]
                                  .filter((document) => (
                                    document.bru_docs[0] && (
                                      Object.values(document.changes).some((item) => item) ||
                                      !!document.goods.changedGoods.length ||
                                      !!document.goods.addedGoods.length ||
                                      (!!document.goods.deletedGoods.length && !document.bru_docs[0].deleted)
                                    )
                                  ))
                                  .map((document) => {
                                    changedDocsRowNumber++;
                                    const documentNumber = documentType === DocumentListType.SUPPLY
                                      ? document["system_doc"]["supply_name"]
                                      : document["system_doc"]["document_number"];

                                    const statuses = [];

                                    if (!!document.goods.changedGoods.length) {
                                      statuses.push('Кол-во');
                                    }
                                    if (
                                      !!document.goods.deletedGoods.length &&
                                      document.bru_docs[0] &&
                                      !document.bru_docs[0].deleted
                                    ) {
                                      statuses.push('Товары\u00A0удалены');
                                    }
                                    if (!!document.goods.addedGoods.length) {
                                      statuses.push('Товары\u00A0добавлены');
                                    }
                                    if (document.changes.isNumberChanged) {
                                      statuses.push(`Номер\u00A0(${documentNumber}➝${(/тест/).test(document.bru_docs[0].number) ? document.bru_docs[0].number.replace('тест', '') : document.bru_docs[0].number})`);
                                    }
                                    if (document.changes.isDateChanged) {
                                      statuses.push('Дата');
                                    }
                                    if (document.changes.isStorageChanged) {
                                      statuses.push('Склад');
                                    }

                                    return (
                                      <tr key={document["system_doc"]["id"]}>
                                        <td className={styles.cell}>
                                          <span>{changedDocsRowNumber}</span>
                                        </td>
                                        <td className={styles.cell}>
                                          <span>{DocumentTypeNameMap[ListTypeDocTypeMap[documentType]]}</span>
                                        </td>
                                        <td className={styles.cell}>
                                          {documentNumber}
                                        </td>
                                        <td className={styles.cell}>
                                          {
                                            documentType === DocumentListType.SUPPLY ||
                                            documentType === DocumentListType.INVENTORY
                                              ? getDateFromMySQLDate(document["system_doc"]["date"]).split(' ')[0]
                                              : getDateFromMySQLDate(document["system_doc"]["document_date"]).split(' ')[0]
                                          }
                                        </td>
                                        <td className={styles.cell}>
                                          {statuses.join(', ')}
                                        </td>
                                      </tr>
                                    );
                                  })
                              }
                            </>
                          );
                        })
                    }
                  </tbody>
                </table>
              </>
            )
          }
        </div>

        {
          id && (
            <div className={styles.warning}>
              <b>⚠️ Проведение текущего документа заблокировано, так как в указанных документах есть товары из текущего документа. После ликвидации этих расхождений Системы с Бизнес.ру, проведение станет возможным.</b>
            </div>
          )
        }
      </div>
    </Modal>
  );
};

export default ChangedInBusinessRuDocumentsModal;
