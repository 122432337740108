import {useContext, useEffect, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import DocumentsListButtonsBlock from 'components/documents-list-buttons-block/documents-list-buttons-block';
// import Button from 'components/ButtonComponent';
import ChangedInBusinessRuDocumentsModal from 'components/changed-documents-modal/changed-documents-modal';
import Preloader from 'components/preloader/SpinPlaceholderComponent';

import {fetchSuppliesAsyncAction} from 'redux/slices/supplies/supplies-api-actions';
import {getSupplies} from 'redux/slices/supplies/selectors';
import {getChangedInBusinessRuDocuments} from 'redux/slices/documents/selectors';
import {fetchChangedInBusinessRuDocumentsAsyncAction} from 'redux/slices/documents/documents-api-actions';
import {getSettings} from 'redux/slices/settings/selectors';

import {AppContext} from 'providers/AppContextProvider';

import {AppRoute} from 'constants/routes';
import {DocumentType, DocumentTypeNameMap} from 'constants/document-type';

import cl from 'styles/components/cabinet/UsersTable.module.scss';

const Index = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {search} = useLocation();
	const location = useLocation();

	const LOCATION_REG_EXP = /archive/;
  const pathName = location.pathname;
  const isInArchive = LOCATION_REG_EXP.test(pathName);

	const {setCrumbs, setMenuCondition} = useContext(AppContext);

	const supplies = useSelector(getSupplies);
	const changedInBusinessRuDocuments = useSelector(getChangedInBusinessRuDocuments);
	const settings = useSelector(getSettings);
	const businessRuDataCheckSettingStatus = settings.find((setting) => setting.name === 'business_ru_data_check')
		? settings.find((setting) => setting.name === 'business_ru_data_check').status
		: 0;

	// Активность прелоадера
  const [isPreloaderActive, setIsPreloaderActive] = useState(false);

	// Модальное окно с изменёнными в Бизнес.ру документами
	const [changedInBRuDocumentsModalActive, setChangedInBRuDocumentsModalActive] = useState(false);

	const requestForChangedInBusinessRuDocuments = async () => {
    setIsPreloaderActive(true);
    await dispatch(fetchChangedInBusinessRuDocumentsAsyncAction());
    setIsPreloaderActive(false);
  };

  // Запрос изменённых в Бизнес.ру документов
  useEffect(() => {
    if (businessRuDataCheckSettingStatus === 1) {
			requestForChangedInBusinessRuDocuments();
		}
  }, [isInArchive, businessRuDataCheckSettingStatus]);
	
	// Сброс условия меню
	useEffect(() => {
		setMenuCondition({});
	}, [search]);
	
	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
			{name: DocumentTypeNameMap[DocumentType.SUPPLY], url: ''}
		]);
	}, []);

	// Запрос поступлений с сервера
	useEffect(() => {
		dispatch(fetchSuppliesAsyncAction(isInArchive));
	}, []);

	// Активация модального окна с отменёнными в Бизнес.ру документами
	useEffect(() => {
		if (Object.values(changedInBusinessRuDocuments).some((item) => !!item.length)) {
			setChangedInBRuDocumentsModalActive(true);
		}
	}, [
    isInArchive,
    changedInBusinessRuDocuments.inventories.length,
    changedInBusinessRuDocuments.supplies.length,
    changedInBusinessRuDocuments.realizations.length,
    changedInBusinessRuDocuments.postings.length,
    changedInBusinessRuDocuments.charges.length,
    changedInBusinessRuDocuments.shiftings.length,
    changedInBusinessRuDocuments.factories.length
  ]);

	return (
		<>
			<Preloader isActive={isPreloaderActive}/>

			{!supplies.length && <p>&nbsp; Новых поступлений нет</p>}

			{
        !isInArchive && (
          <DocumentsListButtonsBlock
						documentType={DocumentType.SUPPLY}
						withoutNewButton={true}
					/>
        )
      }

			<table className={cl.usersTable}>
				<thead>
					<tr>
						<th>№</th>
						<th>Тип</th>
						<th>Дата</th>
						<th>Склад</th>
						<th/>
					</tr>
				</thead>
				<tbody>
					{
						supplies.map((form, index) =>
							<tr key={index.toString()}>
								<td>
									<span>{form.document_number}</span>
								</td>
								<td>
									<span>Поступление</span>
								</td>
								<td>
									<span>{form.document_date.slice(0, -6)}</span>
								</td>
								<td>
									<span>{form.storage ? form.storage : 'НЕ ВЫБРАНО'}</span>
								</td>
								<td>
									<span
										className={cl.icon + ' material-icons'}
										title="Посмотреть"
										onClick={() => navigate(`${AppRoute.Document.SUPPLIES}/${form.supply_id}`)}>
										visibility
									</span>
								</td>
							</tr>
						)
					}
				</tbody>
			</table>

			{
        !isInArchive && (
          <ChangedInBusinessRuDocumentsModal
            changedInBusinessRuDocuments={changedInBusinessRuDocuments}
            changedInBRuDocumentsModalActive={changedInBRuDocumentsModalActive}
            setChangedInBRuDocumentsModalActive={setChangedInBRuDocumentsModalActive}
          />
        )
      }
		</>
	);
}

export default Index;
