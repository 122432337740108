
import {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import ArchiveInventoryTable from 'components/archive/archive-inventory-table';
import Preloader from 'components/preloader/SpinPlaceholderComponent';

import {fetchChangedInBusinessRuDocumentsAsyncAction} from 'redux/slices/documents/documents-api-actions';
import {getChangedInBusinessRuDocuments} from 'redux/slices/documents/selectors';
import {getSettings} from 'redux/slices/settings/selectors';

import {AppContext} from 'providers/AppContextProvider';

const Index = () => {
	const dispatch = useDispatch();

	const {setCrumbs} = useContext(AppContext);

	// Активность прелоадера
  const [isPreloaderActive, setIsPreloaderActive] = useState(false);

	const changedInBusinessRuDocuments = useSelector(getChangedInBusinessRuDocuments);
	const settings = useSelector(getSettings);
	const businessRuDataCheckSettingStatus = settings.find((setting) => setting.name === 'business_ru_data_check')
		? settings.find((setting) => setting.name === 'business_ru_data_check').status
		: 0;

	const requestForChangedInBusinessRuDocuments = async () => {
		setIsPreloaderActive(true);
		await dispatch(fetchChangedInBusinessRuDocumentsAsyncAction());
		setIsPreloaderActive(false);
	};

	// Запрос изменённых/отменённых/удалённых документов в Бизнес.ру
	useEffect(() => {
		if (!changedInBusinessRuDocuments.inventories.length && businessRuDataCheckSettingStatus === 1) {
			requestForChangedInBusinessRuDocuments();
		}
	}, [businessRuDataCheckSettingStatus]);

	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
			{name: 'Архивы', url: '/archive'},
			{name: 'Инвентаризация', url: ''}
		]);
	}, []);
	
	return (
		<>
			<div className='cabinetMain'>
				<Preloader isActive={isPreloaderActive}/>
				<ArchiveInventoryTable
					setIsPreloaderActive={setIsPreloaderActive}
				/>
			</div>
		</>
	);
};

export default Index;
