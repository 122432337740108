import {createAsyncThunk} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';

import {User} from 'constants/endpoints';
import {saveToken} from 'helpers/storage';

export const validateTokenAsyncAction = createAsyncThunk(
  'auth/validateToken',
  async (_args, {extra: api}) => {
    await api.get(User.CHECK_AUTH);
  }
);

export const signInAsyncAction = createAsyncThunk(
  'auth/getToken',
  async (credentials, {extra: api}) => {
    const {data} = await api.post(User.LOGIN, credentials)
      .then((res) => {
        saveToken(res.data.access_token);
        return res;
      })
      .catch((res) => {
        const isUnauthorized = res.response.status === 401;
        const noAuthMessage = isUnauthorized ? 'Введены некорректные данные' : res.response.statusText;

        toast.error(noAuthMessage, {
          position: 'bottom-right'
        });
      });

    return data;
  }
);

export const signUpAsyncAction = createAsyncThunk(
  'auth/register',
  async (registerData, {extra: api}) => {
    const {data} = await api.post(User.REGISTER, registerData);

    return data;
  }
);
