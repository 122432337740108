import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  loadingStatus: false
};

export const appData = createSlice({
  name: 'appData',
  initialState,
  reducers: {
    setLoadingStatus: (state, action) => {
      state.loadingStatus = action.payload;
    }
  }
});

export const {
  setLoadingStatus
} = appData.actions;
