import React, {
  useRef,
  useEffect,
  useState,
  useContext
} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import html2canvas from 'html2canvas';

import Preloader from 'components/preloader/SpinPlaceholderComponent';
import CommentModal from 'components/comment-modal/comment-modal';
import CheckValuesModal from 'components/check-values-modal/check-values-modal';
import Pagination from 'components/pagination/pagination';
import ChangedInBusinessRuDocumentsModal from 'components/changed-documents-modal/changed-documents-modal';
import InventoryTable from 'components/inventory-table/inventory-table';
import InventoryFloatPanel from 'components/inventory-float-panel/inventory-float-panel';
import InventoryFilter from 'components/inventory-filter/inventory-filter';

import {
  fetchAddresses,
  setInventoryDataAction
} from 'redux/slices/inventory/inventorySlice';
import {fetchGroups} from 'redux/slices/business/businessSlice';
import {fetchGoodsAsyncAction} from 'redux/slices/good/good-api-actions';
import {
  fetchInventoryAsyncAction,
  postInventoryScreenShotAsyncAction
} from 'redux/slices/inventory/inventory-api-actions';
import {getDocumentNumber, getIsCopyStatus, getProvidedStatus} from 'redux/slices/inventory/selectors';
import {getChangedInBusinessRuDocuments} from 'redux/slices/documents/selectors';

import {AppContext} from 'providers/AppContextProvider';

import style from './style.module.scss';
import {AppRoute} from 'constants/routes';

const InventoryDetails = () => {
  const dispatch = useDispatch();

  const {id} = useParams();
  const {pathname} = useLocation();
  const isInArchive = (/archive/).test(pathname);

  const {setCrumbs} = useContext(AppContext);

  const inventory = useSelector((state) => state.inventory).inventory;
  const inventoryItems = inventory.data;
  const isCopy = useSelector(getIsCopyStatus);
  const documentNumber = useSelector(getDocumentNumber);
  const provided = useSelector(getProvidedStatus);
  const addresses = useSelector((state) => state.inventory).addresses.data;
  const goods = useSelector((state) => state.good).goods;
  const groups = useSelector((state) => state.business).groups.data;
  const changedInBusinessRuDocuments = useSelector(getChangedInBusinessRuDocuments);

  const toScreenShot = useRef();

  // Пагинация
  const [paginationData, setPaginationData] = useState({
    activePage: 1,
    itemsPerPage: 15
  });
  const pagesCount = Math.ceil(inventoryItems.length / paginationData.itemsPerPage);
  const firstItemIndex = (paginationData.activePage - 1) * paginationData.itemsPerPage;
  const lastItemIndex = (paginationData.activePage - 1) * paginationData.itemsPerPage + paginationData.itemsPerPage;
  const displayedItems = inventoryItems.slice(firstItemIndex, lastItemIndex);

  // Активность прелоадера
  const [isPreloaderActive, setIsPreloaderActive] = useState(false);

  // Модальное окно с изменёнными в Бизнес.ру документами
	const [changedInBRuDocumentsModalActive, setChangedInBRuDocumentsModalActive] = useState(false);
  const [changedInBRuDocumentsModalData, setChangedInBRuDocumentsModalData] = useState({
    inventories: [],
    supplies: [],
    realizations: [],
    postings: [],
    charges: [],
    shiftings: [],
    factories: []
  });

  // Тип и порядок сортировки
  const [sortData, setSortData] = useState({
    type: 'name',
    order: 'ASC'
  });

  // Состояния фильтра
  const [isFilterOpened, setIsFilterOpened] = useState(false);
  const [dataBackup, setDataBackup] = useState([]);
  const [isFilterActive, setIsFilterActive] = useState(false);

  const [modalIsActive, setModalIsActive] = useState(false);
  const [checkValuesModalActive, setCheckValuesModalActive] = useState(false);
  const [checkValuesModalData, setCheckValuesModalData] = useState([]);

  // Метод для отправки скриншота (передаётся во FloatPanel и выполняется в момент сохранения данных в стейт)
  const createAndPostScreenShot = async () => {
    const canvas = await html2canvas(toScreenShot.current, {useCORS: true});
    const image = await canvas.toDataURL("image/png", 1.0);

    await dispatch(postInventoryScreenShotAsyncAction({
      id,
      body: {
        thumb: image
      }
    }));
  };

  // Обработчик селекта для выбора количества отображаемых строк на странице
  const handleItemsPerPageSelectChange = (evt) => {
    const value = Number(evt.currentTarget.value);
    const newPagesCount = Math.ceil(inventoryItems.length / value);

    if (paginationData.activePage > newPagesCount) {
      setPaginationData({
        activePage: newPagesCount,
        itemsPerPage: value
      });
    } else {
      setPaginationData((prevState) => ({
        ...prevState,
        itemsPerPage: value
      }));
    }
  };

  const handlePaginationButtonClick = (value) => {
    setPaginationData((prevState) => ({
      ...prevState,
      activePage: value
    }));
  };

  // Функция сортировки
  const handleSortElementClick = (name) => {
    setSortData((prevState) => ({
      type: name,
      order: prevState.type === name ? prevState.order === 'ASC' ? 'DESC' : 'ASC' : 'ASC'
    }));
  };

  // Установка товаров при изменении данных в sortData
  useEffect(() => {
    if (inventoryItems.length) {
      const sortedItems = structuredClone(inventoryItems).sort((a, b) => {
        const sortOrder = sortData.order === 'ASC'
          ? sortData.type === 'article'
              ? Number(a[sortData.type]) - Number(b[sortData.type])
              : a[sortData.type].localeCompare(b[sortData.type], 'ru', { sensitivity: 'base' })
          : sortData.type === 'article'
              ? Number(b[sortData.type]) - Number(a[sortData.type])
              : b[sortData.type].localeCompare(a[sortData.type], 'ru', { sensitivity: 'base' });

        return sortOrder;
      });

      dispatch(setInventoryDataAction(sortedItems));
    }
  }, [sortData.type, sortData.order]);

  // Запрос данных с сервера
  useEffect(() => {
    dispatch(fetchInventoryAsyncAction(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (inventory.provided === 0) {
      if (!addresses.length) {
        dispatch(fetchAddresses());
      }
      if (!groups.length) {
        dispatch(fetchGroups());
      }
      if (!goods.length) {
        dispatch(fetchGoodsAsyncAction());
      }
    }
  }, [inventory.provided]);

  // Запись хлебных крошек
  useEffect(() => {
    if (inventoryItems.length) {
      const crumbsSubString = isCopy ? ' после проведения в Системе' : ' до проведения в Системе';
      const crumbsString = `№ ${documentNumber} ${provided ? crumbsSubString : ''}`;
      const crumbs = isInArchive ? [
        {name: 'Архивы', url: '/archive'},
        {name: 'Инвентаризация', url: AppRoute.Archive.INVENTORY},
        {
          name: crumbsString,
          url: ''
        }
      ] : [
        {name: 'Инвентаризация', url: AppRoute.Inventories.LIST},
        {
          name: crumbsString,
          url: ''
        }
      ];

      setCrumbs(crumbs);
    }
  }, [inventoryItems]);

  return (
    <>
      <Preloader isActive={isPreloaderActive}/>

      <InventoryFloatPanel
        setIsPreloaderActive={setIsPreloaderActive}
        setModalIsActive={setModalIsActive}
        setCheckValuesModalActive={setCheckValuesModalActive}
        setCheckValuesModalData={setCheckValuesModalData}
        setIsFilterOpened={setIsFilterOpened}
        createAndPostScreenShot={createAndPostScreenShot}
        changedInBusinessRuDocuments={changedInBusinessRuDocuments}
        setChangedInBRuDocumentsModalActive={setChangedInBRuDocumentsModalActive}
        setChangedInBRuDocumentsModalData={setChangedInBRuDocumentsModalData}
      />

      <div className="stickyContainer" ref={toScreenShot}>
        <div className={style.wrapper}>
          <InventoryTable
            paginationData={paginationData}
            setPaginationData={setPaginationData}
            displayedItems={displayedItems}
            firstItemIndex={firstItemIndex}
            handleSortElementClick={handleSortElementClick}
            sortData={sortData}
          />
          {
            pagesCount > 1 && (
              <select
                className={style.select}
                value={paginationData.itemsPerPage}
                onChange={handleItemsPerPageSelectChange}
              >
                <option value={15}>15</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            )
          }
          <Pagination
            pagesCount={pagesCount}
            activePage={paginationData.activePage}
            handleClick={handlePaginationButtonClick}
          />
        </div>
        <InventoryFilter
          isOpen={isFilterOpened}
          data={inventoryItems}
          setData={(filteredItems) => dispatch(setInventoryDataAction(filteredItems))}
          setIsFilterActive={setIsFilterActive}
          sortFn={handleSortElementClick}
          onlyOne=''
          dataBackup={dataBackup}
          setDataBackup={setDataBackup}
          fieldList={addresses}
        />
      </div>

      <CommentModal
        modalIsActive={modalIsActive}
        setModalIsActive={setModalIsActive}
      />
      <CheckValuesModal
        data={checkValuesModalData}
        isActive={checkValuesModalActive}
        setIsActive={setCheckValuesModalActive}
      />

      {
        !isInArchive && (
          <ChangedInBusinessRuDocumentsModal
            changedInBusinessRuDocuments={changedInBRuDocumentsModalData}
            changedInBRuDocumentsModalActive={changedInBRuDocumentsModalActive}
            setChangedInBRuDocumentsModalActive={setChangedInBRuDocumentsModalActive}
          />
        )
      }
    </>
  );
};

export default InventoryDetails;
