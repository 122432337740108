import {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import clsx from 'clsx';

import Preloader from 'components/preloader/SpinPlaceholderComponent';
// import ListLegend from 'components/list-legend/list-legend';
import OffersListItem from 'components/offers-list-item/offers-list-item';
import DocumentsListButtonsBlock from 'components/documents-list-buttons-block/documents-list-buttons-block';

import {fetchOffersAsyncAction} from 'redux/slices/offers/offers-api-actions';
import {getOffersList} from 'redux/slices/offers/selectors';

import {AppRoute} from 'constants/routes';
import {DocumentType} from 'constants/document-type';

import {AppContext} from 'providers/AppContextProvider';

import styles from './styles.module.scss';

const OffersList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const ARCHIVE_LOCATION_REG_EXP = /archive/;
  const pathName = location.pathname;
  const isInArchive = ARCHIVE_LOCATION_REG_EXP.test(pathName);

  const {setCrumbs} = useContext(AppContext);

  // Активность прелоадера
  const [isPreloaderActive, setIsPreloaderActive] = useState(false);

  const offersList = useSelector(getOffersList);

  const requestForOffersList = async () => {
    setIsPreloaderActive(true);
    await dispatch(fetchOffersAsyncAction(isInArchive));
    setIsPreloaderActive(false);
  };

  useEffect(() => {
    requestForOffersList();
  }, [isInArchive]);

  useEffect(() => {
    const crumbs = isInArchive ? [
      {name: 'Архив', url: AppRoute.Archive.ALL},
      {name: 'Коммерческое предложение', url: AppRoute.Archive.OFFERS}
    ] : [
      {name: 'Коммерческое предложение', url: AppRoute.Offers.LIST}
    ];

    setCrumbs(crumbs);
  }, [isInArchive]);

  return (
    <>
      <Preloader isActive={isPreloaderActive}/>

      {/* <ListLegend
        legendData={[
          {type: 'danger', text: 'Коммерческое предложение создано'},
          {type: 'success', text: 'Коммерческое предложение проведено'}
        ]}
      /> */}

      {
        !isInArchive && (
          <DocumentsListButtonsBlock documentType={DocumentType.OFFER}/>
        )
      }

      <table className={styles.table}>
        <thead>
          <tr>
            <th>Номер в Системе</th>
            <th>Дата в Системе</th>
            <th>Заказчик</th>
            <th>Номер заказа</th>
            <th/>
            <th/>
          </tr>
        </thead>
        <tbody>
          {
            offersList.map((offer, index) => (
              <OffersListItem
                key={index}
                offer={offer}
                setIsPreloaderActive={setIsPreloaderActive}
              />
            ))
          }
        </tbody>
      </table>
    </>
  );
};

export default OffersList;
