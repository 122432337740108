
import {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import RealizationsTable from 'components/archive/RealizationsTable';
import Preloader from 'components/preloader/SpinPlaceholderComponent';

import {getRealizationsList} from 'redux/slices/realizations/selectors';
import {fetchArchiveRealizationsListAsyncAction} from 'redux/slices/realizations/realizations-api-actions';
import {getChangedInBusinessRuDocuments} from 'redux/slices/documents/selectors';
import {fetchChangedInBusinessRuDocumentsAsyncAction} from 'redux/slices/documents/documents-api-actions';
import {getSettings} from 'redux/slices/settings/selectors';

import {AppContext} from 'providers/AppContextProvider';
import {getUser} from 'helpers/storage';

const Index = () => {
	const dispatch = useDispatch();

	const userLevel = getUser().accessLevel;

	const {alert, setCrumbs} = useContext(AppContext);

	// Активность прелоадера
	const [isPreloaderActive, setIsPreloaderActive] = useState(false);

	const realizations = useSelector(getRealizationsList);
	const changedInBusinessRuDocuments = useSelector(getChangedInBusinessRuDocuments);
	const settings = useSelector(getSettings);
	const businessRuDataCheckSettingStatus = settings.find((setting) => setting.name === 'business_ru_data_check')
		? settings.find((setting) => setting.name === 'business_ru_data_check').status
		: 0;
	const transformedRealizations = realizations
		.map((realization) => {
			const changedRealization = changedInBusinessRuDocuments.realizations
				.find((document) => Number(document.system_doc.id) === Number(realization.id));

			if (!changedRealization) {
				return realization;
			}

			const realizationChanged = Object.values(changedRealization.changes).some((item) => !!item) ||
				!!changedRealization.goods.changedGoods.length ||
				!!changedRealization.goods.addedGoods.length ||
				(!!changedRealization.goods.deletedGoods.length && !!changedRealization.bru_docs[0]) ||
				(!!changedRealization.bru_docs[0] && !changedRealization.bru_docs[0].deleted && !!changedRealization.bru_docs[0].held);
			const realizationDeleted = !changedRealization.bru_docs[0] || (changedRealization.bru_docs[0] && changedRealization.bru_docs[0].deleted);
			const realizationDenied = changedRealization.bru_docs[0] && !changedRealization.bru_docs[0].deleted && !changedRealization.bru_docs[0].held;

			return {
				...realization,
				realizationChanged,
				realizationDeleted,
				realizationDenied
			};
		});

	const requestForChangedInBusinessRuDocuments = async () => {
		setIsPreloaderActive(true);
		await dispatch(fetchChangedInBusinessRuDocumentsAsyncAction());
		setIsPreloaderActive(false);
	};
	
	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
			{name: "Архивы", url: "/archive"},
			{name: "Отгрузка", url: ""}
		]);
	}, []);

	// Запрос данных с сервера
	useEffect(() => {
		dispatch(fetchArchiveRealizationsListAsyncAction());

		if (!changedInBusinessRuDocuments.realizations.length && businessRuDataCheckSettingStatus === 1) {
			requestForChangedInBusinessRuDocuments();
		}
	}, [businessRuDataCheckSettingStatus]);
	
	return (
		<>
			<Preloader isActive={isPreloaderActive}/>

			<div className="cabinetMain">
				<RealizationsTable
					Realizations={transformedRealizations}
					alert={alert}
					userLevel={userLevel}
				/>
			</div>
		</>
	);
};

export default Index;
