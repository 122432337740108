import {useSelector, useDispatch} from 'react-redux';

import {setInventoryDataAction, setEmployeeAction} from 'redux/slices/inventory/inventorySlice';

import {getHumanFormattedDate} from 'helpers/utils';

import cl from 'styles/pages/[fieldId].module.scss';
import colors from 'styles/pages/[boardId].module.scss';

const TableHeader = ({
  totals,
  isActiveTotalSpreadButton,
  setIsActiveTotalSpreadButton,
  handleSortElementClick,
  sortData
}) => {
  const dispatch = useDispatch();

  const inventory = useSelector((state) => state.inventory).inventory;
  const inventoryItems = inventory.data;
  const date = inventory.date;
  const employeeInitialValue = inventoryItems.length ? inventory.employee : '';

  // Расчет колонки "Всего отклон."" по кнопке "РАССЧИТАТЬ"
  const estimateTotalSpread = () => {
    const estimatedItems = inventoryItems.map((item) => {
      const itemTotalSpread = parseInt(item.total_fact) - parseInt(item.total_uchet);
      return {
        ...item,
        total_spread: itemTotalSpread
      };
    });
    dispatch(setInventoryDataAction(estimatedItems));
    setIsActiveTotalSpreadButton(false);
  };

  // debounced setting into the state
  // const setInventoryDataActionDebounced = debounce((arg) => dispatch(setInventoryDataAction(arg)), EMPLOYEE_SETTING_DELAY);
  // const setEmployeeActionDebounced = debounce((arg) => dispatch(setEmployeeAction(arg)), EMPLOYEE_SETTING_DELAY);

  // Обработчик ввода имени бригадира
  const handleEmployeeInputChange = (evt) => {
    const employeeInputValue = evt.currentTarget.value;
    const updatedItems = inventoryItems.map((item) => ({
      ...item,
      employee: employeeInputValue
    }));

    dispatch(setInventoryDataAction(updatedItems));
    dispatch(setEmployeeAction(employeeInputValue))
  };

  return (
    <thead className={cl.top0 + " theadBordered thead-dark"}>
      <tr>
        <th colSpan="7">
          Инвентаризация по складу: {inventoryItems.length > 0 && inventoryItems[0].storage}
        </th>
        <th colSpan="5">
          № {inventoryItems.length > 0 && inventoryItems[0].document_number} от{" "}
          {inventoryItems.length > 0 && getHumanFormattedDate(date)}
        </th>
        <th colSpan="3">
          {inventoryItems.length > 0 && inventoryItems[0].author && (
            <>
              Автор:&nbsp;
              {inventoryItems[0].author}
              <br />
            </>
          )}
          Бригадир:&nbsp;
          <input
            type="text"
            name="employee"
            value={employeeInitialValue}
            onChange={handleEmployeeInputChange}
            placeholder="Бригадир"
            autoComplete="off"
          />
        </th>
        {inventoryItems.length > 0 && inventoryItems[0].provided === 0 && (
          <th className={cl.borderNone} rowSpan="4" colSpan="2" />
        )}
      </tr>
      <tr>
        <th rowSpan="3">№</th>
        <th
          rowSpan="3"
          className={sortData.type === "name" ? "sort sorted" : "sort"}
          onClick={() => handleSortElementClick('name')}
        >
          <span>Наименование</span>
        </th>
        <th
          rowSpan="3"
          className={sortData.type === "article" ? "sort sorted" : "sort"}
          onClick={() => handleSortElementClick('article')}
        >
          <span>Арт.</span>
        </th>
        <th rowSpan="3">Группа</th>
        <th colSpan="3">По базе</th>
        <th colSpan="8" className={colors.b5}>
          По системе учета
        </th>
      </tr>
      <tr>
        <th rowSpan="2">Учет</th>
        <th rowSpan="2">Факт</th>
        <th rowSpan="2">Откл.</th>
        <th rowSpan="2" className={colors.b7}>
          Всего учет {totals.uchet}
        </th>
        <th colSpan="2" className={colors.b7}>
          в том числе
        </th>
        <th rowSpan="2" className={colors.b5}>
          Всего факт {totals.fact}
        </th>
        <th colSpan="3" className={colors.b5}>
          в том числе
        </th>
        <th
          rowSpan="2"
          className={colors.b5}
        >
          Всего<br/>отклон.
          <br/>
          {inventoryItems.length > 0 &&
            inventory.provided === 0 &&
            isActiveTotalSpreadButton && (
              <button
                style={{
                  backgroundColor: '#526D4F',
                  borderStyle: 'none',
                  borderRadius: '5px',
                  padding: '3px 5px',
                  textTransform: 'uppercase',
                  fontSize: '10px',
                  fontWeight: '700',
                  color: 'white'
                }}
                onClick={estimateTotalSpread}
              >
                Рассчёт
              </button>
            )}
        </th>
      </tr>
      <tr>
        <th className={colors.b7}>Кол-во</th>
        <th className={colors.b7}>Адрес</th>
        <th className={colors.b5}>Кол-во</th>
        <th className={colors.b5}>Адрес</th>
        <th className={colors.b5}>
          {inventoryItems.length > 0 && inventoryItems[0].is_copy > 0
            ? "Было отклон."
            : "Отклон."}
        </th>
      </tr>
    </thead>
  );
};

export default TableHeader;
