import {useNavigate} from 'react-router-dom';
import clsx from 'clsx';

import Indicator from 'components/Indicator';

import {AppRoute} from 'constants/routes';

import {getHumanFormattedDate} from 'helpers/utils';

import styles from './styles.module.scss';

const RemainsListItem = ({remainsItem}) => {
  const navigate = useNavigate();

  const provided = Number(remainsItem.provided);

  const handleShowDocumentDetailsButtonClick = () => {
    navigate(`${AppRoute.Remains.LIST}/${remainsItem.id}`);
  };

  return (
    <tr>
      <td>Начальные остатки</td>
      <td>{remainsItem.id}</td>
      <td>{getHumanFormattedDate(remainsItem.date)}</td>
      <td>{remainsItem.author}</td>
      <td>
        <button
          className={clsx(styles.button, 'material-icons')}
          onClick={handleShowDocumentDetailsButtonClick}
        >
          <span className={styles.icon}>
            visibility
          </span>
        </button>
      </td>
      <td>
        <Indicator type={provided === 1 ? 'success' : 'danger'}/>
      </td>
    </tr>
  );
};

export default RemainsListItem;
