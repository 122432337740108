import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import clsx from 'clsx';

import {
  getExpirationDate,
  getOrderReadiness,
  getPaymentSchedule,
  getPriceCondition
} from 'redux/slices/offers/selectors';
import {
  setExpirationDate,
  setOrderReadiness,
  setPaymentSchedule,
  setPriceCondition
} from 'redux/slices/offers/offers';

import {getHumanFormattedDate} from 'helpers/utils';

import styles from './styles.module.scss';

const DeliveryTerms = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const ARCHIVE_LOCATION_REG_EXP = /archive/;
  const pathName = location.pathname;
  const isInArchive = ARCHIVE_LOCATION_REG_EXP.test(pathName);

  const orderReadiness = useSelector(getOrderReadiness);
  const priceCondition = useSelector(getPriceCondition);
  const paymentSchedule = useSelector(getPaymentSchedule);
  const expirationDate = useSelector(getExpirationDate);

  const handleOrderReadinessInputChange = (evt) => {
    const inputValue = evt.currentTarget.value;

    dispatch(setOrderReadiness(inputValue));
  };

  const handlePriceConditionInputChange = (evt) => {
    const inputValue = evt.currentTarget.value;

    dispatch(setPriceCondition(inputValue));
  };

  const handlePaymentScheduleInputChange = (index, value) => {
    const updatedPaymentSchedule = structuredClone(paymentSchedule);

    updatedPaymentSchedule[index] = value;

    dispatch(setPaymentSchedule(updatedPaymentSchedule));
  };

  const handleExpirationDateInputChange = (evt) => {
    const inputValue = evt.currentTarget.value;

    dispatch(setExpirationDate(inputValue));
  };

  const handleAddConditionButtonClick = () => {
    const updatedPaymentSchedule = structuredClone(paymentSchedule);

    updatedPaymentSchedule.push('');

    dispatch(setPaymentSchedule(updatedPaymentSchedule));
  };

  const handleDeleteConditionButtonClick = (index) => {
    const updatedPaymentSchedule = structuredClone(paymentSchedule);

    updatedPaymentSchedule.splice(index, 1);

    dispatch(setPaymentSchedule(updatedPaymentSchedule));
  };

  return (
    <div className={styles.wrapper}>
      <h3>Условия поставки:</h3>
      <div className={clsx(styles.orderReadiness, styles.conditionBlock)}>
        <p className={styles.title}>Срок готовности материала к отгрузке:</p>
        {
          isInArchive ? (
            <span>{orderReadiness}</span>
          ) : (
            <input
              type='text'
              name='order-readiness'
              placeholder='Срок готовности'
              value={orderReadiness}
              onChange={handleOrderReadinessInputChange}
              autoComplete='off'
            />
          )
        }
      </div>
      <div className={clsx(styles.priceCondition, styles.conditionBlock)}>
        <p className={styles.title}>Цена на растения указана:</p>
        {
          isInArchive ? (
            <span>{priceCondition}</span>
          ) : (
            <input
              type='text'
              name='price-condition'
              placeholder='Условие цены'
              value={priceCondition}
              onChange={handlePriceConditionInputChange}
              autoComplete='off'
            />
          )
        }
      </div>
      <div className={clsx(styles.paymentSchedule, styles.conditionBlock)}>
        <p className={styles.title}>График оплат:</p>
        {
          paymentSchedule.map((item, index) => (
            <div key={index}>
              {
                isInArchive ? (
                  <span>{item}</span>
                ) : (
                  <span className={styles.inputWrapper}>
                    <input
                      type='text'
                      name={`payment-schedule-${index}`}
                      placeholder='График оплат'
                      value={item}
                      onChange={(evt) => handlePaymentScheduleInputChange(index, evt.currentTarget.value)}
                      autoComplete='off'
                    />
                    {
                      paymentSchedule.length > 1 && (
                        <span
                          className={clsx('material-icons', styles.addConditionButton)}
                          onClick={() => handleDeleteConditionButtonClick(index)}
                          title='Добавить условие'
                        >
                          delete_outline
                        </span>
                      )
                    }
                    {
                      index === paymentSchedule.length - 1 && (
                        <span
                          className={clsx('material-icons', styles.addConditionButton)}
                          onClick={handleAddConditionButtonClick}
                          title='Добавить условие'
                        >
                          add
                        </span>
                      )
                    }
                  </span>
                )
              }
            </div>
          ))
        }
      </div>
      <div className={clsx(styles.expirationDate, styles.conditionBlock)}>
        <div>
          <p className={styles.title}>Срок действия КП:</p>
          {
            isInArchive ? (
              <span>{expirationDate ? getHumanFormattedDate(expirationDate) : ''}</span>
            ) : (
              <input
                type='date'
                name='expiration-date'
                placeholder='Срок действия'
                value={expirationDate}
                onChange={handleExpirationDateInputChange}
                autoComplete='off'
              />
            )
          }
        </div>
      </div>
    </div>
  );
};

export default DeliveryTerms;
