export const AppRoute = {
  Root: '/',
  Auth: {
    SIGN_IN: '/login',
    FORGOT: '/forgot',
    SIGN_UP: '/signup'
  },
  Map: {
    ID: '/map/:id',
    ADD: '/map/add',
    EDIT: '/map/edit/:id',
    FIELD: '/map/:id/fields/:field_id'
  },
  Journal: {
    AMOUNT: '/journals/amount',
    SPREAD: '/journals/spread'
  },
  Inventory: {
    ALL: '/inventory',
    ADD: '/inventory/add',
    EDIT: '/inventory/edit/:id'
  },
  Inventories: {
    LIST: '/inventories',
    ID: '/inventories/:id',
    UPLOAD: '/inventories/upload'
  },
  Archive: {
    ALL: '/archive',
    HISTORY: '/archive/history',
    HISTORY_ID: '/archive/history/:id',
    HISTORY_ITEM: '/archive/history/:id/item',
    FORM: '/archive/form',
    CORRECTIONS: '/archive/corrections',
    CORRECTIONS_ID: '/archive/corrections/:id',
    CHARGES: '/archive/charges',
    CHARGES_ID: '/archive/charges/:id',
    POSTINGS: '/archive/postings',
    POSTINGS_ID: '/archive/postings/:id',
    SHIFTINGS: '/archive/shiftings',
    SHIFTINGS_ID: '/archive/shiftings/:id',
    SUPPLIES: '/archive/supplies',
    SUPPLIES_ID: '/archive/supplies/:id',
    REALIZATIONS: '/archive/realizations',
    REALIZATION_ID: '/archive/realizations/:id',
    INVENTORY: '/archive/inventory',
    INVENTORY_ID: '/archive/inventory/:id',
    FACTORIES: '/archive/factories',
    FACTORIES_ID: '/archive/factories/:id',
    OFFERS: '/archive/offers',
    OFFER_ID: '/archive/offers/:id',
  },
  Document: {
    SUPPLIES: '/supplies',
    SUPPLIES_ID: '/supplies/:id',
    REALIZATIONS: '/realizations',
    REALIZATIONS_EDIT: '/realizations/edit/:id',
    REALIZATIONS_NEW: '/realizations/new',
    CHARGES: '/charges',
    CHARGES_ID: '/charges/:id',
    CHARGES_NEW: '/charges/new',
    POSTINGS: '/postings',
    POSTINGS_ID: '/postings/:id',
    POSTINGS_NEW: '/postings/new',
    SHIFTINGS: '/shiftings',
    SHIFTINGS_ID: '/shiftings/:id',
    SHIFTINGS_NEW: '/shiftings/new',
    FORM: '/form', // <?type=Списание|Оприходование|Перемещение>
    FORM_NEW: '/form/new',
    FORM_ID: '/form/:id', // <?type=Списание|Оприходование|Перемещение>

    FACTORIES: '/factories',
    FACTORIES_NEW: '/factories/new',
    FACTORIES_ID: '/factories/:id',

    FACTORY_NEW: '/factory/new',
    FACTORY_ID: '/factory/:id'
  },
  Cabinet: {
    SETTINGS: '/cabinet/settings',
    USERS: '/cabinet/users'
  },
  FAQ: {
    ALL: '/faq',
    JOURNAL_SPREAD: '/faq/journalSpread',
    INVENTORY: '/faq/inventory'
  },
  UserManual: {
    CHARGE: '/user-manual/charge',
    POSTING: '/user-manual/posting',
    SHIFTING: '/user-manual/shifting',
    INVENTORY: '/user-manual/inventory',
    REALIZATION: '/user-manual/realization',
    SUPPLIES: '/user-manual/supply',
    FACTORY: '/user-manual/factory',
    REMAINS: '/user-manual/remains',
    OFFERS: '/user-manual/offers'
  },
  History: {
    SELECT: '/history/select',
    ITEM: '/history'
  },
  Remains: {
    LIST: '/remains',
    ID: '/remains/:id',
    UPLOAD: '/remains/upload'
  },
  Offers: {
    LIST: '/offers',
    ID: '/offers/:id',
    UPLOAD: '/offers/upload'
  },
  Settings: {
    ALL: '/settings'
  }
};
