import {createSlice} from '@reduxjs/toolkit';

import {
  checkIfDenyPossibleAsyncAction,
  fetchAddressesAsyncAction,
  fetchRemainsAsyncAction,
  fetchRemainsDocumentDetailsAsyncAction
} from './remains-api-actions';

const initialState = {
  remains: [],
  remainsDocument: {
    provided: undefined,
    lines: []
  },
  addresses: [],
  providingCanBeDenied: undefined
};

export const remains = createSlice({
  name: 'remains',
  initialState,
  reducers: {
    resetRemainsDocument: (state) => {
      state.remainsDocument = {
        provided: undefined,
        lines: []
      };
      state.providingCanBeDenied = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRemainsAsyncAction.fulfilled, (state, action) => {
        state.remains = action.payload.result;
      })
      .addCase(fetchRemainsDocumentDetailsAsyncAction.fulfilled, (state, action) => {
        state.remainsDocument.lines = action.payload.result;
        state.remainsDocument.provided = Number(action.payload.result[0].provided);
      })
      .addCase(fetchAddressesAsyncAction.fulfilled, (state, action) => {
        state.addresses = action.payload.result;
      })
      .addCase(checkIfDenyPossibleAsyncAction.fulfilled, (state, action) => {
        state.providingCanBeDenied = action.payload.result;
      });
  }
});

export const {
  resetRemainsDocument
} = remains.actions;
