import {useState, useEffect, useContext} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import clsx from 'clsx';
import {toast} from 'react-toastify';

import Input from 'components/input/InputComponent';
import CustomSearchSelect from 'components/custom-search-select/custom-search-select';
import AddNewLineButton from 'components/add-new-line-button/add-new-line-button';
import Modal from 'components/Modal';
import DocumentsFloatPanel from 'components/documents-float-panel/documents-float-panel';
import Preloader from "components/preloader/SpinPlaceholderComponent";
import DocumentsCheckValuesModal from 'components/documents-check-values-modal/documents-check-values-modal';
import PopUp from 'components/popup/popup';
import CheckModalExperimental from 'components/shifting-check-values-modal/check-modal-experimental';
import ShiftingTableRowExperimental from 'components/shiftings-table-row/shifting-table-row-experimental';
import ChangedInBusinessRuDocumentsModal from 'components/changed-documents-modal/changed-documents-modal';

import {fetchGroups, fetchStores} from 'redux/slices/business/businessSlice';
import {getGroups} from 'redux/slices/business/selectors';
import {
  postCommentFilesAsyncAction,
  deleteCommentFileAsyncAction,
  fetchDocumentAsyncAction,
  updateDocumentAsyncAction,
  fetchStorageAddressesAsyncAction,
  fetchGoodsByStorageFromHistoryAsyncAction
} from 'redux/slices/documents/documents-api-actions';
import {
  setDocumentItemsAction,
  setDocumentAction,
  setDocumentStorageAction,
  setDocumentEmployeeAction,
  setDocumentDateAction,
  setDocumentNumberAction,
  setDocumentCommentAction,
  setCommentFilesAction,
  resetDocumentAction,
  setNewDocumentDateAction,
  setDocumentDestinationStorageAction,
  setShiftingTargetStorageAmounts
} from 'redux/slices/documents/documents';
import {
  getDocument,
  getDocumentsNumbers,
  getCommentFiles,
  getDocumentAuthor,
  getDoneStatus,
  getIsCorrectionStatus,
  getProvidedStatus,
  getStorageAddresses,
  getNewDocumentDate,
  getDestinationStorage,
  getGoodsByStorage,
  getChangedInBusinessRuDocuments
} from 'redux/slices/documents/selectors';

import {AppContext} from 'providers/AppContextProvider';
import {getUser, saveNewShiftingDocumentIntoLocalStorage} from 'helpers/storage';
import {generateDate, sortStringValues} from 'helpers/utils';

import {AppRoute} from 'constants/routes';
import {DocumentType, DocumentTypeNameMap} from 'constants/document-type';
import {emptyLine} from 'constants/document-empty-line';

import styles from './styles.module.scss';
import toastMessageStyles from 'styles/components/toast-message.module.scss';

const ShiftingsNew = () => {
  const dispatch = useDispatch();
  const {id} = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const ARCHIVE_LOCATION_REG_EXP = /archive/;
  const pathName = location.pathname;
  const isInArchive = ARCHIVE_LOCATION_REG_EXP.test(pathName);

  // Проверка уровня пользователя
  const canUserUpdate = getUser().accessLevel > 1;

  // Классы таблицы
  const tableClasses = [styles.mainTable, styles.top0, "table", "table-responsive"];

  const {alert, setCrumbs} = useContext(AppContext);

  // Модальное окно примечания
  const [commentModalActive, setCommentModalActive] = useState(false);

  // Модальное окно со списком некорректно заполненных строк
  const [checkValuesModalData, setCheckValuesModalData] = useState([]);
  const [isCheckValuesModalActive, setIsCheckValuesModalActive] = useState(false);

  // Модальное окно со списком некорректно заполненных строк (Перемещения)
  const [checkShiftingValuesModalData, setCheckShiftingValuesModalData] = useState([]);
  const [isCheckShiftingValuesModalActive, setIsCheckShiftingValuesModalActive] = useState(false);

  // Активность прелоадера
  const [isPreloaderActive, setIsPreloaderActive] = useState(false);

  // Состояние видимости подсказки номера документа (всплывает, если такой номер уже есть в списке)
  const [documentNumberPopUpVisible, setDocumentNumberPopUpVisible] = useState(false);

  // Модальное окно с изменёнными в Бизнес.ру документами
	const [changedInBRuDocumentsModalActive, setChangedInBRuDocumentsModalActive] = useState(false);
  const [changedInBRuDocumentsModalData, setChangedInBRuDocumentsModalData] = useState({
    inventories: [],
    supplies: [],
    realizations: [],
    postings: [],
    charges: [],
    shiftings: [],
    factories: []
  });

  // Тип и порядок сортировки
  const [sortData, setSortData] = useState({
    type: 'name',
    order: 'ASC'
  });

  const shiftingDocument = useSelector(getDocument);
  const provided = useSelector(getProvidedStatus);
  const done = useSelector(getDoneStatus);
  const correction = useSelector(getIsCorrectionStatus);
  const documentNumber = shiftingDocument.documentNumber;
  const documentDate = shiftingDocument.documentDate;
  const newDocumentDate = useSelector(getNewDocumentDate);
  const documentItems = shiftingDocument.documentItems;
  const storage = shiftingDocument.storage;
  const destinationStorage = useSelector(getDestinationStorage);
  const employee = shiftingDocument.employee;
  const comment = shiftingDocument.comment;
  const commentFiles = useSelector(getCommentFiles);
  const author = useSelector(getDocumentAuthor);
  const documentsNumbers = useSelector(getDocumentsNumbers);
  const itemsNames = structuredClone(documentItems).map((item) => item.name).filter((item) => !!item);
  const changedInBusinessRuDocuments = useSelector(getChangedInBusinessRuDocuments);

  const goods = useSelector(getGoodsByStorage);
  const groupsIdsFromGoods = goods.map((good) => good.b_group_id);
  const groups = useSelector(getGroups).filter((group) => groupsIdsFromGoods.includes(group.id));
  const storages = useSelector((state) => state.business).stores.data;

  const allAddresses = useSelector(getStorageAddresses);
  const addresses = allAddresses.filter((address) => address.storage === destinationStorage).sort((a, b) => {
    const ADDRESS_REG_EXP = /^\d+/;
    const prevValue = a.value;
    const nextValue = b.value;

    if (ADDRESS_REG_EXP.test(prevValue) && ADDRESS_REG_EXP.test(nextValue)) {
      return prevValue.match(ADDRESS_REG_EXP)[0] - nextValue.match(ADDRESS_REG_EXP)[0];
    }
    if (ADDRESS_REG_EXP.test(prevValue) && !ADDRESS_REG_EXP.test(nextValue)) {
      return -1;
    }
    if (!ADDRESS_REG_EXP.test(prevValue) && ADDRESS_REG_EXP.test(nextValue)) {
      return 1;
    }
    
    return 0;
  });

  // Функция сортировки
  const handleSortElementClick = (name) => {
    setSortData((prevState) => ({
      type: name,
      order: prevState.type === name ? prevState.order === 'ASC' ? 'DESC' : 'ASC' : 'ASC'
    }));
  };

  // Установка товаров при изменении сортировки (sortFn)
  useEffect(() => {
    const sortedItems = structuredClone(documentItems).sort((a, b) => {
      const sortOrder = sortData.order === 'ASC'
        ? sortData.type === 'article'
            ? Number(a[sortData.type]) - Number(b[sortData.type])
            : sortStringValues(a[sortData.type], b[sortData.type])
        : sortData.type === 'article'
            ? Number(b[sortData.type]) - Number(a[sortData.type])
            : -sortStringValues(a[sortData.type], b[sortData.type]);

      return sortOrder;
    });

    dispatch(setDocumentItemsAction(sortedItems));
  }, [itemsNames.length, sortData]);

  // Добавление строки
  const addLine = (index = -1, firstDeleted = false) => {
    const copy = structuredClone(documentItems);
    
    copy.push(emptyLine)

    dispatch(setDocumentItemsAction(copy));
  };

  // Удаление строки
  const deleteLine = (index) => {
    // Если удалена последняя строка, то автоматически вставляем новую пустую
    if (documentItems.length === 1) {
      dispatch(setDocumentItemsAction([emptyLine]));
    } else {
      const copy = structuredClone(documentItems);

      copy.splice(index, 1);
      dispatch(setDocumentItemsAction(copy));
    }
  };

  // Обработчик кнопки удаления строки
  const handleDeleteLineButtonClick = (index) => {
    const handler = () => {
      deleteLine(index);
    };

    alert('Удалить товар из списка?', 'danger', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  // Выбор и установка склада отправления
  const handleStorageInputChange = (selectedStorage) => {
    // Если склад отправления уже был выбран и уже есть хотя бы один товар
    if (storage && documentItems.length && documentItems[0].name) {
      if (storage !== selectedStorage) {
        alert('Введённые данные будут потеряны. Вы уверены, что хотите изменить склад?', 'danger', 0, [
          {
            text: 'Да',
            handler: () => {
              dispatch(setDocumentStorageAction(selectedStorage));
              dispatch(setDocumentItemsAction([emptyLine]));
              alert('', 'default', 1);
            },
            needToCloseImmediately: true
          },
          {
            text: 'Нет',
            handler: () => {
              alert('', 'default', 1);
            }
          }
        ]);
      } else {
        return;
      }
    }

    // Если склад уже был выбран и пока есть только одна пустая строка
    if (storage && documentItems.length === 1 && !documentItems[0].name) {
      dispatch(setDocumentStorageAction(selectedStorage));
      return;
    }

    dispatch(setDocumentStorageAction(selectedStorage));

    // Для предотвращения изменения внутреннего значения CustomSearchSelect,
    // если пользователь нажал на кнопку "Нет" при изменении склада возвращается true
    // и проверяется в обработчике компонента CustomSearchSelect
    return true;
  };

  // Выбор и установка склада назначения
  const handleDestinationStorageInputChange = (selectedStorage) => {
    // Если склад назначения уже был выбран и уже есть хотя бы один товар
    if (destinationStorage && documentItems.length && documentItems[0].name) {
      if (destinationStorage !== selectedStorage) {
        alert('Введённые данные будут потеряны. Вы уверены, что хотите изменить склад?', 'danger', 0, [
          {
            text: 'Да',
            handler: () => {
              dispatch(setDocumentDestinationStorageAction(selectedStorage));
              dispatch(setDocumentItemsAction([emptyLine]));
              dispatch(setShiftingTargetStorageAmounts([]));
              alert('', 'default', 1);
            },
            needToCloseImmediately: true
          },
          {
            text: 'Нет',
            handler: () => {
              alert('', 'default', 1);
            }
          }
        ]);
      }
      
      return;
    }

    // Если склад уже был выбран и пока есть только одна пустая строка
    if (destinationStorage && documentItems.length === 1 && !documentItems[0].name) {
      dispatch(setDocumentDestinationStorageAction(selectedStorage));

      return;
    }

    dispatch(setDocumentDestinationStorageAction(selectedStorage));
    dispatch(setDocumentItemsAction([emptyLine]));

    // Для предотвращения изменения внутреннего значения CustomSearchSelect,
    // если пользователь нажал на кнопку "Нет" при изменении склада возвращается true
    // и проверяется в обработчике компонента CustomSearchSelect
    return true;
  };

  // Обработчик поля ввода имени бригадира
  const handleEmployeeInputChange = (employee) => {
    dispatch(setDocumentEmployeeAction(employee));
  };

  // Обработчик поля ввода номера документа
  const handleDocumentNumberInputChange = (name, value) => {
    const existingDocumentsNumbers = documentsNumbers.map((docNumber) => docNumber.match(/\d+/)[0]);
    const newDocumentNumberAlreadyExists = existingDocumentsNumbers.includes(value);

    // Проверка, есть ли уже такой номер документа в списке существующих
    // и показ уведомления, если есть
    if (newDocumentNumberAlreadyExists) {
      setDocumentNumberPopUpVisible(true);
    } else {
      setDocumentNumberPopUpVisible(false);
    }

    dispatch(setDocumentNumberAction(value));
  };

  // Обработчик ввода текста примечания
  const handleModalTextAreaChange = (evt) => {
    const textAreaValue = evt.currentTarget.value;
    dispatch(setDocumentCommentAction(textAreaValue));
  };

  // Отправка файлов примечания на сервер
  const sendCommentFiles = async (id, files) => {
    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append(`file_${index}`, file);
    });

    await dispatch(postCommentFilesAsyncAction({
      id,
      files: formData
    }));
  };

  // Обработчик добавления файла к примечанию в модальном окне
  const handleFileInputChange = async (evt) => {
    const inputFiles = Object.values(evt.currentTarget.files);
    const updatedFiles = [...commentFiles, ...inputFiles];

    dispatch(setCommentFilesAction(updatedFiles));
  };

  // Удаление файла из модального окна примечания
  const removeFile = async ({url, index}) => {
    // Если это новый документ, то удаляем из стейта
    if (index) {
      const updatedCommentFiles = commentFiles.filter((_file, fileIndex) => fileIndex !== index);
      dispatch(setCommentFilesAction(updatedCommentFiles));
    }
    // Если редактируется существующий, то сразу удаляем на сервере
    if (url) {
      await dispatch(deleteCommentFileAsyncAction({filename: url}));
      dispatch(fetchDocumentAsyncAction(id));
    }
  };

  // Запрос детальной информации документа
  const requestForDocumentDetails = async () => {
    setIsPreloaderActive(true);
    await dispatch(fetchDocumentAsyncAction(id));
    setIsPreloaderActive(false);
  };

  // Генерация номера нового документа
  const generateDocumentNumber = () => {
    const existingDocumentsNumbers = documentsNumbers
      .filter((docNumber) => (/\d+/).test(String(docNumber)))
      .map((docNumber) => Number(String(docNumber).match(/\d+/)[0]))
      .sort((a, b) => a - b);
    const newNumber = [0, ...existingDocumentsNumbers]
      .find((docNumber) => !existingDocumentsNumbers.includes(docNumber + 1)) + 1;
    // const newNumber = existingDocumentsNumbers.length ? Math.max(...existingDocumentsNumbers) + 1 : 1;

    return newNumber;
  };

  // Обработчик кнопки закрытия модального окна примечания
  const handleCommentModalCloseButtonClick = async () => {
    const newFiles = commentFiles.filter((file) => file instanceof File);

    if (id && canUserUpdate) {
      await dispatch(updateDocumentAsyncAction({
        id,
        body: {
          type: DocumentTypeNameMap[DocumentType.SHIFTING],
          form: documentItems,
          document: {
            number: documentNumber,
            date: documentDate
          },
          storage,
          employee,
          comment
        }
      }));
      if (newFiles.length) {
        await sendCommentFiles(id, newFiles);
      }
      dispatch(fetchDocumentAsyncAction(id));
    }

    setCommentModalActive(false);
  };

  // Обработчик клика по неактивной дате документа
  const handleInactiveDocumentDateClick = () => {
    toast.error('Дата сохранена. Если нужна другая дата - создайте новый документ', {
      position: 'top-left',
      className: toastMessageStyles['toast-message']
    });
  };

  // Очистка стейта после отмонтирования компонента
  useEffect(() => {
    return () => {
      dispatch(resetDocumentAction());
    };
  }, []);

  // Запрос данных конкретного документа с сервера
  useEffect(() => {
    if (id) {
      requestForDocumentDetails();
    }
    dispatch(fetchStores());
  }, [id, provided, done]);

  // Запрос всех имеющихся в Системе товаров
  // (для формирования выпадающих списков выбора наименования, артикула и группы)
  useEffect(() => {
    if (!goods.length) {
      if (storage) {
        dispatch(fetchGoodsByStorageFromHistoryAsyncAction({
          storage,
          date: newDocumentDate ? `${newDocumentDate}${documentDate.slice(-9)}` : documentDate
        }));
      }
    }
    if (!groups.length) {
      dispatch(fetchGroups());
    }
  }, [storage]);

  // Запрос адресов по конкретному выбранному складу
  useEffect(() => {
    if (storage) {
      dispatch(fetchStorageAddressesAsyncAction(storage));
    }
  }, [storage]);

  // Установка номера для нового документа
  useEffect(() => {
    if (!id) {
      if (!documentNumber) {
        const newDocumentNumber = generateDocumentNumber();
        dispatch(setDocumentNumberAction(newDocumentNumber));
      }
    }
  }, [documentsNumbers]);

  // Установка даты для нового документа
  useEffect(() => {
    if (!id) {
      if (!documentDate) {
        const newDocumentDate = generateDate();
        dispatch(setDocumentDateAction(newDocumentDate));
      }
    }
  }, []);

  // Формирование данных для хлебных крошек
  const getCrumbs = () => {
    const isCorrection = Number(correction) === 1;
    const crumbs = {
      archive: {
        title: 'Архивы',
        url: AppRoute.Archive.ALL
      },
      shiftings: {
        title: 'Перемещение',
        url: isInArchive ? AppRoute.Archive.SHIFTINGS : AppRoute.Document.SHIFTINGS
      },
      corrections: {
        title: 'Коррекции',
        url: AppRoute.Archive.CORRECTIONS
      },
      new: {
        title: 'Новый документ',
        url: ''
      }
    };
    const pathNameItems = pathName.split('/').filter((item) => item !== '' && item.match(/\D/));
    const compiledCrumbs = pathNameItems.map((item) => ({
      name: crumbs[item].title,
      url: crumbs[item].url
    }));

    if (id) {
      if (isCorrection) {
        compiledCrumbs.push(
          {name: `№ ${documentNumber} ${storage === destinationStorage ? '(внутреннее)' : '(коррекция)'}`, url: ''}
        );
      } else {
        compiledCrumbs.push(
          {name: `№ ${documentNumber}`, url: ''}
        );
      }
    }

    return compiledCrumbs;
  };

  // Запись хлебных крошек
  useEffect(() => {
    setCrumbs([
      ...getCrumbs()
    ]);
  }, [documentNumber, isInArchive, correction]);

  // Если уже есть начатый документ в localStorage, то продолжить его заполнение
  useEffect(() => {
    const incompletedShiftingDocument = JSON.parse(localStorage.getItem('newShiftingDocument'));
    if (incompletedShiftingDocument) {
      dispatch(setDocumentAction(incompletedShiftingDocument));
    }
  }, []);

  // Сохранение нового документа в localStorage
  useEffect(() => {
    if (!id && (documentItems.length || storage || employee || comment)) {
      saveNewShiftingDocumentIntoLocalStorage(shiftingDocument);
    }
  }, [shiftingDocument]);

  // Защита от открытия архивного документа с помощью адресной строки в основном списке и наоборот
  // Если пользователь намеренно вводит в адресной строке "/charges/<id архивного документа>"
  useEffect(() => {
    if (id) {
      const isDone = done === 1;
      const notDone = done !== undefined && done === 0;
      // const isCorrection = Number(correction) === 1;

      if (isDone) {
        // if (isCorrection) {
        //   navigate(`${AppRoute.Archive.CORRECTIONS}/${id}`);
        // } else {
        //   navigate(`${AppRoute.Archive.POSTINGS}/${id}`);
        // }
        navigate(`${AppRoute.Archive.SHIFTINGS}/${id}`);
      }
      if (notDone) {
        navigate(`${AppRoute.Document.SHIFTINGS}/${id}`);
      }
    }
  }, [correction, done, id, isInArchive]);

  return (
    <div>
      <Preloader isActive={isPreloaderActive}/>

      <DocumentsFloatPanel
        sendCommentFiles={sendCommentFiles}
        setModalActive={setCommentModalActive}
        setIsPreloaderActive={setIsPreloaderActive}
        setCheckValuesModalData={setCheckValuesModalData}
        setIsCheckValuesModalActive={setIsCheckValuesModalActive}
        setCheckShiftingValuesModalData={setCheckShiftingValuesModalData}
        setIsCheckShiftingValuesModalActive={setIsCheckShiftingValuesModalActive}
        changedInBusinessRuDocuments={changedInBusinessRuDocuments}
        setChangedInBRuDocumentsModalActive={setChangedInBRuDocumentsModalActive}
        setChangedInBRuDocumentsModalData={setChangedInBRuDocumentsModalData}
        addresses={addresses}
      />

      <table className={tableClasses.join(" ")}>
        <thead className={styles.top0 + " theadBordered thead-dark"}>
          <tr>
            <th colSpan="2" className={styles.borderNone}>
              <span>Акт перемещения № </span>
              {
                !provided && canUserUpdate ? (
                  <>
                    <Input
                      type="text"
                      name="document-number"
                      autocomplete="off"
                      placeholder="Номер документа"
                      value={documentNumber}
                      setValue={handleDocumentNumberInputChange}
                    />
                    {
                      documentNumberPopUpVisible && (
                        <PopUp setPopUpVisible={setDocumentNumberPopUpVisible}>
                          <span>
                            Документ с таким номером уже существует
                          </span>
                        </PopUp>
                      )
                    }
                  </>
                ) : documentNumber
              }
            </th>
            <th className={styles.borderNone}>
              <span>от </span>
              {
                !provided && canUserUpdate && documentItems.length === 0 ? (
                  <Input
                    type="text"
                    name="documentDate"
                    autocomplete="off"
                    mask="99.99.9999"
                    placeholder="Дата"
                    value={newDocumentDate ? newDocumentDate : documentDate}
                    setValue={(name, value) => dispatch(setNewDocumentDateAction(value))}
                  />
                ) : (
                  <span onClick={handleInactiveDocumentDateClick}>
                    {newDocumentDate ? newDocumentDate : documentDate.substring(0, 10)}
                  </span>
                )
              }
            </th>
            <th>
              {
                id && (
                  <>
                    <span>Автор:&nbsp;{author}</span>
                    <br/>
                  </>
                )
              }
              Бригадир:&nbsp;
              {
                !provided && canUserUpdate ? (
                  <Input
                    type="text"
                    name="employee"
                    value={employee}
                    setValue={(name, value) => handleEmployeeInputChange(value)}
                    placeholder="Бригадир"
                    title={employee}
                  />
                ) : employee
              }
            </th>
            <th colSpan="3">Остатки до перемещения:</th>
            <th className={styles.storageFixed}>
              Со склада:
              <br/>
              {
                !provided ? (
                  <CustomSearchSelect
                    inputName='storage'
                    defaultValue={storage}
                    options={storages}
                    onChange={handleStorageInputChange}
                    placeholder='Склад'
                  />
                ) : (
                  storage
                )
              }
            </th>
            <th colSpan="2">
              На склад:
              <br/>
              {
                !provided ? (
                  <CustomSearchSelect
                    inputName='toStorage'
                    defaultValue={destinationStorage}
                    options={storages}
                    onChange={handleDestinationStorageInputChange}
                    placeholder='На склад'
                  />
                ) : (
                  destinationStorage
                )
              }
            </th>
            <th colSpan="3">Остатки после перемещения:</th>
            {
              provided === 0 && (
                <th rowSpan="3" colSpan="2" className={styles.borderNone}/>
              )
            }
          </tr>
          <tr>
            <th rowSpan="2">№</th>
            <th
              rowSpan="2"
              className={clsx('sort', {
                'sorted': sortData.type === 'name',
                'rotated': sortData.type === 'name' && sortData.order === 'ASC'
              })}
              onClick={() => handleSortElementClick('name')}
            >
              <span>Название</span>
            </th>
            <th
              rowSpan="2"
              className={clsx('sort', {
                'sorted': sortData.type === 'article',
                'rotated': sortData.type === 'article' && sortData.order === 'ASC'
              })}
              onClick={() => handleSortElementClick('article')}
            >
              <span>Артикул</span>
            </th>
            <th
              rowSpan="2"
              className={clsx('sort', {
                'sorted': sortData.type === 'b_group',
                'rotated': sortData.type === 'b_group' && sortData.order === 'ASC'
              })}
              onClick={() => handleSortElementClick('b_group')}
            >
              Группа
            </th>
            <th rowSpan="2">Всего:</th>
            <th colSpan="2">В том числе:</th>
            <th rowSpan="2">Кол-во</th>
            <th rowSpan="2">Кол-во</th>
            <th rowSpan="2">Адрес</th>
            <th rowSpan="2">Всего:</th>
            <th colSpan="2">В том числе:</th>
          </tr>
          <tr>
            <th>Кол-во</th>
            <th>Адрес</th>
            <th>Кол-во</th>
            <th>Адрес</th>
          </tr>
        </thead>
        <tbody>
        {
          storage && destinationStorage && (
            <>
              {/* Строки таблицы */}
              {
                documentItems.map((line, lineIndex) => (
                  <ShiftingTableRowExperimental
                    key={lineIndex}
                    line={line}
                    lineIndex={lineIndex}
                    goods={goods}
                    groups={groups}
                    addresses={addresses}
                    deleteLine={handleDeleteLineButtonClick}
                  />
                ))
              }
              
              {
                !provided && canUserUpdate && (
                  <AddNewLineButton title='Добавить строку' addLine={addLine}/>
                )
              }
              <tr style={{height: "300px"}}/>
            </>
          )
        }
        </tbody>
      </table>

      <Modal isActive={commentModalActive} setIsActive={handleCommentModalCloseButtonClick}>
        {
          !isInArchive && canUserUpdate ? (
            <>
              <textarea
                value={comment}
                onChange={handleModalTextAreaChange}
                placeholder="Примечание"
              />
              <input
                type="file"
                name="files[]"
                onChange={handleFileInputChange}
                multiple
              />
            </>
          ) : (
            <div className={styles.commentFrame}>
              {comment}
            </div>
          )
        }
        {
          commentFiles.map((file, index) => (
            <div className="fileIconGroup">
              {
                !isInArchive && canUserUpdate && (
                  <span
                    className="material-icons remove"
                    title="Удалить"
                    onClick={() => removeFile(id ? {url: file.url} : {index})}
                  >
                    cancel
                  </span>
                )
              }
              <span
                className="material-icons file"
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_SERVER_URL + file.url,
                    "_blank"
                  )
                }
              >
                description
              </span>
              <span
                className="name"
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_SERVER_URL + file.url,
                    "_blank"
                  )
                }
              >
                {file.name}
              </span>
            </div>
          ))
        }
      </Modal>

      <DocumentsCheckValuesModal
        data={checkValuesModalData}
        isActive={isCheckValuesModalActive}
        setIsActive={setIsCheckValuesModalActive}
      />

      {/* <ShiftingCheckValuesModal
        data={checkShiftingValuesModalData}
        isActive={isCheckShiftingValuesModalActive}
        setIsActive={setIsCheckShiftingValuesModalActive}
      /> */}

      <CheckModalExperimental
        data={checkShiftingValuesModalData}
        isActive={isCheckShiftingValuesModalActive}
        setIsActive={setIsCheckShiftingValuesModalActive}
      />

      {
        !isInArchive && (
          <ChangedInBusinessRuDocumentsModal
            changedInBusinessRuDocuments={changedInBRuDocumentsModalData}
            changedInBRuDocumentsModalActive={changedInBRuDocumentsModalActive}
            setChangedInBRuDocumentsModalActive={setChangedInBRuDocumentsModalActive}
          />
        )
      }
    </div>
  );
};

export default ShiftingsNew;
