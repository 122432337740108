import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';

import InventoryTableHeader from 'components/inventory-table-header/inventory-table-header';
import InventoryTableBody from 'components/inventory-table-body/inventory-table-body';

import cl from 'styles/pages/[fieldId].module.scss';

// Классы таблицы
const tableClasses = [
  cl.mainTable,
  cl.top0,
  cl.overflow,
  'table',
  'table-responsive',
];

// Интервал автосохранения
// const AUTO_SAVE_INTERVAL = 300000;

const InventoryTable = ({
  paginationData,
  setPaginationData,
  firstItemIndex,
  displayedItems,
  handleSortElementClick,
  sortData
}) => {
  const inventory = useSelector((state) => state.inventory).inventory;
  const inventoryItems = inventory.data;

  // Общее кол-во по позициям
  const [totals, setTotals] = useState({ uchet: 0, fact: 0 });

  // Активность кнопки расчета отклонения
  const [isActiveTotalSpreadButton, setIsActiveTotalSpreadButton] = useState(false);

  // Подсчёт общего кол-ва по позициям
  useEffect(() => {
    if (inventoryItems.length) {
      const total = structuredClone(totals);
      total.uchet = 0;
      total.fact = 0;

      const areAllTotalSpreadsEstimated = inventoryItems.every((invItem) => {
        const isItemTotalSpreadEstimated = Number(invItem.total_fact) - Number(invItem.total_uchet) === Number(invItem.total_spread);
        return isItemTotalSpreadEstimated;
      });

      // Подсчет общего кол-ва
      inventoryItems.forEach((invItem) => {
        total.uchet += invItem.total_uchet;
        total.fact += invItem.total_fact;
      });

      const shouldButtonGetActive = !isActiveTotalSpreadButton && !areAllTotalSpreadsEstimated;
      if (shouldButtonGetActive) {
        setIsActiveTotalSpreadButton(true);
      }

      setTotals(total);
    }
  }, [inventoryItems]);
  
  return (
    <table
      className={tableClasses.join(" ")}
      // onClick={() => setIsFilterOpen(false)}
    >
      <InventoryTableHeader
        totals={totals}
        isActiveTotalSpreadButton={isActiveTotalSpreadButton}
        setIsActiveTotalSpreadButton={setIsActiveTotalSpreadButton}
        handleSortElementClick={handleSortElementClick}
        sortData={sortData}
      />
      <InventoryTableBody
        paginationData={paginationData}
        setPaginationData={setPaginationData}
        displayedItems={displayedItems}
        firstItemIndex={firstItemIndex}
      />
    </table>
  );
};

export default InventoryTable;
