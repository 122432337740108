import {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import CardComponent from 'components/CardComponent';
import GeneralChangedInBusinessRuDocumentsModal from 'components/changed-documents-modal/general-changed-documents-modal';
import Preloader from 'components/preloader/SpinPlaceholderComponent';

import {fetchMaps} from 'redux/slices/mapSlice';
import {getChangedInBusinessRuDocuments} from 'redux/slices/documents/selectors';
import {fetchChangedInBusinessRuDocumentsAsyncAction} from 'redux/slices/documents/documents-api-actions';
import {getSettings} from 'redux/slices/settings/selectors';

import {AppContext} from 'providers/AppContextProvider';
import {loadingStatus} from 'helpers/fetcher';
import {getUser} from 'helpers/storage';

import cl from 'styles/pages/index.module.scss';

const Maps = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {maps} = useSelector((state) => state.map);
  const changedInBusinessRuDocuments = useSelector(getChangedInBusinessRuDocuments);
  const settings = useSelector(getSettings);
  const businessRuDataCheckSettingStatus = settings.find((setting) => setting.name === 'business_ru_data_check')
    ? settings.find((setting) => setting.name === 'business_ru_data_check').status
    : 0;

  const {setCrumbs} = useContext(AppContext);

  // Активность прелоадера
  const [isPreloaderActive, setIsPreloaderActive] = useState(false);

  // Модальное окно с изменёнными в Бизнес.ру документами
	const [changedInBRuDocumentsModalActive, setChangedInBRuDocumentsModalActive] = useState(false);

  const requestForChangedInBusinessRuDocuments = async () => {
    setIsPreloaderActive(true);
    await dispatch(fetchChangedInBusinessRuDocumentsAsyncAction());
    setIsPreloaderActive(false);
  };

  // Запрос изменённых в Бизнес.ру документов
  useEffect(() => {
    if (businessRuDataCheckSettingStatus === 1) {
      requestForChangedInBusinessRuDocuments();
    }
  }, [businessRuDataCheckSettingStatus]);

  // Запись хлебных крошек
  useEffect(() => {
    setCrumbs([]);
  }, []);

  useEffect(() => {
    dispatch(fetchMaps());
  }, []);

  // Активация модального окна с отменёнными в Бизнес.ру поступлениями
	useEffect(() => {
		if (Object.values(changedInBusinessRuDocuments).some((item) => !!item.length)) {
			setChangedInBRuDocumentsModalActive(true);
		}
	}, [...Object.values(changedInBusinessRuDocuments).map((item) => item.length)]);
  
  return (
    <>
      <Preloader isActive={isPreloaderActive}/>

      <div className={cl.cardContainer + " container"}>
        {maps.status === loadingStatus.LOADING && <div>Loading...</div>}
        <div className="row">
          {maps.data.map((map) => (
            <div
              className="col-sm-3"
              style={{ marginTop: "2rem" }}
              key={map.id.toString()}
            >
              <CardComponent
                name={map.name}
                image={map.thumb}
                buttons={[
                  {
                    text: "Открыть",
                    handler: function () {
                      navigate("/map/" + map.id);
                    },
                  },
                  getUser().accessLevel > 2 && {
                    text: "Редактировать",
                    handler: function () {
                      navigate("/map/edit/" + map.id);
                    },
                  },
                ]}
              />
            </div>
          ))}
          {getUser().accessLevel > 2 && (
            <div className="col-sm-3" style={{ marginTop: "2rem" }}>
              <CardComponent
                name="Новая карта"
                image={`${process.env.REACT_APP_SERVER_URL}/img/maps/map-add.svg`}
                buttons={[
                  {
                    text: <span className="material-icons">add</span>,
                    handler: function () {
                      navigate("/map/add");
                    },
                  },
                ]}
              />
            </div>
          )}
        </div>
      </div>

      <GeneralChangedInBusinessRuDocumentsModal
        changedInBusinessRuDocuments={changedInBusinessRuDocuments}
        changedInBRuDocumentsModalActive={changedInBRuDocumentsModalActive && businessRuDataCheckSettingStatus === 1}
        setChangedInBRuDocumentsModalActive={setChangedInBRuDocumentsModalActive}
      />
    </>
  );
};

export default Maps;
