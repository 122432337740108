import {Link} from 'react-router-dom';
import clsx from 'clsx';

import Modal from 'components/Modal';

import {
  DocumentTypeAppRouteMap,
  DocumentTypeArchiveNameMap,
  DocumentTypeListNameMap,
  ListTypeDocTypeMap
} from './constants';

import styles from './styles.module.scss';

const GeneralChangedInBusinessRuDocumentsModal = ({
  changedInBusinessRuDocuments,
  changedInBRuDocumentsModalActive,
  setChangedInBRuDocumentsModalActive
}) => {
  const handleNavigateToArchiveButtonClick = (type) => {
		window.open(DocumentTypeAppRouteMap[type]);
		setChangedInBRuDocumentsModalActive(false);
	};

  return (
    <Modal
      isActive={changedInBRuDocumentsModalActive}
      setIsActive={setChangedInBRuDocumentsModalActive}
    >
      <div className={styles.wrapper}>
        <p className={styles.title}>
          В этих разделах Системы есть проведённые документы,<br/>которые были отменены, удалены или изменены в Бизнес.ру
        </p>

        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.cell}>№п/п</th>
              <th className={styles.cell}>Раздел</th>
              <th className={styles.cell}>Кол-во</th>
              <th className={styles.cell}>Перейти в раздел</th>
            </tr>
          </thead>
          <tbody>
            {
              Object.keys(changedInBusinessRuDocuments)
                .filter((documentType) => !!changedInBusinessRuDocuments[documentType].length)
                .map((documentType, index) => (
                  <tr key={documentType}>
                    <td className={styles.cell}>{index + 1}</td>
                    <td className={clsx(styles.cell, styles.sectionTitle)}>{DocumentTypeListNameMap[ListTypeDocTypeMap[documentType]]}</td>
                    <td className={styles.cell}>{changedInBusinessRuDocuments[documentType].length}</td>
                    <td className={clsx(styles.cell, styles.sectionButton)}>
                      {/* <button
                        className={clsx(styles.button, styles.regular)}
                        onClick={() => handleNavigateToArchiveButtonClick(ListTypeDocTypeMap[documentType])}
                      >
                        <span>
                          {DocumentTypeArchiveNameMap[ListTypeDocTypeMap[documentType]]}
                        </span>
                      </button> */}
                      <Link
                        className={styles.link}
                        onClick={() => handleNavigateToArchiveButtonClick(ListTypeDocTypeMap[documentType])}
                      >
                        {DocumentTypeArchiveNameMap[ListTypeDocTypeMap[documentType]]}
                      </Link>
                    </td>
                  </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default GeneralChangedInBusinessRuDocumentsModal;
