import {createAsyncThunk} from '@reduxjs/toolkit';

import {ApiRoute} from 'constants/endpoints';

export const fetchSettingsAsyncAction = createAsyncThunk(
  'settings/fetchSettings',
  async (_args, {extra: api}) => {
    const {data} = await api.get(ApiRoute.Settings.ALL);

    return data;
  }
);

export const updateSettingAsyncAction = createAsyncThunk(
  'settings/updateSetting',
  async (args, {extra: api}) => {
    const id = args.id;
    const settingStatus = args.settingStatus;
    const {data} = await api.put(`${ApiRoute.Settings.UPDATE}/${id}`, {settingStatus});

    return {data};
  }
);
