import {useState, useEffect, useContext} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import Indicator from 'components/Indicator';
import Preloader from 'components/preloader/SpinPlaceholderComponent';
import DocumentsListButtonsBlock from 'components/documents-list-buttons-block/documents-list-buttons-block';
import ChangedInBusinessRuDocumentsModal from 'components/changed-documents-modal/changed-documents-modal';

import {setEmployeeAction} from 'redux/slices/inventory/inventorySlice';
import {fetchInventoriesAsyncAction} from 'redux/slices/inventory/inventory-api-actions';
import {getChangedInBusinessRuDocuments} from 'redux/slices/documents/selectors';
import {fetchChangedInBusinessRuDocumentsAsyncAction} from 'redux/slices/documents/documents-api-actions';
import {getSettings} from 'redux/slices/settings/selectors';

import {AppContext} from 'providers/AppContextProvider';

import {loadingStatus} from 'helpers/fetcher';
import {getDateTimeStringFromDBValue, getHumanFormattedDate} from 'helpers/utils';

import {DocumentType} from 'constants/document-type';

import cl from 'styles/components/cabinet/UsersTable.module.scss';
import {AppRoute} from 'constants/routes';

const InventoriesList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
	const location = useLocation();

	const LOCATION_REG_EXP = /archive/;
  const pathName = location.pathname;
  const isInArchive = LOCATION_REG_EXP.test(pathName);

	const {setCrumbs} = useContext(AppContext);

  const {inventories} = useSelector((state) => state.inventory);
	const sortedInventories = structuredClone(inventories.data).sort((currentInventory, nextInventory) => {
		const currentItemDate = new Date(getDateTimeStringFromDBValue(currentInventory.before.date_current));
		const nextItemDate = new Date(getDateTimeStringFromDBValue(nextInventory.before.date_current));

		const currentItemNumber = Number(currentInventory.before.document_number.match(/\d+/)[0]);
		const nextItemNumber = Number(nextInventory.before.document_number.match(/\d+/)[0]);

		if (nextItemDate.getTime() === currentItemDate.getTime()) {
			if (nextItemNumber === currentItemNumber) {
				const currentItemCopyNumber = currentInventory.before.document_number.split(' ')[2] ? Number(currentInventory.before.document_number.split(' ')[2]) : 0;
				const nextItemCopyNumber = nextInventory.before.document_number.split(' ')[2] ? Number(nextInventory.before.document_number.split(' ')[2]) : 0;

				return nextItemCopyNumber - currentItemCopyNumber;
			} else {
				return nextItemNumber - currentItemNumber;
			}
		} else {
			return nextItemDate - currentItemDate;
		}
	});
	const changedInBusinessRuDocuments = useSelector(getChangedInBusinessRuDocuments);
	const settings = useSelector(getSettings);
	const businessRuDataCheckSettingStatus = settings.find((setting) => setting.name === 'business_ru_data_check')
		? settings.find((setting) => setting.name === 'business_ru_data_check').status
		: 0;

	// Активность прелоадера
  const [isPreloaderActive, setIsPreloaderActive] = useState(false);

	// Модальное окно с изменёнными в Бизнес.ру документами
	const [changedInBRuDocumentsModalActive, setChangedInBRuDocumentsModalActive] = useState(false);

  const handleShowInventoryDetailsButtonClick = (inventoryData) => {
    navigate(`${AppRoute.Inventories.LIST}/${inventoryData.before.id}`);

    if (inventoryData.after) {
      window.open(`${AppRoute.Inventories.LIST}/${inventoryData.after.id}`, '_blank');
    }
  };

	const requestForChangedInBusinessRuDocuments = async () => {
    setIsPreloaderActive(true);
    await dispatch(fetchChangedInBusinessRuDocumentsAsyncAction());
    setIsPreloaderActive(false);
  };

  // Запрос изменённых в Бизнес.ру документов
  useEffect(() => {
		if (businessRuDataCheckSettingStatus === 1) {
			requestForChangedInBusinessRuDocuments();
		}
  }, [isInArchive, businessRuDataCheckSettingStatus]);

	// Запрос списка инвентаризаций
	const requestForInventories = async () => {
		setIsPreloaderActive(true);
		await dispatch(fetchInventoriesAsyncAction());
		setIsPreloaderActive(false);
	};

	useEffect(() => {
		if (!inventories.data.length) {
			requestForInventories();
		}
	}, []);

  // Запись хлебных крошек
  useEffect(() => {
    setCrumbs([{name: 'Инвентаризация', url: AppRoute.Inventories.LIST}]);
  }, []);

	// Очистка значения имени бригадира
	useEffect(() => {
		dispatch(setEmployeeAction(''));
	}, []);
	
	// Активация модального окна с отменёнными в Бизнес.ру документами
	useEffect(() => {
		if (Object.values(changedInBusinessRuDocuments).some((item) => !!item.length)) {
			setChangedInBRuDocumentsModalActive(true);
		}
	}, [
    isInArchive,
    changedInBusinessRuDocuments.inventories.length,
    changedInBusinessRuDocuments.supplies.length,
    changedInBusinessRuDocuments.realizations.length,
    changedInBusinessRuDocuments.postings.length,
    changedInBusinessRuDocuments.charges.length,
    changedInBusinessRuDocuments.shiftings.length,
    changedInBusinessRuDocuments.factories.length
  ]);
  
  return (
    <>
			<Preloader isActive={isPreloaderActive} />
			
      {inventories.status === loadingStatus.LOADING && <div>Loading...</div>}
			<div style={{padding: "16px"}}>
				&nbsp;
				<Indicator type="danger"/> - Инвентаризация создана &nbsp;
				<Indicator type="warning"/> - Инвентаризация редактируется &nbsp;
				<Indicator type="success"/> - До проведения в Системе &nbsp;
				<Indicator type="blued"/> - После проведения в Системе
			</div>

			{
        !isInArchive && (
          <DocumentsListButtonsBlock documentType={DocumentType.INVENTORY_NEW}/>
        )
      }
			
			<table className={cl.usersTable}>
				<thead>
					<tr>
						<th>№</th>
						<th>Склад</th>
						<th>Дата инвентаризации</th>
						<th>Дата в Системе</th>
						<th>Последнее сохранение</th>
						<th/>
						<th/>
					</tr>
				</thead>
				<tbody>
					{
            sortedInventories.map((inv, index) =>
              <tr key={index.toString()}>
                {/* Номер документа */}
                <td>
                  <span>{inv.before.document_number}</span>
                  <br/>
                  {
                    inv.after && (
                      <span>{inv.after.document_number}</span>
                    )
                  }
                </td>
  
                <td>
                  <span>{inv.before.storage}</span>
                </td>
  
                {/* Дата инвентаризации */}
                <td>
                  <span>{getHumanFormattedDate(inv.before.date)}</span>
                  <br/>
                  {
                    inv.after && (
                      <span>{getHumanFormattedDate(inv.after.date)}</span>
                    )
                  }
                </td>
  
                {/* Дата в Системе */}
                <td>
                  <span>{inv.before.date_current}</span>
                  <br/>
                  {
                    inv.after && (
                      <span>{inv.after.date_current}</span>
                    )
                  }
                </td>
  
                {/* Последнее сохранение */}
                <td>
                  <span>{inv.before.last_edit}</span>
                  <br/>
                  {
                    inv.after && (
                      <span>{inv.after.last_edit}</span>
                    )
                  }
                </td>
  
                {/* Кнопка просмотра детальной информации */}
                <td>
                  <span
                    className={`${cl.icon} material-icons`}
                    title="Посмотреть"
                    onClick={() => handleShowInventoryDetailsButtonClick(inv)}
                  >
                    visibility
                  </span>
                </td>
  
                {/* Индикаторы */}
                <td>
                  <Indicator
                    type={inv.before.provided === 1 ? 'success' : inv.before.saved === 1 ? 'warning' : 'danger'}
                  />
                  <br/>
                  {
                    inv.after && (
                      <Indicator type="blued"/>
                    )
                  }
                </td>
              </tr>
            )
          }
				</tbody>
			</table>

			{
        !isInArchive && (
          <ChangedInBusinessRuDocumentsModal
            changedInBusinessRuDocuments={changedInBusinessRuDocuments}
            changedInBRuDocumentsModalActive={changedInBRuDocumentsModalActive}
            setChangedInBRuDocumentsModalActive={setChangedInBRuDocumentsModalActive}
          />
        )
      }
		</>
  );
};

export default InventoriesList;
