import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Switch from 'react-switch';

import {getSettings} from 'redux/slices/settings/selectors';
import {
  fetchSettingsAsyncAction,
  updateSettingAsyncAction
} from 'redux/slices/settings/settings-api-actions';

import cl from 'styles/components/cabinet/UsersTable.module.scss';

const AppSettings = () => {
  const dispatch = useDispatch();

  // Размер switch-элементов для разных экранов
  const [switchSize, setSwitchSize] = useState({height: 28, width: 56});

  const settings = useSelector(getSettings);

  // Изменение состояния настройки
	const changeSetting = async (checked, e, index) => {
    const id = settings[index].id;
    const settingStatus = checked === true ? 1 : 0;

    await dispatch(updateSettingAsyncAction({
      id,
      settingStatus
    }));
    dispatch(fetchSettingsAsyncAction());
	}

  useEffect(() => {
    if (window.screen.width <= 768) {
      setSwitchSize({height: 20, width: 40});
    }
  }, []);

  useEffect(() => {
    dispatch(fetchSettingsAsyncAction());
  }, []);

  return (
    <table className={cl.usersTable}>
			<thead>
				<tr>
					<th>Параметр настройки</th>
					<th>Состояние</th>
				</tr>
			</thead>
			<tbody>
				{
					settings.map((setting, index) =>
						<tr key={index.toString()}>
              <td>
								<span>{setting.description}</span>
							</td>
							<td>
								<Switch
									onChange={changeSetting}
									checked={setting.status === 1}
									id={index.toString()}
									onColor="#648220"
									offColor="#b0b1b3"
									height={switchSize.height}
									width={switchSize.width}
								/>
							</td>
						</tr>
					)
				}
			</tbody>
		</table>
  );
};

export default AppSettings;
