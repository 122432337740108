export const DocumentType = {
  CHARGE: 'charge',
  POSTING: 'posting',
  SUPPLY: 'supply',
  SHIFTING: 'shifting',
  INVENTORY: 'inventory',
  INVENTORY_NEW: 'inventory_new',
  REALIZATION: 'realization',
  FACTORY: 'factory',
  REMAINS: 'remains',
  OFFER: 'offer'
};

export const DocumentTypeNameMap = {
  [DocumentType.CHARGE]: 'Списание',
  [DocumentType.POSTING]: 'Оприходование',
  [DocumentType.SUPPLY]: 'Поступление',
  [DocumentType.SHIFTING]: 'Перемещение',
  [DocumentType.INVENTORY]: 'Инвентаризация',
  [DocumentType.REALIZATION]: 'Отгрузка',
  [DocumentType.FACTORY]: 'Производство',
  [DocumentType.REMAINS]: 'Начальные остатки'
};
