import {Fragment, useContext} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import clsx from 'clsx';

import CustomSearchSelect from 'components/custom-search-select/custom-search-select';

import {
  fetchShiftingTargetAddressesInitialAmountsAsyncAction,
  setNewDocumentInitialAmountsAsyncAction
} from 'redux/slices/documents/documents-api-actions';
import {setDocumentItemsAction} from 'redux/slices/documents/documents';
import {
  getDestinationStorage,
  getDocument,
  getNewDocumentDate,
  getProvidedStatus,
  getShiftingTargetStorageAmounts
} from 'redux/slices/documents/selectors';

import {AppContext} from 'providers/AppContextProvider';
import {getUser} from 'helpers/storage';
import {sortStringValues} from 'helpers/utils';

import styles from './styles.module.scss';
import toastMessageStyles from 'styles/components/toast-message.module.scss';

const ShiftingTableRowExperimental = ({
  line,
  lineIndex,
  goods,
  groups,
  addresses,
  deleteLine
}) => {
  const dispatch = useDispatch();

  const {alert} = useContext(AppContext);

  // Проверка уровня пользователя
  const canUserUpdate = getUser().accessLevel > 1;

  const document = useSelector(getDocument);
  const documentDate = document.documentDate;
  const newDocumentDate = useSelector(getNewDocumentDate);
  const provided = !!useSelector(getProvidedStatus);
  const storage = document.storage;
  const destinationStorage = useSelector(getDestinationStorage);
  const documentItems = document.documentItems;
  const itemsNames = structuredClone(documentItems).map((itm) => itm.name);
  const shiftingTargetStorageAmounts = useSelector(getShiftingTargetStorageAmounts);

  const getDisplayedAddresses = (positionAddress) => {
    // Уже выбранный адрес в строке (для исключения его из выпадающего списка)
    const substringAddresses = line.userPositions
      .filter((item) => item.address === positionAddress && item.address_after)
      .map((item) => item.address_after);

    const displayedAddresses = addresses
      .filter((address) => address.value !== positionAddress && !substringAddresses.includes(address.value));

    return displayedAddresses;
  };

  // Получить список наименований для селекта для вновь добавленной строки (в самом низу)
  const filterSelectComponentOptions = (selectComponentName) => {
    // Отсеиваем те товары, которые уже есть в инвентаризации
    const filteredPlants = goods
      .filter((plant) => !itemsNames.includes(plant.name))
      .sort((a, b) => {
        const res = sortStringValues(a.value, b.value);
        return res;
      });
    const filteredArticles = filteredPlants.map((plant) => ({
      id: plant.b_group_id,
      name: plant.article,
      value: plant.article
    }));

    // Если в новой строке группа уже выбрана, то фильтруем список для селекта ещё и по группе
    if (line.b_group && groups.length) {
      const groupId = groups.find((gr) => gr.name === line.b_group).id;
      const filteredByGroupPlants = filteredPlants
        .filter((plant) => plant.b_group_id === groupId)
        .sort((a, b) => {
          const res = sortStringValues(a.value, b.value);
          return res;
        });
      const filteredByGroupArticles = filteredByGroupPlants.map((plant) => ({
        id: plant.b_group_id,
        name: plant.article,
        value: plant.article
      }));

      switch(selectComponentName) {
        case 'name':
          return filteredByGroupPlants;
        case 'article':
          return filteredByGroupArticles;
        default:
          return;
      };
    }

    switch(selectComponentName) {
      case 'name':
        return filteredPlants;
      case 'article':
        return filteredArticles;
      default:
        return;
    };
  };

  // Метод изменения наименования и артикула (и группы, если не выбрана)
  const changeValue = (index, selectElementName, value) => {
    const copiedLines = structuredClone(documentItems)
      .map((item) => ({
        ...item,
        storage,
        toStorage: destinationStorage
      }));

    copiedLines[index][selectElementName] = value;

    if (selectElementName === 'name') {
      const plant = goods.find((plant) => plant.name === value);
      const plantArticle = plant.article;
      const businessGroupName = plant.b_group;
      const plantBruGoodId = plant.b_good_id;

      copiedLines[index].article = plantArticle;
      copiedLines[index].b_group = businessGroupName;
      copiedLines[index].good_id = plantBruGoodId;
    }
    if (selectElementName === 'article') {
      const plant = goods.find((plant) => plant.article === value);
      const plantName = plant.name;
      const businessGroupName = plant.b_group;
      const plantBruGoodId = plant.b_good_id;

      copiedLines[index].name = plantName;
      copiedLines[index].b_group = businessGroupName;
      copiedLines[index].good_id = plantBruGoodId;
    }

    dispatch(setDocumentItemsAction(copiedLines));

    // Если товар выбран (наименование определено), то запрашиваем данные с полей
    if (copiedLines[index].name) {
      dispatch(setNewDocumentInitialAmountsAsyncAction({
        name: copiedLines[index].name,
        storage,
        date: newDocumentDate ? `${newDocumentDate}${documentDate.slice(-9)}` : documentDate
      }));

      // Если склад списания и склад назначения отличаются, то запрашиваем начальные остатки
      // выбранного товара на складе назначения для определения итогового кол-ва
      if (storage !== destinationStorage) {
        dispatch(fetchShiftingTargetAddressesInitialAmountsAsyncAction({
          name: copiedLines[index].name,
          storage: destinationStorage,
          date: newDocumentDate ? `${newDocumentDate}${documentDate.slice(-9)}` : documentDate
        }));
      }
    }
  };

  // Обработчик выбора адреса
  const handleAddressChange = (positionIndex, selectedAddress) => {
    if (documentItems[lineIndex].name) {
      const copiedLines = structuredClone(documentItems);
      const anotherStorageInitialAmount = shiftingTargetStorageAmounts.find((item) => item.address === selectedAddress)
        ? Number(shiftingTargetStorageAmounts.find((item) => item.address === selectedAddress).amount)
        : 0;
      const selectedAddressAmountsItem = copiedLines[lineIndex].amounts.find((item) => item.address === selectedAddress);
      const selectedAddressAmountsItemInitialValue = selectedAddressAmountsItem ? Number(selectedAddressAmountsItem.amount) : 0;
      const selectedAddressAnotherStringOutgoingAmount = copiedLines[lineIndex].userPositions.find((item) => item.address === selectedAddress)
        ? (copiedLines[lineIndex].userPositions.find((item) => item.address === selectedAddress).amount_current
            ? Number(copiedLines[lineIndex].userPositions.find((item) => item.address === selectedAddress).amount_current)
            : 0)
        : 0;

      // ИЗМЕНИТЬ КОЛ-ВО (amount) при:
      // 1) Выборе адреса
      if (!copiedLines[lineIndex].userPositions[positionIndex].address_after && selectedAddress) {
        const currentAmount = copiedLines[lineIndex].userPositions[positionIndex].amount_current ? Number(copiedLines[lineIndex].userPositions[positionIndex].amount_current) : 0;
        copiedLines[lineIndex].userPositions[positionIndex].amount = anotherStorageInitialAmount + selectedAddressAmountsItemInitialValue + currentAmount - selectedAddressAnotherStringOutgoingAmount;
      }
      // 2) Изменении адреса
      if (copiedLines[lineIndex].userPositions[positionIndex].address_after && selectedAddress && copiedLines[lineIndex].userPositions[positionIndex].address_after !== selectedAddress) {
        const amountAfter = copiedLines[lineIndex].userPositions[positionIndex].amount_after ? Number(copiedLines[lineIndex].userPositions[positionIndex].amount_after) : 0;
        copiedLines[lineIndex].userPositions[positionIndex].amount = anotherStorageInitialAmount + selectedAddressAmountsItemInitialValue + amountAfter - selectedAddressAnotherStringOutgoingAmount;
      }
      // 3) Удалении адреса
      if (copiedLines[lineIndex].userPositions[positionIndex].address_after && !selectedAddress) {
        copiedLines[lineIndex].userPositions[positionIndex].amount = 0;
        copiedLines[lineIndex].userPositions[positionIndex].amount_after = 0;
        copiedLines[lineIndex].userPositions[positionIndex].amount_current = 0;
      }

      // Установить новое значение адреса назначения
      copiedLines[lineIndex].userPositions[positionIndex].address_after = selectedAddress;
      copiedLines[lineIndex].userPositions[positionIndex].address_current = copiedLines[lineIndex].userPositions[positionIndex].address;

      // Рассчитать и установить общее перемещаемое кол-во
      const totalAmountAfterPositions = copiedLines[lineIndex].userPositions
        .reduce((res, item) => (item.address_after ? {
            ...res,
            [item.address_after]: Number(item.amount ? item.amount : 0)
          } : res), 0);
      const totalAmountAfter = Object.values(totalAmountAfterPositions).reduce((res, item) => res + item, 0);
      copiedLines[lineIndex].totalAmount_after = totalAmountAfter;

      dispatch(setDocumentItemsAction(copiedLines));
    } else {
      toast.error('Выберите наименование', {
        position: 'bottom-right',
        className: toastMessageStyles['toast-message']
      });

      return true;
    }
  };

  // Изменение позиций
  const handlePositionDataInputChange = (indexes, value) => {
    if ((/\D+/).test(value)) {
      return;
    }

    if (!!documentItems[indexes.lineIndex].name) {

      const copy = structuredClone(documentItems);
      const selectedValue = Number(value) > 0 ? Number(value) : 0;

      // Рассчитать и установить кол-во в текущей строке
      const targetAddress = copy[indexes.lineIndex].userPositions[indexes.positionIndex].address_after;
      const anotherStorageInitialAmount = shiftingTargetStorageAmounts.find((item) => item.address === targetAddress)
        ? Number(shiftingTargetStorageAmounts.find((item) => item.address === targetAddress).amount)
        : 0;
      const targetAddressInitialAmount = targetAddress && copy[indexes.lineIndex].amounts
        .find((item) => item.address === targetAddress)
          ? Number(copy[indexes.lineIndex].amounts.find((item) => item.address === targetAddress).amount)
          : 0;
      const totalSourceAddressStringsAmount = targetAddress
        ? copy[indexes.lineIndex].userPositions
          .filter((item) => item.address_current === targetAddress)
          .reduce((res, item) => res + Number(item.amount_current ? Number(item.amount_current) : 0), 0)
        : 0;
      const totalAnotherAddressesTargetAmount = copy[indexes.lineIndex].userPositions
        .filter((item, index) => item.address_after === targetAddress && index !== indexes.positionIndex)
        .reduce((res, item) => res + Number(item.amount_after ? Number(item.amount_after) : 0), 0);
      const displayedValue = anotherStorageInitialAmount + targetAddressInitialAmount + selectedValue + totalAnotherAddressesTargetAmount - totalSourceAddressStringsAmount;

      // Рассчитать и установить кол-во во всех строках с таким же адресом назначения, как и исх. адрес в текущей строке
      const sourceAddress = copy[indexes.lineIndex].userPositions[indexes.positionIndex].address;
      const sourceAddressInitialAmount = Number(copy[indexes.lineIndex].amounts.find((item) => item.address === sourceAddress).amount);
      const totalTargetAddressStringsAmount = copy[indexes.lineIndex].userPositions
        .filter((item) => item.address_after === sourceAddress)
        .reduce((res, item) => res + Number(item.amount_current ? Number(item.amount_current) : 0), 0);
      const totalSourceAddressAnotherStringsAmount = copy[indexes.lineIndex].userPositions
        .filter((item, index) => item.address_current === sourceAddress && index !== indexes.positionIndex)
        .reduce((res, item) => res + Number(item.amount_current ? Number(item.amount_current) : 0), 0);
      const anotherPositionAmount = sourceAddressInitialAmount - selectedValue - totalSourceAddressAnotherStringsAmount + totalTargetAddressStringsAmount;
      copy[indexes.lineIndex].userPositions.forEach((position, positionIndex) => {
        if (position.address_after === sourceAddress) {
          copy[indexes.lineIndex].userPositions[positionIndex].amount = anotherPositionAmount;
        }
        // Рассчитать и установить кол-во во всех строках с таким же адресом назначения, как и адрес назначения в текущей строке
        if (targetAddress && position.address_after === targetAddress) {
          copy[indexes.lineIndex].userPositions[positionIndex].amount = displayedValue;
        }
      });

      copy[indexes.lineIndex].userPositions[indexes.positionIndex].amount = displayedValue;
      copy[indexes.lineIndex].userPositions[indexes.positionIndex].amount_current = selectedValue;
      copy[indexes.lineIndex].userPositions[indexes.positionIndex].amount_after = selectedValue;

      // Рассчитать и установить общее перемещаемое кол-во
      const totalAmountAfterPositions = copy[indexes.lineIndex].userPositions
        .reduce((res, item) => (item.address_after ? {
            ...res,
            [item.address_after]: Number(item.amount ? item.amount : 0)
          } : res), 0);
      const totalAmountAfter = Object.values(totalAmountAfterPositions).reduce((res, item) => res + item, 0);
      copy[indexes.lineIndex].totalAmount_after = totalAmountAfter;

      dispatch(setDocumentItemsAction(copy));
    } else {
      const copy = structuredClone(documentItems);

      copy[indexes.lineIndex].userPositions[indexes.positionIndex].amount = 0;
      copy[indexes.lineIndex].userPositions[indexes.positionIndex].amount_current = 0;
      copy[indexes.lineIndex].userPositions[indexes.positionIndex].amount_after = 0;

      dispatch(setDocumentItemsAction(copy));

      toast.error('Выберите наименование', {
        position: 'bottom-right',
        className: toastMessageStyles['toast-message']
      });
    }
  };

  // Удаление подстроки (поле/площадка)
  const deleteSubstring = (positionIndex) => {
    const copiedLines = structuredClone(documentItems);

    copiedLines[lineIndex].userPositions.splice(positionIndex, 1);

    dispatch(setDocumentItemsAction(copiedLines));
  };

  // Обработчик удаления подстроки (поле/площадка)
  const handleDeleteSubstringButtonClick = (positionIndex) => {
    const handler = () => {
      deleteSubstring(positionIndex);
    };

    alert('Удалить площадку?', 'danger', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  const handleAddNewSubstringButtonClick = (position, positionIndex) => {
    const newSubstring = {
      address: position.address,
      amount: 0,
      amount_current: 0,
      amount_after: 0,
      address_after: '',
      address_current: position.address_current
    };
    const updatedUserPositions = [
      ...documentItems[lineIndex].userPositions.slice(0, positionIndex + 1),
      newSubstring,
      ...documentItems[lineIndex].userPositions.slice(positionIndex + 1)
    ];
    const updatedLines = structuredClone(documentItems);

    updatedLines[lineIndex].userPositions = updatedUserPositions;

    dispatch(setDocumentItemsAction(updatedLines));
  };

  // Для правильной отрисовки ячеек "Кол-во" и "Адрес" в "Остатки до перемещения:"
  // и кнопки добавления новой подстроки для адреса
  const indexesForAddNewSubstringButton = [];
  const indexesForExistingAddressesCell = [];
  let currentAddress = null;
  line.userPositions.forEach((position, index) => {
    if (position.address !== currentAddress) {
      indexesForExistingAddressesCell.push(index);
      if (index !== 0) {
        indexesForAddNewSubstringButton.push(index - 1);
      }
      currentAddress = position.address;
    }
  });

  return (
    <Fragment>
      {
        line.userPositions.map((position, positionIndex, userPositions) => (
          <tr key={positionIndex}>
            {
              positionIndex === 0 && (
                <>
                  {/* Ячейка № п/п */}
                  <td rowSpan={userPositions.length}>{lineIndex + 1} ({line.good_id})</td>

                  {/* Ячейка "Наименование" */}
                  <td
                    rowSpan={userPositions.length}
                  >
                    {
                      !provided && !line.name ? (
                        <CustomSearchSelect
                          inputName='name'
                          defaultValue={line.name}
                          options={filterSelectComponentOptions('name')}
                          onChange={(value) => changeValue(lineIndex, 'name', value)}
                          placeholder='Наименование'
                          searchMode={true}
                        />
                      ) : (
                        line.name
                      )
                    }
                  </td>

                  {/* Ячейка "Артикул" */}
                  <td rowSpan={userPositions.length}>
                    {
                      !provided && !line.article ? (
                        <CustomSearchSelect
                          inputName='article'
                          defaultValue={line.article}
                          options={filterSelectComponentOptions('article')}
                          onChange={(value) => changeValue(lineIndex, 'article', value)}
                          placeholder='Артикул'
                          searchMode={true}
                        />
                      ) : (
                        line.article
                      )
                    }
                  </td>

                  {/* Ячейка "Группа" */}
                  <td rowSpan={userPositions.length}>
                    {
                      !provided && !line.name ? (
                        <CustomSearchSelect
                          inputName='b_group'
                          defaultValue={line.b_group}
                          options={groups}
                          onChange={(value) => changeValue(lineIndex, 'b_group', value)}
                          placeholder='Группа'
                          searchMode={true}
                        />
                      ) : (
                        line.b_group
                      )
                    }
                  </td>

                  {/* Ячейка общего кол-ва товара на выбранном складе "Всего" */}
                  <td rowSpan={userPositions.length}>
                    {new Intl.NumberFormat('ru-RU').format(line.totalAmount)}
                  </td>
                </>
              )
            }

            {/* Кол-во и адрес из БД - реальное кол-во на полях/площадках */}
            {
              indexesForExistingAddressesCell.includes(positionIndex) && (
                <>
                  <td
                    rowSpan={userPositions.filter((item) => position.address === item.address).length}
                  >
                    {new Intl.NumberFormat('ru-RU').format(line.amounts.find((amountsItem) => amountsItem.address === position.address) ? line.amounts.find((amountsItem) => amountsItem.address === position.address).amount : 0)}
                  </td>
                  <td
                    rowSpan={userPositions.filter((item) => position.address === item.address).length}
                  >
                    {line.amounts.find((amountsItem) => amountsItem.address === position.address) ? line.amounts.find((amountsItem) => amountsItem.address === position.address).address : ''}
                  </td>
                </>
              )
            }

            {/* Ячейка ввода перемещаемого кол-ва */}
            <td>
              {
                !provided && canUserUpdate ? (
                  <input
                    type='text'
                    name='amount_current'
                    placeholder='Кол-во'
                    value={position.amount_current ? position.amount_current : 0}
                    onChange={(evt) => handlePositionDataInputChange({lineIndex, positionIndex}, evt.currentTarget.value)}
                    autoComplete='off'
                  />
                ) : (
                  new Intl.NumberFormat('ru-RU').format(
                    position.amount_current ?? 0
                  )
                )
              }
            </td>

            {/* Ячейка ввода оприходуемого кол-ва */}
            <td>
              {
                !provided && canUserUpdate ? (
                  <input
                    type='text'
                    name='amount_after'
                    placeholder='Кол-во'
                    value={position.amount_after ? position.amount_after : 0}
                    onChange={(evt) => handlePositionDataInputChange({lineIndex, positionIndex}, evt.currentTarget.value)}
                    autoComplete='off'
                  />
                ) : (
                  new Intl.NumberFormat('ru-RU').format(
                    position.amount_after ?? 0
                  )
                )
              }
            </td>

            {/* Ячейка выбора адреса прихода */}
            <td className={styles.shiftingTargetAddress}>
              <div className={styles.subLine}>
                {
                  !provided ?
                  // !line.amounts
                  //   .filter((amountsItem) => amountsItem.address !== '')
                  //   .find((amountsItem) => amountsItem.address === position.address_after) ?
                        (
                          <CustomSearchSelect
                            inputName='address_after'
                            defaultValue={position.address_after}
                            options={getDisplayedAddresses(position.address)}
                            onChange={(value) => handleAddressChange(positionIndex, value)}
                            placeholder='Адрес'
                          />
                        ) : (
                          position.address_after
                        )
                }
              </div>
            </td>

            {/* Ячейка "Всего:" в "Остатки после перемещения" */}
            {
              positionIndex === 0 && (
                <td rowSpan={userPositions.length}>
                  {new Intl.NumberFormat("ru-RU").format(
                    line.totalAmount_after ? Number(line.totalAmount_after) : 0
                  )}
                </td>
              )
            }

            <td>
              {new Intl.NumberFormat("ru-RU").format(position.amount ? Number(position.amount) : 0)}
            </td>

            {/* Ячейка адреса прихода */}
            <td>
              <div className={clsx(styles.subLine, {
                [styles.stretch]: !!position.address_after
              })}>
                {position.address_after}
                <div className={styles.buttonsWrapper}>
                  {
                    (indexesForAddNewSubstringButton.includes(positionIndex) || positionIndex === userPositions.length - 1) && !provided && (
                      <td>
                        <span
                          className='material-icons'
                          onClick={() => handleAddNewSubstringButtonClick(position, positionIndex)}
                          title='Добавить адрес'
                        >
                          add
                        </span>
                      </td>
                    )
                  }
                  {
                    userPositions.filter((item) => item.address === position.address).length > 1 && !provided && (
                      <span
                        className='material-icons'
                        onClick={() => handleDeleteSubstringButtonClick(positionIndex)}
                        title='Удалить площадку'
                      >
                        delete_outline
                      </span>
                    )
                  }
                </div>
              </div>
            </td>

            {/* Кнопка удаления товара из документа */}
            {
              positionIndex === 0 && !provided && (
                <td className='iconed' rowSpan={userPositions.length}>
                  <span
                    className='roundIcon material-icons'
                    onClick={() => deleteLine(lineIndex)}
                    title='Удалить строку'
                  >
                    delete_outline
                  </span>
                </td>
              )
            }
          </tr>
        ))
      }
    </Fragment>
  );
};

export default ShiftingTableRowExperimental;
