import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import {toast} from 'react-toastify';

import InputDate from 'components/input/InputDate';

import {AppRoute} from 'constants/routes';

import cl from 'styles/components/cabinet/UsersTable.module.scss';
import styles from './supplies-table-styles.module.scss';

const SuppliesTable = ({alert, userLevel, Supplies}) => {
	const navigate = useNavigate();
	
	// Локальные данные
	const [supplies, setSupplies] = useState(Supplies);
	
	// Периоды фильтрации
	const [period, setPeriod] = useState({before: '', after: ''});
	
	// Строка поиска
	const [search, setSearch] = useState('');
	
	// Изменение периода фильтрации
	const changePeriod = (name, value) => {
		const copy = Object.assign({}, period);
		copy[name] = value;
		setPeriod(copy);
	}
	
	// Удаление архива
	const remove = (index) => {
		const copy = structuredClone(supplies);
		
		alert('Удалить архив?', 'danger', 0, [
			{
				text: 'Да',
				handler: function(){
					axios.delete('businessRu/removeSupplyArchive/' + copy[index].supply_id).then(() => {
						alert('', 'default', 1);
						copy.splice(index, 1);
						setSupplies(copy);
					}).catch(() => {
						alert('Ошибка удаления', 'danger');
					});
				},
				needToCloseImmediately: true
			},
			{
				text: 'Нет',
				handler: function(){
					alert('', 'default', 1);
				}
			}
		]);
	}

	// Изменение локального состояния
	useEffect(() => {
		setSupplies(Supplies);
	}, [Supplies]);
	
	// Фильтрация
	useEffect(() => {
		let filtered = Supplies;
		if(period.after !== '')
			filtered = filtered.filter(form => new Date(form.document_date_filter).valueOf() >= new Date(period.after + ' 00:00').valueOf());
		if(period.before !== '')
			filtered = filtered.filter(form => new Date(form.document_date_filter).valueOf() <= new Date(period.before + ' 23:59').valueOf());
		if(search !== '')
			filtered = filtered.filter(form => form.comment.toLowerCase().includes(search.toLowerCase()));
		
		setSupplies(filtered);
	}, [period, search]);
	
	return (
		<table className={cl.usersTable}>
			<thead>
				<tr>
					<th colSpan='2'>
						<div style={{display: 'inline-block', width: '45%'}}>
							<InputDate name='after' currentValue={period.after} changeHandler={changePeriod}/>
						</div>
						<div style={{display: 'inline-block', width: '4%'}}>—</div>
						<div style={{display: 'inline-block', width: '45%'}}>
							<InputDate name='before' currentValue={period.before} changeHandler={changePeriod}/>
						</div>
					</th>
					<th colSpan={userLevel === 3 ? '2' : ''}>
						<input
							type="search"
							name="commentSearch"
							value={search}
							title={search}
							placeholder="Поиск по примечанию"
							onChange={(e) => setSearch(e.target.value)}
						/>
					</th>
				</tr>
				<tr>
					<th>№</th>
					<th>Дата</th>
					<th>Склад</th>
					<th>Статус в Бизнес.ру</th>
					<th/>
					{userLevel === 3 && <th/>}
				</tr>
			</thead>
			<tbody>
				{
					supplies.map((suppliesListItem, index) =>
						<tr key={index.toString()}>
							<td>
								<span>{suppliesListItem.document_number}</span>
							</td>
							<td>
								<span>{suppliesListItem.document_date}</span>
							</td>
							<td>
								<span>{suppliesListItem.storage}</span>
							</td>
							<td>
								{
									suppliesListItem.supplyChanged &&
									!suppliesListItem.supplyDeleted &&
									!suppliesListItem.supplyDenied && (
										<span className={styles.supplyDeniedMark}>Изменено в Бизнес.ру</span>
									)
								}
								{
									suppliesListItem.supplyDeleted && (
										<span className={styles.supplyDeniedMark}>Удалено в Бизнес.ру</span>
									)
								}
								{
									suppliesListItem.supplyDenied &&
									!suppliesListItem.supplyDeleted &&
									!suppliesListItem.supplyChanged && (
										<span className={styles.supplyDeniedMark}>Отменено в Бизнес.ру</span>
									)
								}
								{
									suppliesListItem.supplyDenied &&
									!suppliesListItem.supplyDeleted &&
									suppliesListItem.supplyChanged && (
										<span className={styles.supplyDeniedMark}>Отменено / Изменено в Бизнес.ру</span>
									)
								}
								{
									!suppliesListItem.supplyChanged &&
									!suppliesListItem.supplyDeleted &&
									!suppliesListItem.supplyDenied && (
										<>✔️</>
									)
								}
							</td>
							<td>
								<span
									className={cl.icon + ' material-icons'}
									title="Посмотреть"
									onClick={() => navigate(`${AppRoute.Archive.SUPPLIES}/${suppliesListItem.supply_id}`)}
								>
									visibility
								</span>
							</td>
							{
								userLevel === 3 && (
									<td>
										<span
											className={cl.icon + ' ' + cl.remove + ' material-icons'}
											title="Удалить"
											onClick={() => remove(index)}
										>
											close
										</span>
									</td>
								)
							}
						</tr>
					)
				}
			</tbody>
		</table>
	);
};

export default SuppliesTable;
