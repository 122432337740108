import {useEffect, useState} from 'react';
import clsx from 'clsx';

import CustomSelect from 'components/CustomSelect';
import CustomSearchSelect from 'components/custom-search-select/custom-search-select';

import cl from 'styles/components/Filter.module.scss';

const InventoryFilter = ({
	isOpen = false,
	data,
	setData,
	dataBackup,
	setDataBackup,
	setIsFilterActive,
	sortFn = false,
	maps = [],
	fieldList = false,
	onlyOne = ''
}) => {
	const classes = clsx(cl.filterContainer, {
		[cl.open]: isOpen
	});

	// Состояние значений фильтра
	const [filters, setFilters] = useState([]);

  // Список площадок для выпадающего списка
  const fields = Array.from(new Set(structuredClone(fieldList)
    .map((field) => field.name.match(/.+(?=\.)/)[0])))
    .sort((a, b) => {
      if ((/^\d+/).test(a) && (/^\d+/).test(b)) {
        if (Number(a.match(/^\d+/)[0]) === Number(b.match(/^\d+/)[0])) {
          return a.match(/\D+/)[0].localeCompare(b.match(/\D+/)[0], 'ru', {sensitivity: 'base'});
        } else {
          return Number(a.match(/^\d+/)[0]) - Number(b.match(/^\d+/)[0]);
        }
      } else {
        return a.localeCompare(b, 'ru', { sensitivity: 'base' });
      }
    })
    .map((fieldName) => ({
      name: fieldName,
      value: fieldName
    }));

  // Список адресов для выпадающего списка
  const selectedField = filters.find((filter) => filter.obj === 'field'); // Если выбрана площадка
  const addresses = structuredClone(fieldList)
    .sort((a, b) => {
      if ((/^\d+/).test(a.name) && (/^\d+/).test(b.name)) {
        if (Number(a.name.match(/^\d+/)[0]) === Number(b.name.match(/^\d+/)[0])) {
          return a.name.match(/\D+/)[0].localeCompare(b.name.match(/\D+/)[0], 'ru', {sensitivity: 'base'});
        } else {
          return Number(a.name.match(/^\d+/)[0]) - Number(b.name.match(/^\d+/)[0]);
        }
      } else {
        return a.name.localeCompare(b.name, 'ru', { sensitivity: 'base' });
      }
    })
    .filter((address) => selectedField ? address.name.startsWith(selectedField.value) : address);

	// Бэкап данных
	useEffect(() => {
		let copy = structuredClone(data);

		setDataBackup(copy);
	}, [data.length]);
	
	// Изменение данных в бэкапе при изменении снаружи
	useEffect(() => {
		if (!filters.length) {
			return;
		}
		
		const copy = structuredClone(dataBackup);

		copy.forEach((cp, ind) => {
			data.forEach(dt => {
				if (dt.name === cp.name) {
					dt.datas.forEach((datas_dt, _ind_dt) => {
						copy[ind].datas.forEach((cp_dt, cp_ind) => {
							if (datas_dt.address_uchet === cp_dt.address_uchet) {
								copy[ind].datas[cp_ind] = datas_dt;
							}
						});
					});

					copy[ind].total_fact = dt.total_fact;

					return false;
				}
			});
		});

		setDataBackup(copy);
	}, [data]);

	// Запись отфильтрованных значений в основной массив
	useEffect(() => {
		// Если нет фильтров, возврат старых значений
		if (!filters.length && dataBackup.length > 0) {
			setData(dataBackup);
			setIsFilterActive(true);

			return;
		}

		// Если нет начальных данных, полезут ошибки
		if (!dataBackup.length) {
			return;
		}

		// Фильтрация
		let newData = structuredClone(dataBackup);

		filters.forEach((item) => {
			// Выполнение сортировки
			if (sortFn !== false && (item.obj === 'row' || item.obj === 'name')) {
				sortFn(item.obj);
			}

			// Выполнение фильтрации
			const filtered = newData.filter((dt) => {
				// Фильтрация строковых значений
				if (
					item.obj === 'field_with_map' ||
					item.obj === 'row' ||
					item.obj === 'b_group' ||
					item.obj === 'name' ||
					item.obj === 'article' ||
					item.obj === 'storage'
				) {
					if (dt[item.obj] === null) {
						return '';
					}

					dt[item.obj] = dt[item.obj].toString();

					return dt[item.obj].toLowerCase().includes(item.value.toLowerCase());
				} else if (item.obj === 'map_id') { // Фильтрация селектов
					return dt[item.obj] === item.value;
				} else if (item.obj === 'field' || item.obj === 'address') { // Фильтрация по площадке или адресу
          const updatedDatas = dt.datas.filter((pos) => {
						return pos.address_uchet.includes(item.value) || pos.address_fact.includes(item.value);
					});

					return updatedDatas.length > 0;
				} else { // Фильтрация даты
					if (item.obj === 'date_after') {
						return new Date(dt.date).valueOf() >= new Date(dateFormat(item.value)).valueOf();
					}
					if (item.obj === 'date_before') {
						return new Date(dt.date).valueOf() <= new Date(dateFormat(item.value)).valueOf();
					}
					return dt;
				}
			});

			// Запись отфильтрованных данных
			setData(filtered);
			newData = filtered;
			setIsFilterActive(true);
		});
	}, [filters]);

	// Установка значений фильтра
	const setFilter = (index = 0, obj, value) => {
    let updatedFilters = structuredClone(filters);

    if (obj === 'field') {
      const isAddressSelected = filters.find((filter) => filter.obj === 'address');

      if (isAddressSelected) {
        updatedFilters = filters.filter((filter) => filter.obj !== 'address');
      }
    }

		if (!value.toString().length || value === '__.__.____ __:__') {
			// Удаление строки с фильтром, если значение не задано
			const delFilter = updatedFilters.filter((item) => item.obj !== obj);
			setFilters(delFilter);
		} else {
			// При каждом изменении значения фильтра - удаление строки и добавление нового значения
			const addFilter = updatedFilters.filter((item) => item.obj !== obj);
			addFilter.push({obj, value});
			setFilters(addFilter);
		}
	}

	// Сброс фильтра
	const eraseFilter = () => {
		setFilters([]);
	}

	// Форматирование даты
	const dateFormat = (dt) => {
		if (dt == null || dt === '') {
			return '';
		}
		
		dt = dt.split(' ');
		dt[0] = dt[0].split('.');

		if (dt[0][0] === '0000') {
			return '';
		} else {
			return `${dt[0][2]}-${dt[0][1]}-${dt[0][0]} ${dt[1]}`;
		}
	}

	return (
		<div className={classes}>
			<span className={cl.heading}>Фильтры</span>
			<span
				className={`${cl.filterOff} material-icons`}
				onClick={eraseFilter}
				title="Сбросить фильтр"
			>
				filter_alt_off
			</span>

			{
				maps.length > 0 && (
					<CustomSelect
						options={maps}
						name='map_id'
						changeHandler={setFilter}
						currentIndex='0'
						placeholder='Карта'
						currentValue={
              filters.find(fl => fl.obj === 'map_id') !== undefined
                ? filters.find(fl => fl.obj === 'map_id').value
                : ''
            }
					/>
				)
			}

			{
				fieldList.length > 0 && (
          <CustomSearchSelect
            inputName='field'
            defaultValue={
              filters.find((fl) => fl.obj === 'field') !== undefined
                ? filters.find((fl) => fl.obj === 'field').value
                : ''
            }
            options={fields}
            onChange={(value) => setFilter(0, 'field', value)}
            placeholder='Площадка / поле'
            searchMode={true}
          />
				)
			}

      {
				fieldList.length > 0 && (
          <CustomSearchSelect
            inputName='address'
            defaultValue={
              filters.find((fl) => fl.obj === 'address') !== undefined
                ? filters.find((fl) => fl.obj === 'address').value
                : ''
            }
            options={addresses}
            onChange={(value) => setFilter(0, 'address', value)}
            placeholder='Адрес целиком'
            searchMode={true}
          />
				)
			}

			{
				(onlyOne === 'b_group' || onlyOne === '') && (
					<input
						type="text"
						onChange={(e) => setFilter(0, 'b_group', e.target.value)}
						placeholder="Группа"
						value={
              filters.find((fl) => fl.obj === 'b_group') !== undefined
                ? filters.find((fl) => fl.obj === 'b_group').value
                : ''
            }
					/>
				)
			}

			{
				(onlyOne === 'article' || onlyOne === '') && (
					<input
						type="text"
						onChange={(e) => setFilter(0, 'article', e.target.value)}
						placeholder="Артикул"
						value={
              filters.find((fl) => fl.obj === 'article') !== undefined
                ? filters.find((fl) => fl.obj === 'article').value
                : ''
            }
					/>
				)
			}

			{
				(onlyOne === 'name' || onlyOne === '') && (
					<input
						type="text"
						onChange={(e) => setFilter(0, 'name', e.target.value)}
						placeholder="Наименование"
						value={
              filters.find((fl) => fl.obj === 'name') !== undefined
                ? filters.find((fl) => fl.obj === 'name').value
                : ''
            }
					/>
				)
			}
		</div>
	);
};

export default InventoryFilter;
