import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {getChangedInBusinessRuDocuments} from 'redux/slices/documents/selectors';
import {fetchChangedInBusinessRuDocumentsAsyncAction} from 'redux/slices/documents/documents-api-actions';
import {getSettings} from 'redux/slices/settings/selectors';

import DocumentsListItem from 'components/documents-list-item/documents-list-item';
import Preloader from 'components/preloader/SpinPlaceholderComponent';
import ChangedInBusinessRuDocumentsModal from 'components/changed-documents-modal/changed-documents-modal';

import {DocumentTypeMap} from 'components/changed-documents-modal/constants';

import styles from './styles.module.scss';

const DocumentsListItems = ({
  documentType,
  documentsListItems
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const pathName = location.pathname;
  const ARCHIVE_LOCATION_REG_EXP = /archive/;
  const isInArchive = ARCHIVE_LOCATION_REG_EXP.test(pathName);

  // Активность прелоадера
  const [isPreloaderActive, setIsPreloaderActive] = useState(false);

  // Модальное окно с изменёнными в Бизнес.ру документами
	const [changedInBRuDocumentsModalActive, setChangedInBRuDocumentsModalActive] = useState(false);

  const changedInBusinessRuDocuments = useSelector(getChangedInBusinessRuDocuments);
  const settings = useSelector(getSettings);
  const businessRuDataCheckSettingStatus = settings.find((setting) => setting.name === 'business_ru_data_check')
    ? settings.find((setting) => setting.name === 'business_ru_data_check').status
    : 0;
  const displayedListItems = isInArchive ? documentsListItems
		.map((listItem) => {
			const changedDocument = changedInBusinessRuDocuments[DocumentTypeMap[documentType]]
				.find((document) => Number(document.system_doc.id) === Number(listItem.id));

      if (!changedDocument) {
				return listItem;
			}

      const documentChanged = Object.values(changedDocument.changes).some((item) => !!item) ||
				!!changedDocument.goods.changedGoods.length ||
				!!changedDocument.goods.addedGoods.length ||
				(!!changedDocument.goods.deletedGoods.length && !!changedDocument.bru_docs[0]) ||
				(!!changedDocument.bru_docs[0] && !changedDocument.bru_docs[0].deleted && !!changedDocument.bru_docs[0].held);
			const documentDeleted = !changedDocument.bru_docs[0] || (changedDocument.bru_docs[0] && changedDocument.bru_docs[0].deleted);
			const documentDenied = changedDocument.bru_docs[0] && !changedDocument.bru_docs[0].deleted && !changedDocument.bru_docs[0].held;

			return {
				...listItem,
				documentChanged,
        documentDeleted,
				documentDenied
			};
		}) : documentsListItems;

  const sortedByDateDocuments = structuredClone(displayedListItems)
    .sort((currentDocument, nextDocument) => {
      const currentItemDate = new Date(
        ...currentDocument.document_date.split(' ').slice(0, -1).join('').split('.').reverse() // дата
      );
      const nextItemDate = new Date(
        ...nextDocument.document_date.split(' ').slice(0, -1).join('').split('.').reverse() // дата
      );

      const currentItemNumber = (/\d+/).test(currentDocument.document_number) ? Number(currentDocument.document_number.match(/\d+/)[0]) : 0;
		  const nextItemNumber = (/\d+/).test(nextDocument.document_number) ? Number(nextDocument.document_number.match(/\d+/)[0]) : 0;

      const currentItemCopyNumber = currentDocument.document_number.split(' ')[2] ? Number(currentDocument.document_number.split(' ')[2]) : 0;
      const nextItemCopyNumber = nextDocument.document_number.split(' ')[2] ? Number(nextDocument.document_number.split(' ')[2]) : 0;

      if (nextItemDate.getTime() === currentItemDate.getTime()) {
        if (nextItemNumber === currentItemNumber) {
          return nextItemCopyNumber - currentItemCopyNumber;
        } else {
          return nextItemNumber - currentItemNumber;
        }
      } else {
        return nextItemDate - currentItemDate;
      }
    });

  const requestForChangedInBusinessRuDocuments = async () => {
    setIsPreloaderActive(true);
    await dispatch(fetchChangedInBusinessRuDocumentsAsyncAction());
    setIsPreloaderActive(false);
  };

  // Запрос изменённых в Бизнес.ру документов
  useEffect(() => {
    if (businessRuDataCheckSettingStatus === 1) {
      requestForChangedInBusinessRuDocuments();
    }
  }, [documentType, isInArchive, businessRuDataCheckSettingStatus]);

  // Активация модального окна с отменёнными в Бизнес.ру документами
	useEffect(() => {
		if (Object.values(changedInBusinessRuDocuments).some((item) => !!item.length)) {
			setChangedInBRuDocumentsModalActive(true);
		}
	}, [
    documentType, isInArchive,
    changedInBusinessRuDocuments.inventories.length,
    changedInBusinessRuDocuments.supplies.length,
    changedInBusinessRuDocuments.realizations.length,
    changedInBusinessRuDocuments.postings.length,
    changedInBusinessRuDocuments.charges.length,
    changedInBusinessRuDocuments.shiftings.length,
    changedInBusinessRuDocuments.factories.length
  ]);

  return (
    <>
      <Preloader isActive={isPreloaderActive}/>

      <table className={styles.table}>
        <thead>
          <tr>
            <th>Системный №</th>
            <th>Тип</th>
            <th>Дата в Системе</th>
            <th>Склад</th>
            {
              isInArchive && (
                <th>Статус в Бизнес.ру</th>
              )
            }
            <th/>
            <th/>
          </tr>
        </thead>
        <tbody>
          {
            sortedByDateDocuments.map((document, index) => (
              <DocumentsListItem
                key={index}
                document={document}
                setIsPreloaderActive={setIsPreloaderActive}
              />
            ))
          }
        </tbody>
      </table>

      {
        !isInArchive && (
          <ChangedInBusinessRuDocumentsModal
            changedInBusinessRuDocuments={changedInBusinessRuDocuments}
            changedInBRuDocumentsModalActive={changedInBRuDocumentsModalActive}
            setChangedInBRuDocumentsModalActive={setChangedInBRuDocumentsModalActive}
          />
        )
      }
    </>
  );
};

export default DocumentsListItems;
