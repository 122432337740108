import {useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import clsx from 'clsx';

import {selectOrderCommentValue, setItemsAction} from 'redux/slices/offers/offers';

import CustomSearchSelect from 'components/custom-search-select/custom-search-select-memo';

import styles from './styles.module.scss';

const OfferTableRow = ({
  items,
  item,
  itemIndex,
  position,
  positionIndex,
  provided,
  selectGood,
  orderCommentOptions,
  nameOptions,
  articleOptions
}) => {
  const dispatch = useDispatch();

  const handleOrderCommentSelectChange = (value) => {
    dispatch(selectOrderCommentValue({itemIndex, value}));
  };

  const handleCommentTextAreaChange= (evt) => {
    const value = evt.currentTarget.value;
    const updatedItems = structuredClone(items);

    updatedItems[itemIndex].comment = value;

    dispatch(setItemsAction(updatedItems));
  };

  const handlePositionAmountInputChange = (evt) => {
    const inputValue = evt.currentTarget.value;

    if ((/\D+/).test(inputValue)) {
      return;
    }

    if (!items[itemIndex].name) {
      toast.error('Выберите товар', {
        position: 'bottom-right'
      });
      return;
    }

    if (items[itemIndex].positions[positionIndex].storage === 'not found' && items[itemIndex].manual_input === 0) {
      return;
    }

    const value = Number(inputValue) < 0 ? 0 : Number(inputValue);
    const updatedItems = structuredClone(items);

    updatedItems[itemIndex].positions[positionIndex].selected_amount = value;

    dispatch(setItemsAction(updatedItems));
  };

  const handleItemDiscountInputChange = (evt) => {
    const inputValue = evt.currentTarget.value;

    if ((/\D+/).test(inputValue)) {
      return;
    }

    if (!items[itemIndex].name && items[itemIndex].manual_input === 0) {
      toast.error('Выберите товар', {
        position: 'bottom-right'
      });
      return;
    }

    if (items[itemIndex].positions[positionIndex].storage === 'not found' && items[itemIndex].manual_input === 0) {
      return;
    }

    const updatedItems = structuredClone(items);
    const discount = Number(inputValue);

    updatedItems[itemIndex].discount = discount;
    updatedItems[itemIndex].discount_price = Math.ceil(Number(item.price) * (1 - Number(inputValue) / 100));
    updatedItems[itemIndex].manual_discount = discount === 0 ? 0 : 1;

    dispatch(setItemsAction(updatedItems));
  };

  const handleManualInputStatusChange = (evt) => {
    const updatedItems = structuredClone(items);
    const manualInputStatus = evt.currentTarget.checked ? 0 : 1;

    updatedItems[itemIndex].manual_input = manualInputStatus;
    updatedItems[itemIndex].name = '';
    updatedItems[itemIndex].article = '';
    updatedItems[itemIndex].price = 0;
    updatedItems[itemIndex].discount = 0;
    updatedItems[itemIndex].discount_price = 0;
    updatedItems[itemIndex].good_id = '0';
    updatedItems[itemIndex].positions = [
      {
        selected_amount: updatedItems[itemIndex].order_amount,
        storage: manualInputStatus === 1 ? 'not found' : '',
        total_storage_amount: 0
      }
    ];

    dispatch(setItemsAction(updatedItems));
  };

  const handlePriceInputChange = (evt) => {
    const inputValue = evt.currentTarget.value;

    if ((/\D+/).test(inputValue)) {
      return;
    }

    const updatedItems = structuredClone(items);

    updatedItems[itemIndex].price = Number(inputValue);
    updatedItems[itemIndex].discount_price = Math.ceil(Number(inputValue) * (1 - Number(item.discount) / 100));

    dispatch(setItemsAction(updatedItems));
  };

  return (
    <tr>
      {
        positionIndex === 0 && (
          <>
            <td rowSpan={item.positions.length}>{itemIndex + 1}</td>
            <td
              rowSpan={item.positions.length}
              className={styles.orderName}
            >
              {item.order_name}
            </td>
            <td
              rowSpan={item.positions.length}
              className={clsx(styles.containerSize, {
                [styles.narrow]: item.order_container.length > 5
              })}
            >
              {item.order_container}
            </td>
            <td rowSpan={item.positions.length}>{item.order_height}</td>
            <td rowSpan={item.positions.length}>{item.order_amount}</td>
            <td
              rowSpan={item.positions.length}
              className={styles.orderComment}
            >
              {
                provided === 1 ? (
                  item.order_comment
                ) : (
                  <CustomSearchSelect
                    inputName='order_comment'
                    defaultValue={item.order_comment}
                    options={orderCommentOptions}
                    onChange={handleOrderCommentSelectChange}
                    placeholder='Примечание'
                    searchMode={true}
                  />
                )
              }
            </td>
            {/* <td
              rowSpan={item.positions.length}
            >
              <input
                type='checkbox'
                onChange={handleManualInputStatusChange}
                checked={item.manual_input === 0}
              />
            </td> */}
            <td
              rowSpan={item.positions.length}
              className={styles.plantName}
            >
              <span className={styles.plantNameWrapper}>
                <span className={styles.plantNameInputWrapper}>
                  {
                    item.manual_input === 0 ? (
                      <CustomSearchSelect
                        inputName='name'
                        defaultValue={item.name}
                        options={nameOptions}
                        onChange={(value) => selectGood(itemIndex, 'name', value)}
                        placeholder='Наименование'
                        searchMode={true}
                      />
                    ) : (
                      <input
                        type='text'
                        name='name'
                        id='name'
                        value={item.name}
                        onChange={(evt) => selectGood(itemIndex, 'name', evt.currentTarget.value)}
                      />
                    )
                  }
                </span>
                <span className={styles.plantNameCheckboxWrapper}>
                  <input
                    type='checkbox'
                    onChange={handleManualInputStatusChange}
                    checked={item.manual_input === 0}
                  />
                </span>
              </span>
            </td>
            <td
              rowSpan={item.positions.length}
              className={styles.plantArticle}
            >
              {
                item.manual_input === 0 ? (
                  <CustomSearchSelect
                    inputName='article'
                    defaultValue={item.article}
                    options={articleOptions}
                    onChange={(value) => selectGood(itemIndex, 'article', value)}
                    placeholder='Артикул'
                    searchMode={true}
                  />
                ) : (
                  <input
                    type='text'
                    name='article'
                    id='article'
                    value={item.article}
                    onChange={(evt) => selectGood(itemIndex, 'article', evt.currentTarget.value)}
                  />
                )
              }
            </td>
          </>
        ) 
      }

      <td className={styles.storage}>
        {
          position.storage === 'not found' || item.manual_input === 1 ? (
            <span className={styles.errorMessage}>Нет<br/>на&nbsp;складе</span>
          ) : (
            <span>{position.storage}</span>
          )
        }
      </td>
      <td className={styles.totalAmount}>{position.total_storage_amount}</td>
      <td className={styles.selectedAmount}>
        {
          provided === 1 ? (
            position.selected_amount
          ) : (
            <input
              type='text'
              name='selected_amount'
              placeholder='Кол-во'
              value={position.selected_amount}
              onChange={handlePositionAmountInputChange}
              autoComplete='off'
            />
          )
        }
      </td>

      {
        positionIndex === 0 && (
          <>
            <td
              rowSpan={item.positions.length}
              className={styles.price}
            >
              {
                item.manual_input === 0 ? (
                  item.price
                ) : (
                  <input
                    type='text'
                    name='price'
                    placeholder='Цена'
                    value={item.price}
                    onChange={handlePriceInputChange}
                    autoComplete='off'
                  />
                )
              }
            </td>
            <td
              rowSpan={item.positions.length}
              className={styles.discount}
            >
              {
                provided === 1 ? (
                  item.discount
                ) : (
                  <input
                    type='text'
                    name='discount'
                    placeholder='Скидка'
                    value={item.discount}
                    onChange={handleItemDiscountInputChange}
                    autoComplete='off'
                  />
                )
              }
            </td>
            <td
              rowSpan={item.positions.length}
              className={styles.discountPrice}
            >
              {item.discount_price}
            </td>
            <td rowSpan={item.positions.length}>{item.discount_price * item.positions.reduce((res, item) => res + item.selected_amount, 0)}</td>
            <td
              rowSpan={item.positions.length}
              className={styles.comment}
            >
              {
                provided === 1 ? (
                  item.comment
                ) : (
                  <textarea
                    name="comment"
                    placeholder="Примечание"
                    value={item.comment}
                    onChange={handleCommentTextAreaChange}
                  />
                )
              }
            </td>
          </>
        )
      }
    </tr>
  );
};

export default OfferTableRow;
