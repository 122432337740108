import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';

import InputDate from 'components/input/InputDate';

import cl from 'styles/components/cabinet/UsersTable.module.scss';
import styles from './realizations-table-styles.module.scss';

const RealizationsTable = ({Realizations, alert, userLevel}) => {
	const navigate = useNavigate();
	const router = {
		pathname: window.location.pathname,
		push: (p) => navigate(p),
		query: {type: (new URLSearchParams(window.location.search)).get("type")},
	};
	
	// Локальные данные
	const [realizations, setRealizations] = useState(Realizations);
	
	// Периоды фильтрации
	const [period, setPeriod] = useState({before: "", after: ""});
	
	// Строка поиска
	const [search, setSearch] = useState("");
	
	// Изменение локального состояния
	useEffect(() => {
		setRealizations(Realizations);
	}, [Realizations]);
	
	// Фильтрация
	useEffect(() => {
		let filtered = Realizations;
		if(period.after !== "")
			filtered = filtered.filter(realization => new Date(realization.document_date_filter).valueOf() >= new Date(period.after + " 00:00").valueOf());
		if(period.before !== "")
			filtered = filtered.filter(realization => new Date(realization.document_date_filter).valueOf() <= new Date(period.before + " 23:59").valueOf());
		if(search !== "")
			filtered = filtered.filter(realization => {
				if(realization.comment) return realization.comment.toLowerCase().includes(search.toLowerCase());
				else return false;
			});
		
		setRealizations(filtered);
	}, [period, search]);
	
	// Изменение периода фильтрации
	const changePeriod = (name, value) => {
		const copy = structuredClone(period);
		copy[name] = value;
		setPeriod(copy);
	}
	
	// Удаление отгрузки
	const remove = (index) => {
		const copy = structuredClone(realizations);
		
		const btns = [
			{
				text: "Да",
				handler: function(){
					axios.delete("realizations/deleteRealization/" + copy[index].id).then(() => {
						copy.splice(index, 1);
						setRealizations(copy);
						alert("", "danger", 1);
					}).catch(() => {
						alert("Ошибка удаления", "danger");
					});
				}
			},
			{
				text: "Нет",
				handler: function(){
					alert("", "danger", 1);
				}
			}
		];
		alert("Подтвердите удаление", "danger", 10000, btns);
	}
	
	return (
		<table className={cl.usersTable}>
			<thead>
				<tr>
					<th colSpan="2">
						<div style={{display: "inline-block", width: "45%"}}>
							<InputDate name="after" currentValue={period.after} changeHandler={changePeriod}/>
						</div>
						<div style={{display: "inline-block", width: "4%"}}>—</div>
						<div style={{display: "inline-block", width: "45%"}}>
							<InputDate name="before" currentValue={period.before} changeHandler={changePeriod}/>
						</div>
					</th>
					<th colSpan={userLevel === 3 ? "3" : "2"}>
						<input
							type="search"
							name="commentSearch"
							value={search}
							title={search}
							placeholder="Поиск по примечанию"
							onChange={(e) => setSearch(e.target.value)}
						/>
					</th>
				</tr>
				<tr>
					<th>№</th>
					<th>Заказ</th>
					<th>Клиент</th>
					<th>Дата</th>
					<th>Статус в Бизнес.ру</th>
					<th/>
					<th/>
				</tr>
			</thead>
			<tbody>
				{
					realizations.map((r, index) =>
						<tr key={index.toString()}>
							<td>
								<span>{r.document_number}</span>
							</td>
							<td>
								<span>{r.number}</span>
							</td>
							<td>
								<span>{r.customer}</span>
							</td>
							<td>
								<span>{r.document_date}</span>
							</td>
							<td>
								{
									r.realizationChanged &&
									!r.realizationDeleted &&
									!r.realizationDenied && (
										<span className={styles.documentDeniedMark}>Изменено в Бизнес.ру</span>
									)
								}
								{
									r.realizationDeleted && (
										<span className={styles.documentDeniedMark}>Удалено в Бизнес.ру</span>
									)
								}
								{
									r.realizationDenied &&
									!r.realizationDeleted &&
									!r.realizationChanged && (
										<span className={styles.documentDeniedMark}>Отменено в Бизнес.ру</span>
									)
								}
								{
									r.realizationDenied &&
									!r.realizationDeleted &&
									r.realizationChanged && (
										<span className={styles.documentDeniedMark}>Отменено / Изменено в Бизнес.ру</span>
									)
								}
								{
									!r.realizationChanged &&
									!r.realizationDeleted &&
									!r.realizationDenied && (
										<>✔️</>
									)
								}
							</td>
							<td>
								<span
									className={cl.icon + " material-icons"}
									title="Посмотреть"
									onClick={() => router.push("/archive/realizations/" + r.id)}
								>
									visibility
								</span>
							</td>
							{
								userLevel === 3 &&
								<td>
									<span
										className={cl.icon + " " + cl.remove + " material-icons"}
										title="Удалить"
										onClick={() => remove(index)}
									>
										close
									</span>
								</td>
							}
						</tr>
					)
				}
			</tbody>
		</table>
	);
};

export default RealizationsTable;
