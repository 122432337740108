import {combineReducers} from '@reduxjs/toolkit';

import {userSlice} from 'redux/slices/userSlice';
import {mapSlice} from 'redux/slices/mapSlice';
import {fieldSlice} from 'redux/slices/fieldSlice';
import {journalSlice} from 'redux/slices/journalSlice';
import {inventorySlice} from './slices/inventory/inventorySlice';
import {businessSlice} from 'redux/slices/business/businessSlice';
import {docSlice} from 'redux/slices/docSlice';
import {history} from 'redux/slices/history/history';

import {goodSlice} from 'redux/slices/good/goodSlice';
import {authProcess} from 'redux/slices/auth-process/auth-process';
import {documents} from 'redux/slices/documents/documents';
import {realizations} from './slices/realizations/realizations';
import {supplies} from './slices/supplies/supplies';
import {factory} from './slices/factory/factory';
import {remains} from './slices/remains/remains';
import {offers} from './slices/offers/offers';
import {settings} from './slices/settings/settings';
import {appData} from './slices/app-data/app-data';

export const rootReducer = combineReducers({
  user: userSlice.reducer,
  map: mapSlice.reducer,
  field: fieldSlice.reducer,
  journal: journalSlice.reducer,
  inventory: inventorySlice.reducer,
  good: goodSlice.reducer,
  business: businessSlice.reducer,
  doc: docSlice.reducer,
  history: history.reducer,
  authProcess: authProcess.reducer,
  documents: documents.reducer,
  realizations: realizations.reducer,
  supplies: supplies.reducer,
  factory: factory.reducer,
  remains: remains.reducer,
  offers: offers.reducer,
  settings: settings.reducer,
  appData: appData.reducer
});
