import {createAsyncThunk} from '@reduxjs/toolkit';
import {ApiRoute} from 'constants/endpoints';

export const uploadRemainsFileAndFillDBAsyncAction = createAsyncThunk(
  'remains/uploadRemainsFileAndFillDB',
  async (formData, {extra: api}) => {
    const {data} = await api.post(ApiRoute.Remains.UPLOAD_REMAINS, formData);

    return data;
  }
);

export const fetchRemainsAsyncAction = createAsyncThunk(
  'remains/fetchRemains',
  async (_args, {extra: api}) => {
    const {data} = await api.get(ApiRoute.Remains.ALL);

    return data;
  }
);

export const fetchRemainsDocumentDetailsAsyncAction = createAsyncThunk(
  'remains/fetchRemainsDocumentDetails',
  async (id, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Remains.SHOW}/${id}`);

    return data;
  }
);

export const provideRemainsAsyncAction = createAsyncThunk(
  'remains/provideRemains',
  async (id, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Remains.PROVIDE}/${id}`);

    return data;
  }
);

export const denyRemainsProvidingAsyncAction = createAsyncThunk(
  'remains/denyRemainsProviding',
  async (id, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Remains.DENY}/${id}`);

    return data;
  }
);

export const fetchAddressesAsyncAction = createAsyncThunk(
  'remains/fetchAddresses',
  async (_args, {extra: api}) => {
    const {data} = await api.get(ApiRoute.Map.GET_ALL_ADDRESSES);

    return data;
  }
);

export const deleteRemainsDocumentAsyncAction = createAsyncThunk(
  'remains/deleteRemainsDocument',
  async (id, {extra: api}) => {
    await api.delete(`${ApiRoute.Remains.DELETE}/${id}`);
  }
);

export const checkIfDenyPossibleAsyncAction = createAsyncThunk(
  'remains/checkIfDenyPossible',
  async (id, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Remains.CHECK_DENY_POSSIBLE}/${id}`);

    return data;
  }
);

export const downloadRemainsDocumentAsyncAction = createAsyncThunk(
  'remains/downloadRemainsDocument',
  async (id, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Remains.DOWNLOAD}/${id}`);

    return data;
  }
);
