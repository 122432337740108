import {useContext, useEffect, useLayoutEffect, useMemo, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';

import Preloader from 'components/preloader/SpinPlaceholderComponent';
import OfferTableRow from 'components/offer-table-row/offer-table-row';
import OfferFloatPanel from 'components/offer-float-panel/offer-float-panel';
import Modal from 'components/Modal';
import DeliveryTerms from 'components/delivery-terms/delivery-terms';

import {fetchGoodsAsyncAction} from 'redux/slices/good/good-api-actions';
import {fetchOfferDetailsAsyncAction, refreshGoodAmountsAsyncAction} from 'redux/slices/offers/offers-api-actions';
import {resetState, selectGoodAction, setDiscountAction} from 'redux/slices/offers/offers';
import {getItems, getOffer} from 'redux/slices/offers/selectors';
import {getGoods} from 'redux/slices/good/selectors';

import {AppContext} from 'providers/AppContextProvider';
import {getHumanFormattedDate, sortStringValues} from 'helpers/utils';
import {getUser} from 'helpers/storage';
import {AppRoute} from 'constants/routes';
import {orderCommentOptions} from './constant';

import styles from './styles.module.scss';
import ValidateOfferModal from 'components/validate-offer-modal/validate-offer-modal';

const OfferDetails = () => {
  const dispatch = useDispatch();
  const {id} = useParams();
  const location = useLocation();

  const ARCHIVE_LOCATION_REG_EXP = /archive/;
  const pathName = location.pathname;
  const isInArchive = ARCHIVE_LOCATION_REG_EXP.test(pathName);

  // Проверка уровня пользователя
  const canUserUpdate = getUser().accessLevel > 1;

  const {setCrumbs} = useContext(AppContext);

  // Активность прелоадера
  const [isPreloaderActive, setIsPreloaderActive] = useState(false);

  // Модальное окно примечания
  const [commentModalActive, setCommentModalActive] = useState(false);

  // Модальное окно валидации таблиц
  const [validateOfferModalData, setValidateOfferModalData] = useState({});
  const [validateOfferModalActive, setValidateOfferModalActive] = useState(false);

  const offer = useSelector(getOffer);
  const items = useSelector(getItems);
  const number = offer.number;
  const date = offer.date;
  const author = offer.author;
  const customer = offer.customer;
  const orderNumber = offer.order_number;
  const provided = offer.provided;
  const groupedDiscountValues = items.reduce((res, item) => {
    res[item.discount] = res[item.discount] ? res[item.discount] + 1 : 1;
    return res;
  }, {});
  const discount = items.length ? Object.entries(groupedDiscountValues).sort((a, b) => b[1] - a[1])[0][0] : 0;

  const comment = '';
  const commentFiles = [];

  // Наименования и артикулы
  const goods = useSelector(getGoods);
  const nameOptions = useMemo(() => {
    const sortedNames = structuredClone(goods).sort((a, b) => {
      const res = sortStringValues(a.value, b.value);
      return res;
    })

    return sortedNames;
  }, [goods]);
  const articleOptions = useMemo(() => {
    const sortedArticles = nameOptions.map((plant) => ({
      id: plant.b_group_id,
      name: plant.article,
      value: plant.article
    }));

    return sortedArticles;
  }, [nameOptions]);

  // Метод изменения наименования, артикула и группы
  const selectGood = async (index, selectElementName, value) => {
    await dispatch(selectGoodAction({index, selectElementName, value, goods}));

    // Запрос остатков на складах из Бизнес.ру
    if (items[index].manual_input === 0) {
      const goodId = goods.find((item) => item[selectElementName] === value).good_id;

      if (goodId) {
        dispatch(refreshGoodAmountsAsyncAction({
          good_id: goodId,
          index
        }));
      }
    }
  };

  const handleDiscountInputChange = (evt) => {
    const value = evt.currentTarget.value;
    const isAnyGoodSelected = items.some((item) => item.name);

    if (!isAnyGoodSelected) {
      toast.error('Выберите товары', {
        position: 'bottom-right'
      });
      return;
    }

    if ((/\D+/).test(value)) {
      return;
    }

    const discount = Number(value) < 0 ? 0 : Number(value);

    dispatch(setDiscountAction(discount));
  };

  // Обработчик кнопки закрытия модального окна примечания
  const handleCommentModalCloseButtonClick = () => {
    setCommentModalActive(false);
  };

  // Обработчик ввода комментария
  const handleModalTextAreaChange = () => {};

  // Обработчик добавления файлов к комментарию
  const handleFileInputChange = () => {};

  // Запрос данных с сервера
  const fetchData = async () => {
    setIsPreloaderActive(true);
    if (!goods.length) {
      await dispatch(fetchGoodsAsyncAction());
    }
    await dispatch(fetchOfferDetailsAsyncAction(id));
    setIsPreloaderActive(false);
  };

  useLayoutEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    const crumbs = isInArchive ? [
      {name: 'Архив', url: AppRoute.Archive.ALL},
      {name: 'Коммерческое предложение', url: AppRoute.Archive.OFFERS},
      {name: `№ ${offer.number} от ${offer.date ? getHumanFormattedDate(offer.date) : ''}`, url: ''}
    ] : [
      {name: 'Коммерческое предложение', url: AppRoute.Offers.LIST},
      {name: `№ ${offer.number} от ${offer.date ? getHumanFormattedDate(offer.date) : ''}`, url: ''}
    ];

    setCrumbs(crumbs);
  }, [id, offer.number, offer.date]);

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

  return (
    <>
      <Preloader isActive={isPreloaderActive}/>

      <OfferFloatPanel
        setModalActive={setCommentModalActive}
        setIsPreloaderActive={setIsPreloaderActive}
        setValidateOfferModalData={setValidateOfferModalData}
        setValidateOfferModalActive={setValidateOfferModalActive}
      />

      <table className={`${styles.offerTable} ${styles.top0}`}>
        <thead>
          <tr>
            <th colSpan={6}>{`Коммерческое предложение № ${number} от ${date ? getHumanFormattedDate(date) : ''}`}</th>
            <th colSpan={4}>Автор: {author}</th>
            <th colSpan={6}>
              Запрос: {orderNumber}<br/>
              Заказчик: {customer}
            </th>
          </tr>
          <tr>
            <td colSpan={16} className={styles.divider}></td>
          </tr>
          <tr>
            <th colSpan={6} className={styles.order}>Запрос</th>
            <th colSpan={10} className={styles.offer}>Наше предложение</th>
          </tr>
          <tr>
            <th>№</th>
            <th>Наименование</th>
            <th>Размер</th>
            <th>Высота</th>
            <th>Кол-во</th>
            <th>Примечание</th>

            <th>Наименование</th>
            <th>Артикул</th>
            <th>Склад</th>
            <th>Кол-во на складе</th>
            <th>Кол-во к отгрузке</th>
            <th>Цена</th>
            <th className={styles.discountPrice}>Скидка {
                !provided && canUserUpdate ? (
                  <label>
                    <input
                      type='text'
                      name='discount'
                      placeholder='Скидка'
                      value={discount}
                      onChange={handleDiscountInputChange}
                      autoComplete='off'
                    />&nbsp;%
                  </label>
                ) : (
                  <span>{discount}&nbsp;%</span>
                )
              }</th>
            <th>Цена со скидкой</th>
            <th>Сумма</th>
            <th>Примечание</th>
          </tr>
        </thead>
        <tbody>
          {
            items.map((item, itemIndex) => (
              item.positions.map((position, positionIndex) => (
                <OfferTableRow
                  key={`${itemIndex}-${positionIndex}`}
                  items={items}
                  item={item}
                  itemIndex={itemIndex}
                  position={position}
                  positionIndex={positionIndex}
                  provided={provided}
                  selectGood={selectGood}
                  orderCommentOptions={orderCommentOptions}
                  nameOptions={nameOptions}
                  articleOptions={articleOptions}
                />
              ))
            ))
          }
        </tbody>
      </table>

      <DeliveryTerms/>

      <Modal isActive={commentModalActive} setIsActive={handleCommentModalCloseButtonClick}>
        {
          !isInArchive && canUserUpdate ? (
            <>
              <textarea
                value={comment}
                onChange={handleModalTextAreaChange}
                placeholder="Примечание"
              />
              <input
                type="file"
                name="files[]"
                onChange={handleFileInputChange}
                multiple
              />
            </>
          ) : (
            <div className={styles.commentFrame}>
              {comment}
            </div>
          )
        }
        {
          commentFiles.map((file, index) => (
            <div className="fileIconGroup">
              {
                !isInArchive && canUserUpdate && (
                  <span
                    className="material-icons remove"
                    title="Удалить"
                    // onClick={() => handleRemoveFileButtonClick(file instanceof File ? {index} : {url: file.url})}
                  >
                    cancel
                  </span>
                )
              }
              <span
                className="material-icons file"
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_SERVER_URL + file.url,
                    "_blank"
                  )
                }
              >
                description
              </span>
              <span
                className="name"
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_SERVER_URL + file.url,
                    "_blank"
                  )
                }
              >
                {file.name}
              </span>
            </div>
          ))
        }
      </Modal>

      <ValidateOfferModal
        data={validateOfferModalData}
        isActive={validateOfferModalActive}
        setIsActive={setValidateOfferModalActive}
      />
    </>
  );
};

export default OfferDetails;
