import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import InputDate from 'components/input/InputDate';

import {
	fetchArchiveInventoriesAsyncAction,
	removeInventoryAsyncAction
} from 'redux/slices/inventory/inventory-api-actions';
import {getChangedInBusinessRuDocuments} from 'redux/slices/documents/selectors';

import {AppContext} from 'providers/AppContextProvider';

import {getUser} from 'helpers/storage';
import {getDateTimeStringFromDBValue, getHumanFormattedDate} from 'helpers/utils';

import {AppRoute} from 'constants/routes';

import cl from 'styles/components/cabinet/UsersTable.module.scss';
import styles from './archive-inventory-table-styles.module.scss';

const ArchiveInventoryTable = ({setIsPreloaderActive}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const {alert} = useContext(AppContext);

	const userLevel = getUser().accessLevel;

	const changedInBusinessRuDocuments = useSelector(getChangedInBusinessRuDocuments);
	const archiveInventories = useSelector((state) => state.inventory).inventories.archive
		.map((inventory) => {
			const changedInventory = changedInBusinessRuDocuments.inventories
				.find((document) => Number(document.system_doc.id) === Number(inventory.id));

			if (!changedInventory) {
				return inventory;
			}

			const inventoryChanged = Object.values(changedInventory.changes).some((item) => !!item) ||
				!!changedInventory.goods.changedGoods.length ||
				!!changedInventory.goods.addedGoods.length ||
				(!!changedInventory.goods.deletedGoods.length && !!changedInventory.bru_docs[0]) ||
				(!!changedInventory.bru_docs[0] && !changedInventory.bru_docs[0].deleted && !!changedInventory.bru_docs[0].held);
			const inventoryDeleted = !changedInventory.bru_docs[0] || (changedInventory.bru_docs[0] && changedInventory.bru_docs[0].deleted);
			const inventoryDenied = changedInventory.bru_docs[0] && !changedInventory.bru_docs[0].deleted && !changedInventory.bru_docs[0].held;

			return {
				...inventory,
				inventoryChanged,
				inventoryDeleted,
				inventoryDenied
			};
		});
	const [filteredArchiveInventories, setFilteredArchiveInventories] = useState(archiveInventories);
	const sortedArchiveInventories = structuredClone(filteredArchiveInventories).sort((currentInventory, nextInventory) => {
		const currentItemDate = new Date(getDateTimeStringFromDBValue(currentInventory.date_current));
		const nextItemDate = new Date(getDateTimeStringFromDBValue(nextInventory.date_current));

		const currentItemNumber = Number(currentInventory.document_number.match(/\d+/)[0]);
		const nextItemNumber = Number(nextInventory.document_number.match(/\d+/)[0]);

		if (nextItemDate.getTime() === currentItemDate.getTime()) {
			if (nextItemNumber === currentItemNumber) {
				const currentItemCopyNumber = currentInventory.document_number.split(' ')[2] ? Number(currentInventory.document_number.split(' ')[2]) : 0;
				const nextItemCopyNumber = nextInventory.document_number.split(' ')[2] ? Number(nextInventory.document_number.split(' ')[2]) : 0;

				return nextItemCopyNumber - currentItemCopyNumber;
			} else {
				return nextItemNumber - currentItemNumber;
			}
		} else {
			return nextItemDate - currentItemDate;
		}
	});
	
	// Периоды фильтрации
	const [period, setPeriod] = useState({before: '', after: ''});
	
	// Строка поиска
	const [search, setSearch] = useState('');

	// Запрос данных с сервера
	useEffect(() => {
		dispatch(fetchArchiveInventoriesAsyncAction());
	}, [archiveInventories.length]);
	
	// Фильтрация
	useEffect(() => {
		let filtered = archiveInventories;
		if (period.after !== '') {
			filtered = filtered.filter((inventory) => new Date(inventory.document_date_filter).valueOf() >= new Date(period.after + " 00:00").valueOf());
		}
		if (period.before !== '') {
			filtered = filtered.filter((inventory) => new Date(inventory.document_date_filter).valueOf() <= new Date(period.before + " 23:59").valueOf());
		}
		if (search !== '') {
			filtered = filtered.filter((inventory) => {
				if (inventory.comment) {
					if (inventory.comment.toLowerCase().includes(search.toLowerCase())) {
						return true;
					}
				} 
				if (inventory.document_number.toLowerCase().includes(search.toLowerCase())) {
					return true;
				}
				if (inventory.storage.toLowerCase().includes(search.toLowerCase())) {
					return true;
				}
				return false;
			});
		}
		
		setFilteredArchiveInventories(filtered);
	}, [period, search, archiveInventories.length]);
	
	// Изменение периода фильтрации
	const changePeriod = (name, value) => {
		const copy = structuredClone(period);
		copy[name] = value;
		setPeriod(copy);
	}

	const handleDeleteButtonClick = (inventoryId, copyInventoryId) => {
		const handler = async () => {
      setIsPreloaderActive(true);
      await dispatch(removeInventoryAsyncAction(inventoryId));
      await dispatch(removeInventoryAsyncAction(copyInventoryId));
			await dispatch(fetchArchiveInventoriesAsyncAction());
			setIsPreloaderActive(false);
    };

    alert('Удалить архив?', 'danger', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => {
          alert('', 'default', 1);
        }
      }
    ]);
	};

	const handleShowInventoryButtonClick = (id, copyInventoryId) => {
		navigate(`${AppRoute.Archive.INVENTORY}/${id}`);
		window.open(`${AppRoute.Archive.INVENTORY}/${copyInventoryId}`, '_blank');
	};
	
	return (
		<table className={cl.usersTable}>
			<thead>
				<tr>
					<th colSpan="2">
						<div style={{display: "inline-block", width: "45%"}}>
							<InputDate name="after" currentValue={period.after} changeHandler={changePeriod}/>
						</div>
						<div style={{display: "inline-block", width: "4%"}}>—</div>
						<div style={{display: "inline-block", width: "45%"}}>
							<InputDate name="before" currentValue={period.before} changeHandler={changePeriod}/>
						</div>
					</th>
					<th colSpan={userLevel === 3 ? "4" : "3"}>
						<input
							type="search"
							name="commentSearch"
							value={search}
							title={search}
							placeholder="Поиск по примечанию"
							onChange={(e) => setSearch(e.target.value)}
						/>
					</th>
				</tr>
				<tr>
					<th>№</th>
					<th>Дата инвентаризации</th>
					<th>Дата в Системе</th>
					<th>Склад</th>
					<th>Статус в Бизнес.ру</th>
					<th/>
					<th/>
					{userLevel === 3 && <th/>}
				</tr>
			</thead>
			<tbody>
				{
					sortedArchiveInventories.map((inv, index) =>
						<tr key={index.toString()}>
							<td>
								<span>{inv.document_number}</span>
							</td>
							<td>
								<span>{getHumanFormattedDate(inv.date)}</span>
							</td>
							<td>
								<span>{inv.date_current}</span>
							</td>
							<td>
								<span>{inv.storage}</span>
							</td>
							<td>
								{
									inv.realizationChanged &&
									!inv.realizationDeleted &&
									!inv.realizationDenied && (
										<span className={styles.documentDeniedMark}>Изменено в Бизнес.ру</span>
									)
								}
								{
									inv.realizationDeleted && (
										<span className={styles.documentDeniedMark}>Удалено в Бизнес.ру</span>
									)
								}
								{
									inv.realizationDenied &&
									!inv.realizationDeleted &&
									!inv.realizationChanged && (
										<span className={styles.documentDeniedMark}>Отменено в Бизнес.ру</span>
									)
								}
								{
									inv.realizationDenied &&
									!inv.realizationDeleted &&
									inv.realizationChanged && (
										<span className={styles.documentDeniedMark}>Отменено / Изменено в Бизнес.ру</span>
									)
								}
								{
									!inv.realizationChanged &&
									!inv.realizationDeleted &&
									!inv.realizationDenied && (
										<>✔️</>
									)
								}
							</td>
							<td>
								<span
									className={cl.icon + " material-icons"}
									title="Посмотреть"
									onClick={() => handleShowInventoryButtonClick(inv.id, inv.copy)}
								>
									visibility
								</span>
							</td>
							{
								userLevel === 3 && (
									<td>
										<span
											className={`${cl.icon} ${cl.remove} material-icons`}
											title="Удалить"
											onClick={() => handleDeleteButtonClick(inv.id, inv.copy)}
										>
											close
										</span>
									</td>
								)
							}
						</tr>
					)
				}
			</tbody>
		</table>
	);
};

export default ArchiveInventoryTable;
