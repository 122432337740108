import {useContext} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {toast} from 'react-toastify';

import {
  addSubStringAction,
  changeFactAmountAction,
  deleteSubStringAction,
  selectFactAddressAction,
  selectGoodAction,
  setInventoryDataAction
} from 'redux/slices/inventory/inventorySlice';
import {getGoods} from 'redux/slices/good/selectors';
import {getGroups} from 'redux/slices/business/selectors';
import {getInventoryDate} from 'redux/slices/inventory/selectors';
import {setInventoryNewGoodInitialAmountsAsyncAction} from 'redux/slices/inventory/inventory-api-actions';

import InventoryTableRow from 'components/inventory-table-row/inventory-table-row';
import AddNewLineButton from 'components/add-new-line-button/add-new-line-button';

import {getDateFromMySQLDate, sortStringValues} from 'helpers/utils';
import {AppContext} from 'providers/AppContextProvider';

const InventoryTableBody = ({
  paginationData,
  setPaginationData,
  firstItemIndex,
  displayedItems
}) => {
  const dispatch = useDispatch();
  const {id} = useParams();

  const {alert} = useContext(AppContext);

  const items = useSelector((state) => state.inventory).inventory.data;
  const selectedGoodArticles = structuredClone(items).map((item) => item.article);

  const goods = useSelector(getGoods);
  const groupsIdsFromGoods = goods.map((good) => good.b_group_id);
  const groups = useSelector(getGroups).filter((group) => groupsIdsFromGoods.includes(group.id));

  const provided = useSelector((state) => state.inventory.inventory.provided);
  const isHeldInBusinessRu = useSelector((state) => state.inventory.inventory.is_held_in_business_ru);
  const storage = useSelector((state) => state.inventory).inventory.storage;
  const date = useSelector(getInventoryDate);
  const allAddresses = useSelector((state) => state.inventory).addresses.data;
  const addresses = allAddresses
    .filter((address) => address.storage === storage)
    .sort((a, b) => {
      const ADDRESS_REG_EXP = /^\d+/;
      const prevValue = a.value;
      const nextValue = b.value;

      if (ADDRESS_REG_EXP.test(prevValue) && ADDRESS_REG_EXP.test(nextValue)) {
        return prevValue.match(ADDRESS_REG_EXP)[0] - nextValue.match(ADDRESS_REG_EXP)[0];
      }
      if (ADDRESS_REG_EXP.test(prevValue) && !ADDRESS_REG_EXP.test(nextValue)) {
        return -1;
      }
      if (!ADDRESS_REG_EXP.test(prevValue) && ADDRESS_REG_EXP.test(nextValue)) {
        return 1;
      }
      
      return 0;
    });

  // Выборка данных из стейта селекторами
  const inventoryItems = useSelector((state) => state.inventory).inventory.data;

  // Получить список наименований для селекта для вновь добавленной строки (в самом низу)
  const filterSelectComponentOptions = (selectComponentName, group) => {
    // Отсеиваем те товары, которые уже есть в инвентаризации
    const filteredGoods = goods
      .filter((good) => !selectedGoodArticles.includes(good.article))
      .sort((a, b) => a.name.localeCompare(b.name, 'ru', { sensitivity: 'base' }));
    const filteredArticles = filteredGoods.map((good) => ({
      id: good.b_group_id,
      name: good.article,
      value: good.article
    }));

    // Если в новой строке группа уже выбрана, то фильтруем список для селекта ещё и по группе
    if (group && groups.length) {
      const groupId = groups.find((gr) => gr.name === group).id;
      const filteredByGroupPlants = filteredGoods
        .filter((plant) => plant.b_group_id === groupId)
        .sort((a, b) => a.name.localeCompare(b.name, 'ru', { sensitivity: 'base' }));
      const filteredByGroupArticles = filteredByGroupPlants.map((plant) => ({
        id: plant.b_group_id,
        name: plant.article,
        value: plant.article
      }));

      switch(selectComponentName) {
        case 'name':
          return filteredByGroupPlants;
        case 'article':
          return filteredByGroupArticles;
        default:
          return;
      };
    }

    switch(selectComponentName) {
      case 'name':
        return filteredGoods;
      case 'article':
        return filteredArticles;
      default:
        return;
    };
  };

  // Новая строка
  const addLine = () => {
    const copy = structuredClone(inventoryItems);
    const emptyLine = {
      name: "",
      uchet: 0,
      fact: 0,
      spread: 0,
      total_uchet: 0,
      total_fact: 0,
      datas: [
        {
          spread: "",
          amount_fact: "",
          address_fact: "",
          amount_uchet: "",
          address_uchet: "",
        },
      ],
      inventory_id: id
    };

    const updatedItems = copy.length ? [
      ...copy.slice(0, paginationData.itemsPerPage * paginationData.activePage - 1),
      emptyLine,
      ...copy.slice(paginationData.itemsPerPage * paginationData.activePage - 1)
    ] : [emptyLine];

    dispatch(setInventoryDataAction(updatedItems));
  };

  // Обработчик инпута изменения фактического кол-ва
  const handleFactAmountInputChange = async (index, value) => {
    if (isHeldInBusinessRu) {
      toast.error('Инвентаризация проведена в Бизнес.ру', {
        position: 'bottom-right'
      });

      return;
    }

    if ((/\D+/).test(value)) {
      return;
    }

    await dispatch(changeFactAmountAction({index, value}));
  };

  // Обработчик селекта выбора фактического адреса
  const handleFactAddressSelectChange = async (index, value) => {
    if (isHeldInBusinessRu) {
      toast.error('Инвентаризация проведена в Бизнес.ру', {
        position: 'bottom-right'
      });

      return;
    }

    await dispatch(selectFactAddressAction({index, value}));
  };

  // Обработчик селекта выбора товара
  const handleGoodSelectChange = async (index, selectElementName, value) => {
    if (isHeldInBusinessRu) {
      toast.error('Инвентаризация проведена в Бизнес.ру', {
        position: 'bottom-right'
      });

      return;
    }

    const plantName = selectElementName === 'name'
      ? value
      : goods.find((good) => good.article === value).name;

    await dispatch(selectGoodAction({
      index,
      value,
      selectElementName,
      goods,
      groups
    }));

    // Обновление остатков выбранного товара
    if (plantName) {
      dispatch(setInventoryNewGoodInitialAmountsAsyncAction({
        name: plantName,
        storage,
        date: getDateFromMySQLDate(date)
      }));
    } else {
      toast.error('Ошибка выбора товара', {
        position: 'bottom-right'
      });
    }
  };

  // Обработчик кнопки добавления подстроки
  const handleAddSubStringButtonClick = async (index) => {
    if (isHeldInBusinessRu) {
      toast.error('Инвентаризация проведена в Бизнес.ру', {
        position: 'bottom-right'
      });

      return;
    }

    await dispatch(addSubStringAction({index}));
  };

  // Обработчик кнопки удаления строки
  const handleDeleteLineButtonClick = ({index, i}) => {
    if (isHeldInBusinessRu) {
      toast.error('Инвентаризация проведена в Бизнес.ру', {
        position: 'bottom-right'
      });

      return;
    }

    const handler = async () => {
      await dispatch(deleteSubStringAction({
        stringIndex: index,
        subStringIndex: i
      }));
    };

    alert('Удалить строку?', 'danger', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => {
          alert('', 'default', 1);
        },
        needToCloseImmediately: true
      }
    ]);
  };

  return (
    <tbody>
      {
        displayedItems.map((item, index) => (
          <InventoryTableRow
            key={item.id}
            isHeldInBusinessRu={isHeldInBusinessRu}
            item={item}
            index={firstItemIndex + index}
            nameOptions={!item.name || !item.article ? filterSelectComponentOptions('name', item.b_group) : []}
            articleOptions={!item.name || !item.article ? filterSelectComponentOptions('article', item.b_group) : []}
            groups={groups}
            addresses={addresses}
            handleFactAddressSelectChange={handleFactAddressSelectChange}
            handleFactAmountInputChange={handleFactAmountInputChange}
            handleGoodSelectChange={handleGoodSelectChange}
            handleAddSubStringButtonClick={handleAddSubStringButtonClick}
            handleDeleteLineButtonClick={handleDeleteLineButtonClick}
          />
        ))
      }

      {/* Добавить наименование */}
      {
        provided === 0 && (
          <AddNewLineButton title='Добавить наименование' addLine={addLine}/>
        )
      }
      <tr style={{ height: "1px" }} />
    </tbody>
  );
};

export default InventoryTableBody;
