import {useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import DocumentsListButtonsBlock from 'components/documents-list-buttons-block/documents-list-buttons-block';
import ListLegend from 'components/list-legend/list-legend';
import FactoriesListItem from 'components/factories-list-item/factories-list-item';
import InputDate from 'components/input/InputDate';
// import Tabs from 'components/tabs/tabs';
import ChangedInBusinessRuDocumentsModal from 'components/changed-documents-modal/changed-documents-modal';
import Preloader from 'components/preloader/SpinPlaceholderComponent';

import {
  fetchFactoriesAsyncAction,
  fetchFactoryDocumentsNumbersAsyncAction
} from 'redux/slices/factory/factory-api-actions';
import {getFactories} from 'redux/slices/factory/selectors';
import {getChangedInBusinessRuDocuments} from 'redux/slices/documents/selectors';
import {fetchChangedInBusinessRuDocumentsAsyncAction} from 'redux/slices/documents/documents-api-actions';
import {getSettings} from 'redux/slices/settings/selectors';

import {DocumentType} from 'constants/document-type';
import {AppRoute} from 'constants/routes';

import {getUser} from 'helpers/storage';
import {AppContext} from 'providers/AppContextProvider';

import cl from 'styles/components/cabinet/UsersTable.module.scss';

const FactoriesList = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const {setCrumbs} = useContext(AppContext);

  const LOCATION_REG_EXP = /archive/;
  const pathName = location.pathname;
  const isInArchive = LOCATION_REG_EXP.test(pathName);

  const userLevel = getUser().accessLevel;

  // Активность прелоадера
  const [isPreloaderActive, setIsPreloaderActive] = useState(false);

  const factories = useSelector(getFactories);
  const changedInBusinessRuDocuments = useSelector(getChangedInBusinessRuDocuments);
  const settings = useSelector(getSettings);
  const businessRuDataCheckSettingStatus = settings.find((setting) => setting.name === 'business_ru_data_check')
    ? settings.find((setting) => setting.name === 'business_ru_data_check').status
    : 0;

  const sortedByDateDocuments = structuredClone(factories)
    .sort((currentDocument, nextDocument) => {
      const currentItemDate = new Date(
        ...currentDocument.document_date.split(' ').slice(0, -1).join('').split('.').reverse() // дата
      );
      const nextItemDate = new Date(
        ...nextDocument.document_date.split(' ').slice(0, -1).join('').split('.').reverse() // дата
      );

      const currentItemNumber = (/\d+/).test(currentDocument.document_number) ? Number(currentDocument.document_number.match(/\d+/)[0]) : 0;
		  const nextItemNumber = (/\d+/).test(nextDocument.document_number) ? Number(nextDocument.document_number.match(/\d+/)[0]) : 0;

      const currentItemCopyNumber = currentDocument.document_number.split(' ')[2] ? Number(currentDocument.document_number.split(' ')[2]) : 0;
      const nextItemCopyNumber = nextDocument.document_number.split(' ')[2] ? Number(nextDocument.document_number.split(' ')[2]) : 0;

      if (nextItemDate.getTime() === currentItemDate.getTime()) {
        if (nextItemNumber === currentItemNumber) {
          return nextItemCopyNumber - currentItemCopyNumber;
        } else {
          return nextItemNumber - currentItemNumber;
        }
      } else {
        return nextItemDate - currentItemDate;
      }
    });

  // Модальное окно с изменёнными в Бизнес.ру документами
	const [changedInBRuDocumentsModalActive, setChangedInBRuDocumentsModalActive] = useState(false);

  const [tabsData, setTabsData] = useState(
    [
      {
        caption: 'производства',
        active: true
      },
      {
        caption: 'партии производств',
        active: false
      }
    ]
  );

  const requestForChangedInBusinessRuDocuments = async () => {
    setIsPreloaderActive(true);
    await dispatch(fetchChangedInBusinessRuDocumentsAsyncAction());
    setIsPreloaderActive(false);
  };

  // Запрос изменённых в Бизнес.ру документов
  useEffect(() => {
    if (businessRuDataCheckSettingStatus === 1) {
			requestForChangedInBusinessRuDocuments();
		}
  }, [isInArchive, businessRuDataCheckSettingStatus]);

  // Запрос списка производств
  useEffect(() => {
    if (isInArchive) {
      dispatch(fetchFactoriesAsyncAction(true));
    } else {
      dispatch(fetchFactoriesAsyncAction());
      dispatch(fetchFactoryDocumentsNumbersAsyncAction());
    }
  }, [isInArchive]);

  // Запись хлебных крошек
  useEffect(() => {
    const crumbs = isInArchive
      ? [
          {name: 'Архивы', url: AppRoute.Archive.ALL},
          {name: 'Производство', url: ''}
        ]
      : [
          {name: 'Производство', url: ''}
        ];

    setCrumbs(crumbs);
  }, [isInArchive]);

  // Активация модального окна с отменёнными в Бизнес.ру документами
	useEffect(() => {
		if (Object.values(changedInBusinessRuDocuments).some((item) => !!item.length)) {
			setChangedInBRuDocumentsModalActive(true);
		}
	}, [
    isInArchive,
    changedInBusinessRuDocuments.inventories.length,
    changedInBusinessRuDocuments.supplies.length,
    changedInBusinessRuDocuments.realizations.length,
    changedInBusinessRuDocuments.postings.length,
    changedInBusinessRuDocuments.charges.length,
    changedInBusinessRuDocuments.shiftings.length,
    changedInBusinessRuDocuments.factories.length
  ]);

  return (
    <>
      <Preloader isActive={isPreloaderActive}/>

      {
        !isInArchive && (
          <ListLegend
            legendData={[
              {type: 'danger', text: 'Производство создано'},
              {type: 'success', text: 'Производство проведено'}
            ]}
          />
        )
      }

      {
        !isInArchive && userLevel > 1 && (
          <DocumentsListButtonsBlock documentType={DocumentType.FACTORY}/>
        )
      }

      {/* {
        !isInArchive && (
          <Tabs
            tabsData={tabsData}
            setTabsData={setTabsData}
          >
            <table className={cl.usersTable}>
              <thead>
                <tr>
                  <th>№</th>
                  <th>Дата в Системе</th>
                  <th>Дата производства</th>
                  <th>Наименование</th>
                  <th>Склад</th>
                  <th/>
                  <th/>
                </tr>
              </thead>
              <tbody>
                {
                  sortedByDateDocuments.map((factory, index) => (
                    <FactoriesListItem
                      key={index}
                      factory={factory}
                    />
                  ))
                }
              </tbody>
            </table>
          </Tabs>
        )
      } */}

			<table className={cl.usersTable}>
				<thead>
          {
            isInArchive && (
              <tr>
                <th colSpan="2">
                  <div style={{display: "inline-block", width: "45%"}}>
                    <InputDate
                      name="after"
                      // currentValue={period.after}
                      // changeHandler={changePeriod}
                    />
                  </div>
                  <div style={{display: "inline-block", width: "4%"}}>—</div>
                  <div style={{display: "inline-block", width: "45%"}}>
                    <InputDate
                      name="before"
                      // currentValue={period.before}
                      // changeHandler={changePeriod}
                    />
                  </div>
                </th>
                <th colSpan={userLevel === 3 ? "3" : "2"}>
                  <input
                    type="search"
                    name="commentSearch"
                    // value={search}
                    // title={search}
                    placeholder="Поиск по примечанию"
                    // onChange={(e) => setSearch(e.target.value)}
                  />
                </th>
              </tr>
            )
          }
					<tr>
						<th>№</th>
						<th>Дата в Системе</th>
						<th>Дата производства</th>
						<th>Наименование</th>
						<th>Склад</th>
						<th/>
						<th/>
					</tr>
				</thead>
				<tbody>
					{
            sortedByDateDocuments.map((factory, index) => (
              <FactoriesListItem
                key={index}
                factory={factory}
              />
            ))
          }
				</tbody>
			</table>

      {
        !isInArchive && (
          <ChangedInBusinessRuDocumentsModal
            changedInBusinessRuDocuments={changedInBusinessRuDocuments}
            changedInBRuDocumentsModalActive={changedInBRuDocumentsModalActive}
            setChangedInBRuDocumentsModalActive={setChangedInBRuDocumentsModalActive}
          />
        )
      }
		</>
  );
};

export default FactoriesList;
