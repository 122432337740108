import {useContext} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import clsx from 'clsx';

import {getDeliveryTerms, getItems, getOffer} from 'redux/slices/offers/selectors';
import {
  deleteOfferAsyncAction,
  downloadOfferAsyncAction,
  moveOfferToArchiveAsyncAction,
  saveOfferAsyncAction
} from 'redux/slices/offers/offers-api-actions';

import {AppContext} from 'providers/AppContextProvider';
import {getUser} from 'helpers/storage';

import {AppRoute} from 'constants/routes';

import styles from './styles.module.scss';

const OfferFloatPanel = ({
  setModalActive,
  setIsPreloaderActive,
  setValidateOfferModalData,
  setValidateOfferModalActive
}) => {
  const dispatch = useDispatch();
  const {id} = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const LOCATION_REG_EXP = /archive/;
  const pathName = location.pathname;
  const isInArchive = LOCATION_REG_EXP.test(pathName);

  // Проверка уровня пользователя
  const canUserUpdate = getUser().accessLevel > 1;

  const {alert} = useContext(AppContext);

  const offer = useSelector(getOffer);
  const items = useSelector(getItems);
  const deliveryTerms = useSelector(getDeliveryTerms);
  const provided = offer.provided;

  // Проверки при проведении
  const checkOfferItems = () => {
    const incorrectItems = {};

    // Проверка на превышение кол-ва на складе
    items.forEach((item, itemIndex) => {
      const selectedPlantName = item.name;
      
      if (selectedPlantName) {
        item.positions.forEach((position) => {
          const totalStorageSelectedAmount = items
            .filter((item) => item.name === selectedPlantName)
            .reduce((res, item) => res + Number(item.positions.find((pos) => pos.storage === position.storage).selected_amount), 0);
  
          const isTotalSelectedAmountGreaterThanStorageAmount = totalStorageSelectedAmount > position.total_storage_amount;
  
          if (isTotalSelectedAmountGreaterThanStorageAmount) {
            if (incorrectItems[itemIndex + 1]) {
              incorrectItems[itemIndex + 1].exceedsTotalAmount = true;
              incorrectItems[itemIndex + 1].storage = position.storage;
              incorrectItems[itemIndex + 1].storageTotalAmount = position.total_storage_amount;
              incorrectItems[itemIndex + 1].totalStorageSelectedAmount = totalStorageSelectedAmount;
            } else {
              incorrectItems[itemIndex + 1] = {
                exceedsTotalAmount: true,
                storage: position.storage,
                storageTotalAmount: position.total_storage_amount,
                totalStorageSelectedAmount: totalStorageSelectedAmount
              };
            }
          }
        });
      }

      if (incorrectItems[itemIndex + 1]) {
        incorrectItems[itemIndex + 1].plantName = selectedPlantName;
      }
    });

    if (Object.keys(incorrectItems).length) {
      setValidateOfferModalData(incorrectItems);
      setValidateOfferModalActive(true);
      return false;
    } else {
      return true;
    }
  };

  // Сохранение документа
  const saveDocument = async () => {
    await dispatch(saveOfferAsyncAction({
      id,
      body: {
        offer,
        items,
        deliveryTerms
      }
    }));
  };

  // Удаление документа
  const deleteDocument = async () => {
    setIsPreloaderActive(true);
    await dispatch(deleteOfferAsyncAction(id));
    navigate(AppRoute.Offers.LIST);
    setIsPreloaderActive(false);
  };

  // Копирование документа
  const copyDocument = async () => {};

  // Перемещение документа в архив
  const moveDocumentToArchive = async () => {
    setIsPreloaderActive(true);
    await saveDocument();
    await dispatch(moveOfferToArchiveAsyncAction(id));
    navigate(AppRoute.Offers.LIST);
    setIsPreloaderActive(false);
  };

  // Обработчик кнопки сохранения документа
  const handleSaveButtonClick = () => {
    const handler = async () => {
      setIsPreloaderActive(true);
      await saveDocument();
      setIsPreloaderActive(false);
    };

    alert('Сохранить документ?', 'default', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  // Обработчик кнопки перемещения документа в архив
  const handleMoveToArchiveButtonClick = () => {
    const isEachItemExistsInBusinessRu = items.every((item) => item.manual_input === 0);
    if (!isEachItemExistsInBusinessRu) {
      toast.error('В документе есть товары, которых нет в Бизнес.ру', {
        position: 'bottom-right'
      });
      return;
    }

    const isOfferDataValid = checkOfferItems();
    if (!isOfferDataValid) {
      return;
    }

    const handler = () => {
      moveDocumentToArchive();
    };

    alert('Провести в Бизнес.ру?', 'default', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  // Обработчик кнопки удаления документа
  const handleDeleteDocumentButtonClick = () => {
    const handler = () => {
      deleteDocument();
    };

    alert('Удалить документ?', 'danger', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  // Обработчик кнопки копирования документа
  const handleCopyDocumentButtonClick = () => {
    const handler = () => {};

    alert('Копировать документ?', 'default', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  // Обработчик кнопки скачивания документа
  const handleDownloadDocumentButtonClick = async () => {
    const downloadResponse = await dispatch(downloadOfferAsyncAction(id));

    window.open(
      `${process.env.REACT_APP_SERVER_URL}/FILES/${downloadResponse.payload.filename}`,
      "_blank"
    );
  };

  const tmpButtonHandler = () => toast.success('Кнопка временно не работает', {
    position: 'bottom-right'
  });

  return (
    <div className={clsx(styles.floatPanel, styles.controlsPanel)}>
      {/* Кнопка удаления КП */}
      {
        id && canUserUpdate && !provided && (
          <span
            className="roundIcon dangerIcon material-icons"
            onClick={handleDeleteDocumentButtonClick}
            title="Удалить"
          >
            delete_sweep
          </span>
        )
      }

      {/* Кнопка перемещения КП в архив */}
      {
        id && canUserUpdate && !provided && (
          <span
            className="roundIcon material-icons"
            onClick={handleMoveToArchiveButtonClick}
            title="Провести в Бизнес.ру"
          >
            done
          </span>
        )
      }

      {/* Кнопка копирования КП */}
      {
        id && canUserUpdate && !provided && (
          <span
            className="roundIcon material-icons"
            onClick={handleCopyDocumentButtonClick}
            title="Скопировать"
          >
            copy
          </span>
        )
      }

      {/* Кнопка сохранения КП */}
      {
        canUserUpdate && !provided && (
          <span
            className="roundIcon material-icons"
            onClick={handleSaveButtonClick}
            title="Сохранить"
          >
            save
          </span>
        )
      }

      {/* Кнопка скачивания КП */}
      {
        id && (
          <span
            className="roundIcon material-icons"
            onClick={handleDownloadDocumentButtonClick}
            title="Скачать"
          >
            download
          </span>
        )
      }

      {/* Кнопка открытия модального окна примечания */}
      <span
        className="roundIcon material-icons"
        onClick={() => setModalActive(true)}
        title="Примечание"
      >
        maps_ugc
      </span>

      {/* Кнопка перехода в руководство пользователя */}
      <span
        className="roundIcon material-icons"
        onClick={tmpButtonHandler}
        title="Инструкция"
      >
        quiz
      </span>
    </div>
  );
};

export default OfferFloatPanel;
