import {DocumentType} from 'constants/document-type';
import {AppRoute} from 'constants/routes';

export const DocumentListType = {
  CHARGES: 'charges',
  POSTING: 'postings',
  SHIFTING: 'shiftings',
  INVENTORY: 'inventories',
  REALIZATION: 'realizations',
  SUPPLY: 'supplies',
  FACTORY: 'factories'
};

export const ListTypeDocTypeMap = {
  [DocumentListType.CHARGES]: DocumentType.CHARGE,
  [DocumentListType.POSTING]: DocumentType.POSTING,
  [DocumentListType.SHIFTING]: DocumentType.SHIFTING,
  [DocumentListType.INVENTORY]: DocumentType.INVENTORY,
  [DocumentListType.REALIZATION]: DocumentType.REALIZATION,
  [DocumentListType.SUPPLY]: DocumentType.SUPPLY,
  [DocumentListType.FACTORY]: DocumentType.FACTORY
};

export const DocumentTypeListNameMap = {
  [DocumentType.CHARGE]: 'списания',
  [DocumentType.POSTING]: 'оприходования',
  [DocumentType.SHIFTING]: 'перемещения',
  [DocumentType.INVENTORY]: 'инвентаризации',
  [DocumentType.REALIZATION]: 'отгрузки',
  [DocumentType.SUPPLY]: 'поступления',
  [DocumentType.FACTORY]: 'производства',
};

export const DocumentTypeArchiveNameMap = {
  [DocumentType.CHARGE]: 'Архив списаний',
  [DocumentType.POSTING]: 'Архив оприходований',
  [DocumentType.SHIFTING]: 'Архив перемещений',
  [DocumentType.INVENTORY]: 'Архив инвентаризаций',
  [DocumentType.REALIZATION]: 'Архив отгрузок',
  [DocumentType.SUPPLY]: 'Архив поступлений',
  [DocumentType.FACTORY]: 'Архив производств'
};

export const DocumentTypeAppRouteMap = {
  [DocumentType.CHARGE]: AppRoute.Archive.CHARGES,
  [DocumentType.POSTING]: AppRoute.Archive.POSTINGS,
  [DocumentType.SHIFTING]: AppRoute.Archive.SHIFTINGS,
  [DocumentType.INVENTORY]: AppRoute.Archive.INVENTORY,
  [DocumentType.REALIZATION]: AppRoute.Archive.REALIZATIONS,
  [DocumentType.SUPPLY]: AppRoute.Archive.SUPPLIES,
  [DocumentType.FACTORY]: AppRoute.Archive.FACTORIES
};

export const DocumentTypeMap = {
  [DocumentType.CHARGE]: DocumentListType.CHARGES,
  [DocumentType.POSTING]: DocumentListType.POSTING,
  [DocumentType.SHIFTING]: DocumentListType.SHIFTING,
  [DocumentType.INVENTORY]: DocumentListType.INVENTORY,
  [DocumentType.REALIZATION]: DocumentListType.REALIZATION,
  [DocumentType.SUPPLY]: DocumentListType.SUPPLY,
  [DocumentType.FACTORY]: DocumentListType.FACTORY
};
