import {memo} from 'react';

import cl from 'styles/pages/[fieldId].module.scss';
import colors from 'styles/pages/[boardId].module.scss';
import styles from './styles.module.scss';

import CustomSearchSelect from 'components/custom-search-select/custom-search-select-memo';

const InventoryTableRow = ({
  isHeldInBusinessRu, // не удалять - используется в управлении перерисовками (в мемоизации)
  item,
  index,
  nameOptions,
  articleOptions,
  groups,
  addresses,
  handleFactAddressSelectChange,
  handleFactAmountInputChange,
  handleGoodSelectChange,
  handleAddSubStringButtonClick,
  handleDeleteLineButtonClick
}) => {
  const itemOrderNumber = index + 1;

  // Список существующих и добавленных адресов в подстроках
  // для исключения их из выпадающего списка выбора адреса в подстроке
  const existingItemAddresses = Object.keys(item.datas.reduce((res, datasItem) => ({
    ...res,
    [datasItem.address_fact]: null,
    [datasItem.address_uchet]: null
  }), {})).filter((address) => !!address);
  const displayedAddresses = addresses.filter((address) => !existingItemAddresses.includes(address.value));

  return (
    <>
      {
        item.datas.map((dt, i) => (
          <tr key={i}>
            {
              i === 0 && (
                <>
                  {/* Ячейка: Номер по порядку */}
                  <td className={cl.center} rowSpan={item.datas.length}>
                    {itemOrderNumber}
                  </td>

                  {/* Ячейка: Наименование */}
                  <td
                    rowSpan={item.datas.length}
                    className={cl.itemName}
                  >
                    {
                      item.name === "" ? (
                        <>
                          <CustomSearchSelect
                            inputName='name'
                            defaultValue={''}
                            options={nameOptions}
                            onChange={(value) => handleGoodSelectChange(index, 'name', value)}
                            // onInput={checkNameRepeated}
                            // onFocus={checkNameRepeated}
                            placeholder='Наименование'
                            searchMode={true}
                          />
                        </>
                      ) : (
                        <span
                          style={{ cursor: "pointer" }}
                          // onClick={() =>
                          //   navigate("/history?name=" + item.name)
                          // }
                        >
                          {item.name}
                        </span>
                      )
                    }
                  </td>

                  {/* Ячейка: Артикул */}
                  <td
                    rowSpan={item.datas.length}
                    className={cl.itemArticle}
                  >
                    {
                      item.name === "" ? (
                        <>
                          <CustomSearchSelect
                            inputName='article'
                            defaultValue={''}
                            options={articleOptions}
                            onChange={(value) => handleGoodSelectChange(index, 'article', value)}
                            // onInput={checkArticleRepeated}
                            // onFocus={checkArticleRepeated}
                            placeholder='Артикул'
                            searchMode={true}
                          />
                        </>
                      ) : (
                        <span
                          style={{ cursor: "pointer" }}
                          // onClick={() =>
                          //   navigate("/history?name=" + item.name)
                          // }
                        >
                          {item.article}
                        </span>
                      )
                    }
                  </td>

                  {/* Ячейка: Группа из Бизнес.ру */}
                  <td
                    rowSpan={item.datas.length}
                    className={cl.itemGroup}
                  >
                    {
                      item.name === "" ? (
                        <CustomSearchSelect
                          inputName='b_group'
                          defaultValue={item.b_group}
                          options={groups}
                          onChange={(value) => handleGoodSelectChange(index, 'b_group', value)}
                          placeholder='Группа'
                          searchMode={true}
                        />
                      ) : (
                        item.b_group
                      )
                    }
                  </td>

                  {/* Ячейка: Учёт (из xls файла) */}
                  <td className={cl.right} rowSpan={item.datas.length}>
                    {new Intl.NumberFormat("ru-RU").format(item.uchet)}
                  </td>

                  {/* Ячейка: Факт (из xls файла) */}
                  <td className={cl.right} rowSpan={item.datas.length}>
                    {new Intl.NumberFormat("ru-RU").format(item.fact)}
                  </td>

                  {/* Ячейка: Отклонение (из xls файла) */}
                  <td className={cl.right} rowSpan={item.datas.length}>
                    {new Intl.NumberFormat("ru-RU").format(item.spread)}
                  </td>

                  {/* Ячейка: Всего учёт */}
                  <td
                    className={cl.right + " " + colors.b7}
                    rowSpan={item.datas.length}
                  >
                    {
                      new Intl.NumberFormat("ru-RU").format(
                        item.total_uchet
                      )
                    }
                  </td>
                </>
              )
            }

            {/* Ячейка: Кол-во */}
            <td
              className={`${colors.b7} ${cl.right} ${styles.amountUchet}`}
              onDoubleClick={() => handleFactAmountInputChange({index, i}, dt.amount_uchet)}
            >
              {new Intl.NumberFormat("ru-RU").format(dt.amount_uchet)}
            </td>

            {/* Ячейка: Адрес */}
            <td className={colors.b7 + " " + cl.address}>
              {dt.address_uchet}
            </td>

            {/* Ячейка: Всего факт */}
            {
              i === 0 && (
                <td
                  className={cl.right + " " + colors.b5}
                  rowSpan={item.datas.length}
                >
                  {new Intl.NumberFormat("ru-RU").format(item.total_fact)}
                </td>
              )
            }

            {/* Ячейка: Кол-во факт (Input) */}
            <td className={colors.b5 + " " + cl.right}>
              {
                item.provided === 1 ? (
                  new Intl.NumberFormat("ru-RU").format(dt.amount_fact)
                ) : (
                  <label className={cl.inputLabel}>
                    <input
                      type="text"
                      name="amount_fact"
                      value={dt.amount_fact}
                      placeholder="Факт"
                      autoComplete='off'
                      onChange={(evt) => handleFactAmountInputChange({index, i}, evt.currentTarget.value)}
                    />
                  </label>
                )
              }
            </td>

            {/* Ячейка: Адрес */}
            <td className={colors.b5 + " " + cl.address}>
              {
                item.provided === 1 || !!dt.address_uchet ? (
                  dt.address_fact
                ) : (
                  <CustomSearchSelect
                    inputName='address_fact'
                    // defaultValue={dt.address_fact ? dt.address_fact : dt.address_uchet}
                    defaultValue={dt.address_fact}
                    options={displayedAddresses}
                    onChange={(value) => handleFactAddressSelectChange({index, i}, value)}
                    executeChangeHandlerOnTypingValue={true}
                    placeholder='Адрес'
                  />
                )
              }
            </td>

            {/* Ячейка: Отклонение */}
            <td className={colors.b5 + " " + cl.right}>
              {new Intl.NumberFormat("ru-RU").format(dt.spread)}
            </td>

            {/* Ячейка: Всего отклонение */}
            {
              i === 0 && (
                <td
                  className={cl.right + " " + colors.b5}
                  rowSpan={item.datas.length}
                >
                  {
                    !item.total_spread
                      ? 0
                      : new Intl.NumberFormat("ru-RU").format(
                          item.total_spread
                        )
                  }
                </td>
              )
            }

            {/* КНОПКИ В КОНЦЕ СТРОКИ */}
            {/* Удалить строку */}
            {
              !item.provided && (
                <td className="iconed">
                  <span
                    className="roundIcon material-icons"
                    onClick={() => handleDeleteLineButtonClick({index, i})}
                    title="Удалить строку"
                  >
                    delete_outline
                  </span>
                </td>
              )
            }

            {/* Добавить строку */}
            {
              i === 0 && !item.provided && (
                <td
                  className={"iconed " + cl.bottom}
                  style={{verticalAlign: 'bottom'}}
                  rowSpan={item.datas.length}
                >
                  <span
                    className="roundIcon material-icons"
                    onClick={() => handleAddSubStringButtonClick(index)}
                    title="Добавить строку"
                  >
                    add
                  </span>
                </td>
              )
            }
          </tr>
        ))
      }
    </>
  );
};

export default memo(InventoryTableRow, (prevProps, nextProps) => {
  const isHeldStatusTheSame = prevProps.isHeldInBusinessRu === nextProps.isHeldInBusinessRu;
  const isIndexTheSame = prevProps.index === nextProps.index;
  const isItemDataTheSame = JSON.stringify(prevProps.item) === JSON.stringify(nextProps.item);
  const areGroupsTheSame = prevProps.groups.length === nextProps.groups.length;
  const areAddressesTheSame = JSON.stringify(prevProps.addresses) === JSON.stringify(nextProps.addresses);

  const arePropsTheSame = isHeldStatusTheSame && isIndexTheSame && isItemDataTheSame && areGroupsTheSame && areAddressesTheSame;

  return arePropsTheSame;
});
