import {createAsyncThunk} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';

import {redirectToRoute} from 'redux/actions';

import {ApiRoute, Inventory} from 'constants/endpoints';
import {AppRoute} from 'constants/routes';

import toastMessageStyles from 'styles/components/toast-message.module.scss';

// Запрос списка инвентаризаций
export const fetchInventoriesAsyncAction = createAsyncThunk(
  'inventory/all',
  async (_args, {extra: api}) => {
    const {data} = await api.get(Inventory.ALL);
    return data;
  }
);

// Запрос списка инвентаризаций
export const createInventoryAsyncAction = createAsyncThunk(
  'inventory/createInventory',
  async (file, {dispatch, extra: api}) => {
    const formData = new FormData();
    formData.append('act', file);

    const {data} = await api.post(Inventory.POST_FILE, formData)
      .then((res) => {
        dispatch(fetchInventoriesAsyncAction());
        return res;
      })
      .then((res) => {
        dispatch(redirectToRoute(`${AppRoute.Inventories.LIST}/${res.data.id}`));
        return res;
      })
      .catch((res) => {
        toast.error(res.response.data.message, {
          position: 'bottom-right',
          className: toastMessageStyles['toast-message']
        });
        dispatch(redirectToRoute(AppRoute.Inventories.LIST));
      });

    return data;
  }
);

// Первичная отправка Exel файла инвентаризации на сервер
export const postInventoryFileAsyncAction = createAsyncThunk(
  'inventory/postFile',
  async (file, {dispatch, extra: api}) => {
    const formData = new FormData();
    formData.append('act', file);

    const {data} = await api.post(Inventory.POST_FILE, formData)
      .then((res) => {
        dispatch(fetchInventoriesAsyncAction());
        return res;
      })
      .then((res) => {
        dispatch(redirectToRoute(`/inventory/edit/${res.data.id}`));
        return res;
      })
      .catch((res) => {
        toast.error(res.response.data.message, {
          position: 'bottom-right',
          className: toastMessageStyles['toast-message']
        });
        dispatch(redirectToRoute('/inventory'));
      });

    return data;
  }
);

// Запрос списка товаров конкретной инвентаризации по её id
export const fetchInventoryAsyncAction = createAsyncThunk(
  'inventory/getItems',
  async (id, {extra: api}) => {
    const {data} = await api.get(`${Inventory.ITEMS}/${id}`);
    return data;
  }
);

// Сохранение данных инвентаризации
export const postInventoryItemsAsyncAction = createAsyncThunk(
  'inventory/save',
  async (args, {dispatch, extra: api}) => {
    const id = args.id;
    const comment = args.comment;
    const inventory = args.inventory;

    const {data} = await api.post(Inventory.SAVE, {comment, inventory})
      .then((res) => {
        dispatch(fetchInventoryAsyncAction(id));
        return res;
      });

    return data;
  }
);

// Редактирование Exel файла инвентаризации
export const editFileAsyncAction = createAsyncThunk(
  'inventory/editFile',
  async (arg, {extra: api}) => {
    const {data} = await api.post(Inventory.EDIT_FILE, arg);
    return data;
  }
);

// Сохранение примечания
export const setCommentAsyncAction = createAsyncThunk(
  'inventory/setComment',
  async (args, {extra: api}) => {
    const id = args.id;
    const comment = args.comment;

    await api.post(`${Inventory.SET_COMMENT}/${id}`, {comment});
  }
);

// Сохранение файлов из модального окна примечания
export const setCommentFilesAsyncAction = createAsyncThunk(
  'inventory/setCommentFile',
  async (args, {extra: api}) => {
    const id = args.id;
    const formData = args.formData;

    const {data} = await api.post(`${Inventory.SET_COMMENT_FILE}/${id}`, formData);

    return data;
  }
);

// Отправка скриншота инвентаризации
export const postInventoryScreenShotAsyncAction = createAsyncThunk(
  'inventory/editInventoryThumb',
  async (arg, {extra: api}) => {
    const {data} = await api.post(`${Inventory.EDIT_THUMB}/${arg.id}`, arg.body);
    return data;
  }
);

// Создание файлов для Бизнес.ру
export const editBruFilesAsyncAction = createAsyncThunk(
  'inventory/editFilesBru',
  async (arg, {extra: api}) => {
    const {data} = await api.post(Inventory.EDIT_FILES_BRU, arg);
    return data;
  }
);

// Проведение первичной инвентаризации
export const provideInventoryAsyncAction = createAsyncThunk(
  'inventory/provide',
  async (args, {dispatch, extra: api}) => {
    const id = args.id;
    const showSuccessMsg = args.showSuccessMsg;
    const {data} = await api.get(`${Inventory.PROVIDE}/${id}`)
      .then((res) => {
        dispatch(fetchInventoriesAsyncAction());
        return res;
      })
      .then((res) => {
        dispatch(redirectToRoute('/inventory'));
        showSuccessMsg();
        return res;
      });

    return data;
  }
);

// Отмена инвентаризации
export const cancelInventoryAsyncAction = createAsyncThunk(
  'inventory/cancel',
  async (args, {dispatch, extra: api}) => {
    const id = args.id;
    const showSuccessMsg = args.showSuccessMsg;
    const {data} = await api.get(`${Inventory.PROVIDE}/${id}?deny=1`)
      .then((res) => {
        dispatch(fetchInventoriesAsyncAction());
        return res;
      })
      .then((res) => {
        dispatch(redirectToRoute('/inventory'));
        showSuccessMsg();
        return res;
      })
      .catch((res) => {
        const axiosResponseMessage = res.message;
        toast.error(axiosResponseMessage, {
          position: 'bottom-right',
          className: toastMessageStyles['toast-message']
        });
      });

    return data;
  }
);

// Удаление инвентаризации
export const removeInventoryAsyncAction = createAsyncThunk(
  'inventory/remove',
  async (id, {extra: api}) => {
    const {data} = await api.delete(`${Inventory.REMOVE}/${id}`);

    return data;
  }
);

// Загрузка архивов при переходе в раздел "Архивы"
export const fetchArchiveInventoriesAsyncAction = createAsyncThunk(
  'inventory/archive',
  async (_args, {extra: api}) => {
    const {data} = await api.get(Inventory.ARCHIVES);
    return data;
  }
);

// Копирование инвентаризации
export const copyInventoryAsyncAction = createAsyncThunk(
  'inventory/copy',
  async (args, {dispatch, extra: api}) => {
    const id = args.id;
    const showSuccessMsg = args.showSuccessMsg;

    const {data} = await api.get(`${Inventory.COPY}/${id}`)
      .then((res) => {
        dispatch(fetchInventoriesAsyncAction());
        return res;
      })
      .then((res) => {
        dispatch(redirectToRoute('/inventory'));
        showSuccessMsg();
        return res;
      });

    return data;
  }
);

export const setInventoryNewGoodInitialAmountsAsyncAction = createAsyncThunk(
  'inventory/setInventoryNewGoodInitialAmounts',
  async (body, {extra: api}) => {
    const {data} = await api.post(ApiRoute.Fields.SET_INITIAL_HISTORY_AMOUNTS, body);

    return {data, plantName: body.name};
  }
);
