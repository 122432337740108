import {createAsyncThunk} from '@reduxjs/toolkit';

import {ApiRoute} from 'constants/endpoints';

export const fetchFactoriesAsyncAction = createAsyncThunk(
  'factory/fetchFactories',
  async (isInArchive, {extra: api}) => {
    const done = isInArchive ? 1 : 0;
    const {data} = await api.get(`${ApiRoute.Factory.ALL}?done=${done}`);

    return data;
  }
);

export const fetchFactoryAsyncAction = createAsyncThunk(
  'factory/fetchFactory',
  async (id, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Factory.GET_FACTORY}/${id}`);

    return data;
  }
);

export const fetchAddressesAsyncAction = createAsyncThunk(
  'factory/fetchAddresses',
  async (_args, {extra: api}) => {
    const {data} = await api.get(ApiRoute.Map.GET_ALL_ADDRESSES);

    return data;
  }
);

export const fetchFactoryDocumentsNumbersAsyncAction = createAsyncThunk(
  'factory/fetchFactoryDocumentsNumbers',
  async (_args, {extra: api}) => {
    const regularFactoriesResult = await api.get(`${ApiRoute.Factory.ALL}?done=0`);
    const archiveFactoriesResult = await api.get(`${ApiRoute.Factory.ALL}?done=1`);

    const factoryDocumentsNumbers = [
      ...regularFactoriesResult.data.result,
      ...archiveFactoriesResult.data.result
    ].map((factory) => factory.document_number);

    return factoryDocumentsNumbers;
  }
);

export const fetchGoodsFromHistoryAsyncAction = createAsyncThunk(
  'factory/fetchGoodsFromHistory',
  async (args, {extra: api}) => {
    const date = args.date;
    const {data} = await api.get(`${ApiRoute.Fields.GET_ALL_GOODS_FROM_HISTORY}?date=${date}`);

    return data;
  }
);

export const setFactorySourceInitialAmountsAsyncAction = createAsyncThunk(
  'factory/setNewDocumentInitialAmounts',
  async (body, {extra: api}) => {
    const {data} = await api.post(ApiRoute.Fields.SET_INITIAL_HISTORY_AMOUNTS, body);

    return {data, plantName: body.name};
  }
);

export const setMaterialsInitialAmountsAsyncAction = createAsyncThunk(
  'factory/setMaterialsInitialAmounts',
  async (body, {extra: api}) => {
    const {data} = await api.post(ApiRoute.Fields.SET_INITIAL_HISTORY_AMOUNTS, body);

    return {data, plantName: body.name};
  }
);

export const saveFactoryAsyncAction = createAsyncThunk(
  'factory/saveFactory',
  async (body, {extra: api}) => {
    const id = body.id ? `/${body.id}` : '';
    const {data} = await api.post(`${ApiRoute.Factory.SAVE}${id}`, body);

    return data.id;
  }
);

export const provideFactoryAsyncAction = createAsyncThunk(
  'factory/provideFactory',
  async (args, {extra: api}) => {
    const id = args.factoryId;

    const denyQueryString = args.cancel ? '?deny=1' : '';
    const doneQueryString = args.complete ? '?done=1' : '';
    const queryString = denyQueryString || doneQueryString;

    const {data} = await api.get(`${ApiRoute.Factory.PROVIDE}/${id}${queryString}`);

    return data;
  }
);

export const deleteFactoryAsyncAction = createAsyncThunk(
  'factory/deleteFactory',
  async (id, {extra: api}) => {
    const response = await api.delete(`${ApiRoute.Factory.DELETE}/${id}`);

    return response;
  }
);

export const postCommentFilesAsyncAction = createAsyncThunk(
  'factory/postCommentFiles',
  async (args, {extra: api}) => {
    const id = args.id;
    const files = args.files;

    await api.post(`${ApiRoute.Factory.SEND_COMMENT_FILES}/${id}`, files);
  }
);

export const deleteCommentFileAsyncAction = createAsyncThunk(
  'factory/deleteCommentFile',
  async (file, {extra: api}) => {
    await api.post(ApiRoute.Factory.REMOVE_COMMENT_FILE, file);
  }
);

export const copyFactoryCommentFilesAsyncAction = createAsyncThunk(
  'factory/copyFactoryCommentFiles',
  async (args, {extra: api}) => {
    await api.post(ApiRoute.Factory.COPY_FACTORY_COMMENT_FILES, args);
  }
);

export const downloadFactoryDocumentAsyncAction = createAsyncThunk(
  'factory/downloadFactoryDocument',
  async (body, {extra: api}) => {
    const {data} = await api.post(ApiRoute.Factory.DOWNLOAD, body);

    return data;
  }
);

export const downloadFactoryTaskAsyncAction = createAsyncThunk(
  'factory/downloadFactoryTask',
  async (body, {extra: api}) => {
    const {data} = await api.post(ApiRoute.Factory.TASK, body);

    return data;
  }
);
