import {useContext} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import clsx from 'clsx';

import {
  deleteOfferAsyncAction,
  fetchOffersAsyncAction
} from 'redux/slices/offers/offers-api-actions';

import {AppRoute} from 'constants/routes';
import {AppContext} from 'providers/AppContextProvider';
import {getUser} from 'helpers/storage';
import {getHumanFormattedDate} from 'helpers/utils';

import styles from './styles.module.scss';

const OffersListItem = ({offer, setIsPreloaderActive}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const userLevel = getUser().accessLevel;

  const {alert} = useContext(AppContext);

  const ARCHIVE_LOCATION_REG_EXP = /archive/;
  const pathName = location.pathname;
  const isInArchive = ARCHIVE_LOCATION_REG_EXP.test(pathName);

  const handleShowDocumentDetailsButtonClick = () => {
    const regularLocation = AppRoute.Offers.LIST;
    const archiveLocation = AppRoute.Archive.OFFERS;

    if (isInArchive) {
      navigate(`${archiveLocation}/${offer.id}`);
    } else {
      navigate(`${regularLocation}/${offer.id}`);
    }
  };

  const handleDeleteArchiveDocumentButtonClick = (id) => {
    const handler = async () => {
      setIsPreloaderActive(true);
      await dispatch(deleteOfferAsyncAction(id));
      await dispatch(fetchOffersAsyncAction(isInArchive));
      setIsPreloaderActive(false);
    };

    alert('Удалить архив?', 'danger', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  return (
    <tr>
      <td>{offer.number}</td>
      <td>{getHumanFormattedDate(offer.date)}</td>
      <td>{offer.customer}</td>
      <td>{offer.order_number}</td>
      <td>
        <button
          className={clsx(styles.button, 'material-icons')}
          onClick={handleShowDocumentDetailsButtonClick}
        >
          <span className={styles.icon}>
            visibility
          </span>
        </button>
      </td>
      <td>
        {
          isInArchive && (
            userLevel === 3 && (
              <button
                className={clsx(styles.button, 'material-icons')}
                onClick={() => handleDeleteArchiveDocumentButtonClick(offer.id)}
              >
                <span className={clsx(styles.icon, styles.close)}>
                  close
                </span>
              </button>
            )
          )
        }
      </td>
    </tr>
  );
};

export default OffersListItem;
