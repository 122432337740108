import {createSlice} from '@reduxjs/toolkit';

import {
  fetchAddressesAsyncAction,
  fetchFactoriesAsyncAction,
  fetchFactoryAsyncAction,
  fetchFactoryDocumentsNumbersAsyncAction,
  fetchGoodsFromHistoryAsyncAction,
  setFactorySourceInitialAmountsAsyncAction,
  setMaterialsInitialAmountsAsyncAction
} from './factory-api-actions';

const initialState = {
  factories: [],
  factory: {
    comment: '',
    document_number: '',
    document_date: '',
    newDocumentDate: '',
    factory_date: '',
    sub_name: '',
    author: '',
    employee: '',
    accountant: '',
    provided: undefined,
    done: undefined,
    form: [],
    materials: [],
    files: []
  },
  addresses: [],
  factoryDocumentsNumbers: [],
  sourceGoods: []
};

export const factory = createSlice({
  name: 'factory',
  initialState,
  reducers: {
    resetState: (state) => {
      state.factory = {
        comment: '',
        document_number: '',
        document_date: '',
        newDocumentDate: '',
        factory_date: '',
        sub_name: '',
        author: '',
        employee: '',
        accountant: '',
        provided: undefined,
        done: undefined,
        form: [],
        materials: [],
        files: []
      };
    },
    setFactoryDocumentAction: (state, action) => {
      state.factory = action.payload;
    },
    setFactoryCommentAction: (state, action) => {
      state.factory.comment = action.payload;
    },
    setCommentFilesAction: (state, action) => {
      state.factory.files = action.payload;
    },
    setSubNameAction: (state, action) => {
      state.factory.sub_name = action.payload;
    },
    setDocumentNumberAction: (state, action) => {
      state.factory.document_number = action.payload;
    },
    setDocumentDateAction: (state, action) => {
      state.factory.document_date = action.payload;
    },
    setNewDocumentDateAction: (state, action) => {
      state.factory.newDocumentDate = action.payload;
    },
    setFactoryEmployeeAction: (state, action) => {
      state.factory.employee = action.payload;
    },
    setFactoryAccountantAction: (state, action) => {
      state.factory.accountant = action.payload;
    },
    setFactoryDateAction: (state, action) => {
      state.factory.factory_date = action.payload;
    },
    setFormItemsAction: (state, action) => {
      state.factory.form = action.payload;
    },
    setMaterialsItemsAction: (state, action) => {
      state.factory.materials = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFactoriesAsyncAction.fulfilled, (state, action) => {
        state.factories = action.payload.result;
      })
      .addCase(fetchFactoryAsyncAction.fulfilled, (state, action) => {
        const form = action.payload.result.form;
        const materials = action.payload.result.materials;

        state.factory.comment = action.payload.result.comment;
        state.factory.document_number = action.payload.result.document_number;
        state.factory.document_date = action.payload.result.document_date;
        state.factory.factory_date = action.payload.result.factory_date;
        state.factory.sub_name = action.payload.result.sub_name;
        state.factory.author = action.payload.result.author;
        state.factory.employee = action.payload.result.employee;
        state.factory.accountant = action.payload.result.accountant;
        state.factory.provided = action.payload.result.provided;
        state.factory.done = action.payload.result.done;
        state.factory.form = form ? form : [];
        state.factory.materials = materials ? materials : [];
        state.factory.files = action.payload.result.files;
      })
      .addCase(fetchAddressesAsyncAction.fulfilled, (state, action) => {
        state.addresses = action.payload.result;
      })
      .addCase(fetchFactoryDocumentsNumbersAsyncAction.fulfilled, (state, action) => {
        state.factoryDocumentsNumbers = action.payload;
      })
      .addCase(fetchGoodsFromHistoryAsyncAction.fulfilled, (state, action) => {
        state.sourceGoods = action.payload.result;
      })
      .addCase(setFactorySourceInitialAmountsAsyncAction.fulfilled, (state, action) => {
        const plantName = action.payload.plantName;
        const amounts = action.payload.data.amounts;

        if (amounts.length) {
          const userPositions = amounts.map((amountsItem) => ({
            address: amountsItem.address,
            amount: '',
            address_after: '',
            amount_after: ''
          }));
          const documentItems = [...state.factory.form];
          const selectedItemIndex = documentItems.findIndex((item) => item.name === plantName);

          documentItems[selectedItemIndex].amounts = amounts;
          documentItems[selectedItemIndex].userPositions = userPositions;
          documentItems[selectedItemIndex].totalAmount = amounts.reduce((res, item) => res + Number(item.amount), 0);
          documentItems[selectedItemIndex].totalAmount_after = 0;
          state.factory.form = documentItems;
        }
      })
      .addCase(setMaterialsInitialAmountsAsyncAction.fulfilled, (state, action) => {
        const plantName = action.payload.plantName;
        const amounts = action.payload.data.amounts;
        const measure = amounts[0].measure;

        if (amounts.length) {
          const userPositions = amounts.map(() => ({
            amount: '',
            counter: ''
          }));
          const materialItems = [...state.factory.materials];
          const selectedItemIndex = materialItems.findIndex((item) => item.name === plantName);

          materialItems[selectedItemIndex].amounts = amounts;
          materialItems[selectedItemIndex].userPositions = userPositions;
          materialItems[selectedItemIndex].measure = measure;
          materialItems[selectedItemIndex].totalAmount = amounts.reduce((res, item) => res + Number(item.amount), 0);
          materialItems[selectedItemIndex].totalAmount_after = 0;
          state.factory.materials = materialItems;
        }
      });
  }
});

export const {
  resetState,
  setFactoryCommentAction,
  setCommentFilesAction,
  setSubNameAction,
  setDocumentNumberAction,
  setDocumentDateAction,
  setNewDocumentDateAction,
  setFactoryEmployeeAction,
  setFactoryAccountantAction,
  setFactoryDateAction,
  setFormItemsAction,
  setMaterialsItemsAction,
  setFactoryDocumentAction
} = factory.actions;
