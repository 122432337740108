import Indicator from 'components/Indicator';

import styles from './styles.module.scss';

const ListLegend = ({legendData}) => {
  return (
    <div className={styles.wrapper}>
      {
        legendData.map((item, index) => (
          <div key={index}>
            <Indicator type={item.type}/> - {item.text}
          </div>
        ))
      }
    </div>
  );
};

export default ListLegend;
