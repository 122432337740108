import {createSlice} from '@reduxjs/toolkit';

import {fetchSettingsAsyncAction} from './settings-api-actions';

const initialState = {
  settings: []
};

export const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettingsAsyncAction.fulfilled, (state, action) => {
        state.settings = action.payload.result;
      });
  }
});
