import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import Input from 'components/input/InputComponent';
import Button from 'components/ButtonComponent';

import {uploadOfferFileAndFillDBAsyncAction} from 'redux/slices/offers/offers-api-actions';

import {AppContext} from 'providers/AppContextProvider';
import {getUser} from 'helpers/storage';
import {AppRoute} from 'constants/routes';

import cl from 'styles/pages/signup.module.scss';
import {toast} from 'react-toastify';

const UploadOffer = () => {
  const navigate = useNavigate();
	const dispatch = useDispatch();

	// Флаг отправки
	const [isSended, setIsSended] = useState(false);

  // Файл
  const [file, setFile] = useState(null);

  const {setCrumbs} = useContext(AppContext);

  const userLevel = getUser().accessLevel;

  const handleFileInputChange = (_name, file) => {
    setFile(file);
  };

  const uploadFile = async () => {
    const formData = new FormData();
    formData.append('file', file);

		try {
      const response = await dispatch(uploadOfferFileAndFillDBAsyncAction(formData)).unwrap();
      return response;
    } catch(error) {
      return false;
    }
  };

	// Отправка файла на сервер
	const handleUploadFileButtonClick = async () => {
    if (!file) {
      toast.error('Файл не выбран', {
        position: 'bottom-right'
      });
      return;
    }

    setIsSended(true);
    const uploadFileResponse = await uploadFile();
    if (!uploadFileResponse) {
      toast.error('Ошибка выгрузки файла', {
        position: 'bottom-right'
      });
    } else {
      toast.success('Документ загружен', {
        position: 'bottom-right'
      });
      navigate(`${AppRoute.Offers.LIST}/${uploadFileResponse.id}`);
    }
    setIsSended(false);
	}

  // Перенаправление на главную, если нет доступа
	useEffect(() => {
		if (userLevel < 3) {
      navigate(AppRoute.Root);
    }
	}, []);

	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
			{name: 'Коммерческие предложения', url: AppRoute.Offers.LIST},
			{name: 'Новая загрузка', url: AppRoute.Offers.UPLOAD}
		]);
	}, []);

  return (
    <div className={`${cl.main} container`}>
      <form className={`${cl.form} ${cl.withoutLabel}`}>
        <Input
          type="file"
          name="file"
          value={null}
          setValue={handleFileInputChange}
        />
        
        <div className="row">
          <div className="col-sm-12">
            <Button
              type="accent"
              onClick={handleUploadFileButtonClick}
              inProcess={isSended}
            >
              Загрузить файл КП
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UploadOffer;
